import React from 'react';
import { Scheduling } from '../types/Scheduling';
import * as Icons from '../components/SVGIcons';

import './CalendarsView.scss';
import { ImmediateRequest } from '../components/NetworkRequest';
import Button from '../components/Button';
import { FormattedMessage } from 'react-intl';

export const RequestForAccountsAndCalendars: React.FunctionComponent<{
  pageId: number;
  children: (calsByAccount: Scheduling.PageConfigCalendars[]) => React.ReactElement<any>;
}> = (props) => (
  <ImmediateRequest<Scheduling.PageConfigCalendars[], {}> path={`/manage/pages/${props.pageId}/calendars`}>
    {(calsByAccount, onRetry) => {
      if (calsByAccount === 'empty' || calsByAccount === 'loading') {
        return (
          <div className="message-wrap">
            <Icons.Spinner width={40} height={40} />
          </div>
        );
      }
      if (calsByAccount instanceof Error) {
        return (
          <div className="message-wrap">
            <FormattedMessage
              defaultMessage="Unable to fetch your connected calendars."
              description="Settings - Error - Unable to fetch calendars"
              id="Settings-eutfc"
            />

            <Button size="small" onClick={onRetry}>
              <FormattedMessage defaultMessage="Try Again" description="Try Again" id="Try-Again" />
            </Button>
          </div>
        );
      }
      return props.children(calsByAccount);
    }}
  </ImmediateRequest>
);
