import React from 'react';
import S3Upload from 'react-s3-uploader/s3upload';
import { Scheduling } from '../types/Scheduling';
import { Request } from '../components/NetworkRequest';
import Button from './Button';

import './ImageInput.scss';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

interface ImageInputProps {
  value: string | null;
  pageId: string | number;
  onChange: (value: string | null) => void;
  minimumSize: {
    width: number;
    height: number;
  };
}

interface ImageInputInnerProps extends ImageInputProps {
  getSignedUrl: (payload: S3SignedURLPayload) => Promise<S3SignedURL | Error>;
  intl: IntlShape;
}

interface ImageInputState {
  progress: number;
  cancelCount: number;
}

type S3SignedURL = Scheduling.S3SignedURL;
type S3SignedURLPayload = Scheduling.S3SignedURLPayload;

class ImageInputInner extends React.Component<ImageInputInnerProps, ImageInputState> {
  state = {
    progress: 0,
    cancelCount: 0,
  };

  _inputRef = React.createRef<HTMLInputElement>();

  onDragOver = (e: React.DragEvent<any>) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent<any>) => {
    e.preventDefault();
    this.onProcessFile(e.dataTransfer.files[0]);
  };

  onPickedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      this.onProcessFile(e.target.files[0]);
    }
  };

  onProcessFile = (file: File) => {
    if (!file) return;

    const ext = file.name.split('.').pop()!.toLowerCase();
    const { intl } = this.props;

    if (
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type.toLowerCase()) ||
      !['png', 'jpg', 'jpeg'].includes(ext)
    ) {
      alert(
        intl.formatMessage({
          id: 'Image-uef',
          description: 'Image Upload Error - Format',
          defaultMessage: 'Sorry, please select a PNG or JPG image.',
        }),
      );
      return;
    }
    if (file.size > 1024 * 1024 * 12) {
      alert(
        intl.formatMessage({
          id: 'Image-ues',
          description: 'Image Upload Error - Size',
          defaultMessage: 'Sorry, please choose an image that is less than 12MB in size.',
        }),
      );
      return;
    }

    this.onUpload(file);
  };

  onUpload = (file: File) => {
    const { intl } = this.props;

    new S3Upload({
      files: [file],
      getSignedUrl: async (file: File, callback: (data: S3SignedURL) => void) => {
        const resp = await this.props.getSignedUrl({
          contentType: file.type.toLowerCase(),
          objectName: file.name.toLowerCase(),
        });
        if (resp instanceof Error) {
          alert(
            intl.formatMessage({
              id: 'Image-uenr',
              description: 'Image Upload Error - No Reason',
              defaultMessage: 'Sorry, the image could not be uploaded. Please try again.',
            }),
          );
          return;
        }
        callback(resp);
      },
      contentDisposition: 'attachment',
      uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
      onError: this.onUploadError,
      onFinishS3Put: this.onUploadFinished,
      onProgress: (progress: number) => {
        if (this.props.value) {
          this.props.onChange(null);
        }
        this.setState({ progress });
      },
    });
  };

  onUploadError = (err: Error) => {
    const { intl } = this.props;
    alert(
      intl.formatMessage(
        {
          id: 'Image-iue',
          description: 'Image Upload Error',
          defaultMessage:
            'An error occurred while uploading the image: {err}. You may want to disable ad-blockers and try again.',
        },
        {
          err: err.toString(),
        },
      ),
    );
    this.onReset();
  };

  onUploadFinished = (info: { publicUrl: string }) => {
    this.onReset();
    this.props.onChange(info.publicUrl);
  };

  onReset = () => {
    this.setState({ progress: 0, cancelCount: this.state.cancelCount + 1 });
  };

  render() {
    const { value, minimumSize, intl } = this.props;
    const { progress } = this.state;

    return (
      <label htmlFor="file" className={`ImageInput`} onDrop={this.onDrop} onDragOver={this.onDragOver}>
        <input
          id="file"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          key={this.state.cancelCount}
          ref={this._inputRef}
          onChange={this.onPickedFile}
        />
        <div className="inner" style={{ flexDirection: 'row' }}>
          {value ? (
            <img
              src={value}
              alt={intl.formatMessage({
                id: 'Image-upa',
                description: 'Image Upload Preview Alt',
                defaultMessage: 'Upload Preview',
              })}
            />
          ) : (
            <>
              <Button onClick={() => this._inputRef.current && this._inputRef.current.click()}>
                <FormattedMessage defaultMessage="Upload" description="Button-Upload" id="Button-Upload" />
              </Button>
              <div>
                <div style={{ opacity: 0.5, fontSize: '0.9em', marginTop: 3 }}>
                  {`(min ${minimumSize.width} x ${minimumSize.height}px)`}
                </div>
              </div>
              <div
                className="progress"
                style={{
                  width: `${Math.round(Math.min(100, progress))}%`,
                }}
              />
            </>
          )}
        </div>
      </label>
    );
  }
}

export const ImageInput: React.FunctionComponent<ImageInputProps> = (props) => {
  const intl = useIntl();
  return (
    <Request<S3SignedURL, S3SignedURLPayload> path={`/manage/pages/${props.pageId}/upload-image`} method="PUT">
      {(_, getSignedUrl) => <ImageInputInner {...props} getSignedUrl={getSignedUrl} intl={intl} />}
    </Request>
  );
};
