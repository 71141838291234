import { useLocation, useRouteMatch } from 'react-router-dom';
import { parseQuerystring } from '../Utils';

export function useBookingRouteParams() {
  const location = useLocation();
  const match = useRouteMatch<any>({
    path: ['/:slug/book/:start', '/:slug/:action/:hash', '/:slug'],
  });
  const params:
    | { action: string; start: undefined; hash: string }
    | { action: undefined; start: string; hash?: string }
    | { action: undefined; start: undefined; hash?: string } = match?.params;

  params.hash = params.hash || parseQuerystring(location.search).edithash;
  return params;
}
