import React from 'react';
import "./FormattedError.scss"
import { useIntl } from 'react-intl';

export type ViewType = 'Calendar' | 'Default';

interface FormattedErrorProps {
    message?: string;
}

export const FormattedError: React.FunctionComponent<FormattedErrorProps> = (props) => {
    const [showResults, setShowResults] = React.useState(false);
    const onClick = () => setShowResults(!showResults);
    const intl = useIntl();
    return (
        <div>
            <div style={{ marginBottom: 10 }}>
                <strong>
                    {intl.formatMessage({
                        id: 'error-could-not-load-page',
                        defaultMessage: 'Sorry, we couldn\'t load your page',
                        description: 'Error - Unable to load page',
                    })}
                </strong>
            </div>
            <div style={{ marginBottom: 30 }}>Please contact your administrator for help</div>
            {showResults ?
                <div className='FormattedError' >
                    <div onClick={onClick}>
                        {intl.formatMessage({
                            id: 'hide-details',
                            defaultMessage: 'Hide details',
                            description: 'Hide error message details',
                        })}
                    </div>
                    <img style={{ marginLeft: 20 }} onClick={onClick} src="up.png" alt="Hide details" />
                </div>
                :
                <div className='FormattedError' >
                    <div onClick={onClick}>
                        {intl.formatMessage({
                            id: 'show-details',
                            defaultMessage: 'Show details',
                            description: 'Show error message details',
                        })}
                    </div>
                    <img style={{ marginLeft: 20 }} onClick={onClick} src="/down.png" alt="Show details" />
                </div>
            }
            {showResults && <div style={{ display: "inline-flex", marginBottom: 30 }}>
                <div style={{ marginRight: 10 }}>
                    <strong>
                        {intl.formatMessage({
                            id: 'error-reason',
                            defaultMessage: 'Reason:',
                            description: 'Reason for the error',
                        })}
                    </strong>
                </div>
                <div> {props.message} </div>
            </div>}
        </div>
    );
};

