import React, { useState, useRef, useEffect, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';

import { Scheduling } from '../types/Scheduling';
import { makeRequest, RequestContext, RequestState } from '../components/NetworkRequest';
import * as Icons from '../components/SVGIcons';
import { SlotCancelView } from './SlotCancelView';
import { HostApproveBookingView } from './HostApproveBookingView';
import { ThemeVariables } from '../ThemeSupport';
import { CalendarView } from './CalendarView';
import { SlotConfirmView } from './SlotConfirmView';
import { getNylasWindowContext, track } from '../NylasWindowContext';

import './MainView.scss';
import { getLocalStorage } from '../Storage';
import { FormattedMessage, useIntl, IntlContext } from 'react-intl';
import { isValidLocale, LocaleContext } from '../LocaleSupport';
import { buildThankYouRedirectForExternalBooking } from './ThankYouRedirect';
import { useBookingRouteParams } from './useBookingRouteParams';
import { MainViewFooter } from './MainViewFooter';
import { FullScreenError } from './FullScreenError';
import { parseQuerystring } from '../Utils';
import { getBrowserDefaultTimezone, isValidTimezone, setLocalStorageTimezone } from '../DateUtils';

export type ViewType = 'Calendar' | 'Default';

interface MainViewProps {
  slug: string;
}

const SavedView = getLocalStorage().getItem('ViewType') as ViewType;

let DefaultView: ViewType = 'Default';
if (SavedView === 'Calendar' && window.innerWidth > 600) {
  DefaultView = 'Calendar';
}

export const MainView: React.FC<MainViewProps> = ({ slug }) => {
  const requestContext = useContext(RequestContext);
  const localeContext = useContext(LocaleContext);
  const intl = useContext(IntlContext);
  const history = useHistory();

  const [viewType, setViewType] = useState<ViewType>(DefaultView);
  const [page, setPage] = useState<RequestState<Scheduling.Page>>(getNylasWindowContext().page || 'loading');
  const [slots, setSlots] = useState<RequestState<Scheduling.TimeSlot[] | Error>>('empty');
  const [guestAvailability, setGuestAvailability] =
    useState<RequestState<Scheduling.GuestAvailabilityResponse>>('empty');
  const appProvidedQuerystring = useRef(window.location.search.replace(/^\?/, ''));

  const params = useBookingRouteParams();

  const [querystringLocale] = useState<{ locale: string; readonly: boolean }>(() => {
    const localeFromQueryString = parseQuerystring(window.location.search)?.locale as string | undefined;
    const localeReadonly = parseQuerystring(window.location.search)?.locale_readonly as string | undefined;

    return {
      locale: isValidLocale(localeFromQueryString) ? (localeFromQueryString as string) : localeContext.locale,
      readonly: localeReadonly === 'true',
    };
  });

  useEffect(() => {
    if (querystringLocale) {
      localeContext.setLocale(querystringLocale.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [timezone, setTimezone] = useState(() => {
    const timezoneFromQueryString = parseQuerystring(window.location.search)?.tz;

    // if valid timezone is provided in querystring, use it
    if (timezoneFromQueryString && isValidTimezone(timezoneFromQueryString)) {
      setLocalStorageTimezone(timezoneFromQueryString);
      return timezoneFromQueryString;
    }
    // otherwise get browser default timezone, set to local storage, and use it
    else {
      setLocalStorageTimezone(getBrowserDefaultTimezone());
      return getBrowserDefaultTimezone();
    }
  });

  useEffect(() => {
    if (page === 'loading') {
      makeRequest<Scheduling.Page>(requestContext, `/schedule/${slug}/info`).then(
        (result) => setPage(result),
        (error) => setPage(error),
      );
    }
    if (window.location.href.includes('availability')) {
      const service = window.location.href.includes('availability=google') ? 'google' : 'o365';
      makeRequest<Scheduling.GuestAvailabilityResponse>(requestContext, `/schedule/availability/${service}`).then(
        (guestAvailability) => setGuestAvailability(guestAvailability),
        (error) => setGuestAvailability(error),
      );
    }

    const loadSlots = async () => {
      setSlots('loading');
      await refreshSlots({ allowStale: true });
      await refreshSlots({ allowStale: false });
    };
    loadSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSlots = async ({ allowStale }: { allowStale: boolean }) => {
    const ms = Date.now();
    const query = qs.stringify({
      allow_stale: allowStale || undefined,
      edit_hash: params.hash || undefined,
      locale: querystringLocale.locale || localeContext.locale,
    });

    let result: RequestState<Scheduling.TimeSlot[]> | Error;
    try {
      result = await makeRequest<Scheduling.TimeSlot[]>(requestContext, `/schedule/${slug}/timeslots?${query}`);
    } catch (error) {
      result = error as Error;
    }

    const elapsed = Date.now() - ms;
    console.log(`Fetched timeslots (allow stale=${allowStale}) in ${elapsed}ms`);
    setSlots(result);
  };

  if (page === 'loading' || page === 'empty') {
    return (
      <div className="BookingMainView">
        <div className="sidebar" />
        <div className="detail" />
      </div>
    );
  }
  if (page instanceof Error) {
    return <FullScreenError message={page.message} />;
  }
  if (slots instanceof Error) {
    var message: string;
    try {
      const json = JSON.parse(slots.message);
      message = json.message;
    } catch (err) {
      message = slots.message;
    }
    return (
      <FullScreenError
        complete={true}
        message={message}
        onRetry={() => {
          setSlots('loading');
          refreshSlots({ allowStale: false });
        }}
      />
    );
  }

  const hideTimezoneOptions = page.config.appearance.show_timezone_options === false;
  const hideWeekView = page.config.appearance.show_week_view === false || page.config.event.duration < 15;

  // note: show_week_view may be `true` or `undefined`, should only be disabled
  // if the value is explicitly false.
  let finalViewType = viewType;
  if (hideWeekView) {
    finalViewType = 'Default';
  }

  const onSlotClicked = async (slot: Scheduling.TimeSlot) => {
    if (page.config.booking?.confirmation_method === 'external') {
      if (!page.config.appearance?.thank_you_redirect) {
        console.warn('To use the `external` confirmation mode, you need to provide a thank_you_redirect.');
        return;
      }
      const { success } = await makeRequest<{ success: boolean }>(
        requestContext,
        `/schedule/${slug}/timeslots/verify`,
        'POST',
        { slot },
      );
      if (!success) {
        alert(intl.formatMessage(BOOKING_TAKEN_MESSAGE));
        history.push(`/${page.slug}`);
        return;
      }

      track('Redirected to Custom Thanks Page', { url: page.config.appearance.thank_you_redirect });

      window.location.href = buildThankYouRedirectForExternalBooking({
        url: page.config.appearance.thank_you_redirect,
        appProvidedQuerystring: appProvidedQuerystring.current,
        slug: page.slug,
        slot,
      });
    } else {
      history.push(`/${page.slug}/book/${slot.start}${params.hash ? `?editHash=${params.hash}` : ''}`);
    }
  };

  let CoveringView: React.ReactNode = null;
  if (params.action === 'cancel') {
    CoveringView = <SlotCancelView page={page} editHash={params.hash} />;
  } else if (params.action === 'confirm') {
    CoveringView = <HostApproveBookingView page={page} editHash={params.hash} />;
  } else if (params.start && slots instanceof Array) {
    const selection = slots.find((s) => s.start === Number(params.start));

    if (selection) {
      CoveringView = (
        <SlotConfirmView
          page={page}
          slot={selection}
          editHash={params.hash}
          guestAvailability={guestAvailability}
          appProvidedQuerystring={appProvidedQuerystring.current}
        />
      );
    } else {
      CoveringView = <BookingTakenRedirect to={params.hash ? `/${slug}/reschedule/${params.hash}` : `/${slug}`} />;
    }
  }

  return (
    <div className="BookingMainView">
      <ThemeVariables theme={{ tintColor: page.config.appearance.color, backgroundColor: '#fff' }} />

      <div className="top-bar">
        {page.config.appearance.logo && (
          <div className="logo">
            <img src={page.config.appearance.logo} alt="Logo" />
          </div>
        )}
        <h1 className="header">{page.config.event.title}</h1>
        {page.config.appearance.logo && <div className="logo-spacer" />}
      </div>

      {params.action === 'reschedule' && (
        <div className="reschedule-header">
          <FormattedMessage
            description="Header - Rescheduling"
            id="Header-Rescheduling"
            defaultMessage="Choose another time below to reschedule your upcoming meeting."
          />
        </div>
      )}

      <div
        className={`ShadowedContent 
          slot-duration-${page.config.event.duration}
          ${(slots !== 'loading' || params.action) && 'loaded'}`}
      >
        <div className={CoveringView ? 'covered' : ''}>
          {CoveringView}

          <div className="spinner-container">
            <Icons.Spinner color="#666" width={40} height={40} />
            <div className="spinner-slow-message">
              <FormattedMessage
                id="tsl"
                description="Timeslots - Loading message"
                defaultMessage="Still fetching available timeslots..."
              />
            </div>
          </div>
          <CalendarView
            slots={slots}
            viewType={finalViewType}
            showAutoschedule={page.config.appearance.show_autoschedule !== false}
            guestAvailability={guestAvailability}
            onSlotClicked={onSlotClicked}
            timezoneOverride={timezone}
          />
          <MainViewFooter
            hideTimezoneOptions={hideTimezoneOptions}
            hideWeekView={hideWeekView}
            viewType={finalViewType}
            setViewType={setViewType}
            localeOverride={querystringLocale.readonly ? querystringLocale.locale : page.config.locale_for_guests}
            timezone={timezone}
            setTimezone={setTimezone}
          />
        </div>
      </div>

      {page.config.appearance.show_nylas_branding && (
        <div className="poweredBy">
          <img src={require('../icons/nylas_lockup_vertical.png').default} alt="Nylas Logo" />
          <div>
            <FormattedMessage id="pbn" description="Powered-by-Nylas" defaultMessage="Powered by Nylas" />
          </div>
        </div>
      )}
    </div>
  );
};

const BOOKING_TAKEN_MESSAGE = {
  id: 'e-bt',
  description: 'Error - Booking Taken',
  defaultMessage: "Sorry, the timeslot you've selected is no longer available. Please choose another time.",
};

const BookingTakenRedirect: React.FunctionComponent<{ to: string }> = (props) => {
  const intl = useIntl();
  alert(intl.formatMessage(BOOKING_TAKEN_MESSAGE));

  return <Redirect to={props.to} />;
};
