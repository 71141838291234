import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Scheduling } from '../types/Scheduling';
import { BookingForm } from './BookingForm';
import { BookingSummary } from './BookingSummary';
import { ModalDoneState, ModalErrorState } from './ModalCommonStates';
import { Request, ImmediateRequest, RequestState } from '../components/NetworkRequest';
import { getHHMMString, getLongDateString, getCurrentTimezone, getTimezoneAdjusted } from '../DateUtils';
import { parseQuerystring, fieldValuesFromQuerystring } from '../Utils';
import * as Icons from '../components/SVGIcons';

import './SlotConfirmView.scss';
import { track } from '../NylasWindowContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { buildThankYouRedirect } from './ThankYouRedirect';

interface SlotConfirmViewProps {
  slot: Scheduling.TimeSlot;
  editHash?: string;
  appProvidedQuerystring: string;
  guestAvailability: RequestState<Scheduling.GuestAvailabilityResponse>;
  page: Scheduling.Page;
}

const DoneState = ({
  booking,
  config,
  slug,
  appProvidedQuerystring,
}: {
  slug: string;
  booking: Scheduling.Booking;
  config: Scheduling.PageConfig;
  appProvidedQuerystring: string;
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (config.appearance.thank_you_redirect) {
      track('Redirected to Custom Thanks Page', { url: config.appearance.thank_you_redirect });
      window.location.href = buildThankYouRedirect({
        url: config.appearance.thank_you_redirect,
        appProvidedQuerystring,
        booking,
        slug,
        changeUrlParameter: config.features?.change_name_url_parameter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalDoneState
      title={
        config.appearance.thank_you_text ||
        intl.formatMessage({
          id: 'be-yals',
          defaultMessage: "You're all set.",
          description: 'Booking - Default Thank you text',
        })
      }
    >
      <div style={{ padding: '0 30px' }}>
        {config.appearance.thank_you_text_secondary ||
          (config.booking.confirmation_emails_to_guests === false ? (
            <FormattedMessage
              id="Booking-cnfu"
              description="Booking Confirmed - No follow-up"
              defaultMessage="Your response has been recorded."
            />
          ) : config.booking.confirmation_method === 'automatic' ? (
            <FormattedMessage
              id="Booking-cefu"
              description="Booking Confirmed - Email follow-up"
              defaultMessage="You'll receive an email confirmation at {email}"
              values={{
                email: booking.recipient_email,
              }}
            />
          ) : (
            <FormattedMessage
              id="Booking-cefah"
              description="Booking Confirmed - Email after host confirmation"
              defaultMessage="When the host confirms your booking, you'll receive an email confirmation at {email}"
              values={{
                email: booking.recipient_email,
              }}
            />
          ))}
      </div>
    </ModalDoneState>
  );
};

const atTimeAndDate = ({ start_time, end_time }: Scheduling.Booking) => {
  const [adjusted] = getTimezoneAdjusted([{ start: start_time, end: end_time }]);
  const date = new Date(adjusted.start * 1000);
  return `@ ${getHHMMString(date)} ${getLongDateString(date)}`;
};

type PropsWithData = SlotConfirmViewProps & {
  replacesBooking?: Scheduling.Booking;
};

export const SlotConfirmViewWithData: React.FC<PropsWithData> = ({
  page,
  slot,
  replacesBooking,
  editHash,
  guestAvailability,
  appProvidedQuerystring,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [showBackButton, setShowBackButton] = useState(true);
  const [booked, setBooked] = useState(false);

  const guestInfo = guestAvailability instanceof Object && 'busy' in guestAvailability ? guestAvailability : null;
  const querystringInfo = resolveQuerystringAliases(parseQuerystring(appProvidedQuerystring));
  const querystringFields = fieldValuesFromQuerystring(querystringInfo, page.config.booking.additional_fields);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const backBtnParam = queryParams.get('back_btn');
    if (backBtnParam && backBtnParam.toLowerCase() === 'false') {
        setShowBackButton(false);
    } else {
        setShowBackButton(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onBack = (hardRefresh: boolean) => {
    const basePagePath = window.location.pathname.split('/book/')[0];
    const targetPath = basePagePath + (editHash ? `/reschedule/${editHash}` : '');

    if (!hardRefresh) {
      // Note: pathname: must be given just the pathname, not the full href
      history.push({ pathname: targetPath });
    } else {
      // If the user has just booked, reload the entire React app by navigating back at the window.location
      // level - this forces a reload of the timeslots so the user doesn't see stale data after booking.
      // Note: We need to pass the querystring because we have stripped it from the URL into state.
      window.location.href = `${targetPath}?${appProvidedQuerystring}`;
    }
  };

  return (
    <>
      {showBackButton && (<a
          href="#/"
          className="back"
          onClick={(e) => {
            e.preventDefault();
            onBack(!!booked);
          }}
        >
          <Icons.ArrowLeft /> <FormattedMessage defaultMessage="Back" description="Back" id="Back" />
        </a>)}{' '}
      <div className="left-panel">
        <BookingSummary {...slot} />
        {replacesBooking && (
          <div className="replaces-booking-info">
            {page.config.booking.confirmation_method === 'manual' ? (
              <FormattedMessage
                id="Booking-rnahc"
                description="Booking replace notice - after host confirmation"
                defaultMessage="Your meeting {existing_date_and_time} will be cancelled now and the new time will be booked when the host confirms your request."
                values={{
                  existing_date_and_time: atTimeAndDate(replacesBooking),
                }}
              />
            ) : (
              <FormattedMessage
                id="Booking-rni"
                description="Booking replace notice - immediate"
                defaultMessage="Replaces your upcoming booking {existing_date_and_time}."
                values={{
                  existing_date_and_time: atTimeAndDate(replacesBooking),
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="divider" style={{ alignSelf: 'stretch' }} />
      <div className="right-panel">
        <Request<Scheduling.Booking, Scheduling.BookSlotPayload>
          path={`/schedule/${page.slug}/timeslots`}
          method="POST"
        >
          {(result, sendBookingRequest, clearRequest) => {
            if (result instanceof Error) {
              return (
                <ModalErrorState
                  message={result.message}
                  buttonType="back"
                  onClick={() => {
                    clearRequest();
                    onBack(true);
                  }}
                />
              );
            }

            if (result === 'empty' || result === 'loading') {
              return (
                <BookingForm
                  loading={result === 'loading'}
                  initialValues={
                    replacesBooking
                      ? {
                        name: replacesBooking.recipient_name,
                        email: replacesBooking.recipient_email,
                        additional_emails: [],
                        ...replacesBooking.additional_field_values,
                      }
                      : {
                        name: querystringFields.values.name || (guestInfo && guestInfo.name) || '',
                        email: querystringFields.values.email || (guestInfo && guestInfo.email) || '',
                        additional_emails: querystringFields.values.additional_emails || [],
                        ...querystringFields.values,
                      }
                  }
                  readonlyFieldNames={querystringFields.readonly}
                  config={page.config}
                  onSubmit={async (values) => {
                    if (result === 'loading') return;

                    if (editHash) {
                      track('Booking Rescheduled', { slot });
                    } else {
                      track('Booking Created', { slot });
                    }

                    const { email, additional_emails, ...additional_values } = values;
                    const name = page.config.booking.name_field_hidden ? values.email : values.name;

                    const booking = await sendBookingRequest({
                      name,
                      email,
                      additional_values,
                      additional_emails,
                      slot,
                      locale: intl.locale,
                      timezone: getCurrentTimezone(),
                      replaces_booking_hash: editHash,
                      page_hostname: window.location.host,
                      page_querystring: appProvidedQuerystring,
                    });
                    if (!(booking instanceof Error)) {
                      setBooked(true);
                    } else {
                      track('Booking Error', { message: booking.message });
                    }
                  }}
                />
              );
            }
            return (
              <DoneState
                booking={result}
                config={page.config}
                slug={page.slug}
                appProvidedQuerystring={appProvidedQuerystring}
              />
            );
          }}
        </Request>
      </div>
    </>
  );
};

export const SlotConfirmView: React.FunctionComponent<SlotConfirmViewProps> = (props) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <div className="SlotConfirmView">
      {props.editHash ? (
        <ImmediateRequest<Scheduling.Booking, {}> path={`/schedule/${props.page.slug}/${props.editHash}`}>
          {(result, retryRequest) =>
            result instanceof Error ? (
              result.status === 404 ? (
                <ModalErrorState
                  buttonType="back"
                  onClick={() => history.push(`/${props.page.slug}`)}
                  message={intl.formatMessage({
                    id: 'be-rac',
                    defaultMessage:
                      'It looks like the booking you are trying to reschedule has already been cancelled.',
                    description: 'Booking Error - Rescheduling after cancellation',
                  })}
                />
              ) : (
                <ModalErrorState
                  buttonType="try-again"
                  onClick={() => retryRequest()}
                  message={intl.formatMessage({
                    id: 'beg',
                    defaultMessage: 'Sorry, we couldn\'t create your booking.',
                    description: 'Booking Error - Generic',
                  })}
                />
              )
            ) : result === 'loading' || result === 'empty' ? (
              <span />
            ) : (
              <SlotConfirmViewWithData {...props} replacesBooking={result} />
            )
          }
        </ImmediateRequest>
      ) : (
        <SlotConfirmViewWithData {...props} />
      )}
    </div>
  );
};

function resolveQuerystringAliases(querystring: { [key: string]: string }) {
  // We want to keep the name consistent internally, but this is displayed as "Additional Guests"
  // so it seems developers may not guess to try additional_emails
  if (querystring['additional_guests']) {
    querystring['additional_emails'] = querystring['additional_guests'];
    querystring['additional_emails_readonly'] = querystring['additional_guests_readonly'];
  }
  // We initially supported setting name / email, but the fields on the booking model are "recipient_email".
  // As we expose the scheduler's API more it's likely people will try these and expect them to work.
  if (querystring['recipient_email'] && !querystring['email']) {
    querystring['email'] = querystring['recipient_email'];
    querystring['email_readonly'] = querystring['recipient_email_readonly'];
  }
  if (querystring['recipient_name'] && !querystring['name']) {
    querystring['name'] = querystring['recipient_name'];
    querystring['name_readonly'] = querystring['recipient_name_readonly'];
  }

  return querystring;
}
