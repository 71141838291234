import React from 'react';
import './TokenizedInput.scss';
import { clone } from '../Utils';
import * as Icons from '../components/SVGIcons';

interface TokenizedInputProps {
  items: string[];
  readonly?: boolean;
  tokenValidator: (value: string) => boolean;
  onChange?: (values: string[]) => void;
}

class TokenizedInput extends React.Component<TokenizedInputProps> {
  tokenInputRef: React.RefObject<TokenInputListItem>;

  constructor(props: any) {
    super(props);
    this.tokenInputRef = React.createRef();
  }

  focus = () => {
    const input = this.tokenInputRef.current;
    if (input) {
      input.focus();
    }
  };

  handleTokenAdded = (value: string) => {
    const items = clone(this.props.items);
    items.push(value);

    if (this.props.onChange) {
      this.props.onChange(items);
    }
  };

  handleTokenRemoved = (index: number) => {
    const items = clone(this.props.items);
    items.splice(index, 1);

    if (this.props.onChange) {
      this.props.onChange(items);
    }
  };

  handleDeleteLastItem = () => {
    if (this.props.items.length > 0) {
      this.handleTokenRemoved(this.props.items.length - 1);
    }
  };

  render() {
    return (
      <ul className={`TokenizedInput ${this.props.readonly && 'readonly'}`} onClick={this.focus}>
        {this.props.items.map((item, index) => (
          <TokenListItem
            key={index}
            label={item}
            isValid={this.props.tokenValidator(item)}
            onRemove={() => this.handleTokenRemoved(index)}
          />
        ))}

        <TokenInputListItem
          ref={this.tokenInputRef}
          onItemAdded={this.handleTokenAdded}
          onDeleteLastItem={this.handleDeleteLastItem}
          readonly={this.props.readonly}
        />
      </ul>
    );
  }
}

class TokenInputListItem extends React.Component<{
  onItemAdded: (value: string) => void;
  onDeleteLastItem: () => void;
  readonly?: boolean;
}> {
  input: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.input = React.createRef();
  }

  focus() {
    const input = this.input.current;
    if (input) {
      input.focus();
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = this.input.current;
    if (!input) {
      return;
    }

    const inputValue = input.value.replace(',', '');

    switch (event.keyCode) {
      case 8: // delete
        if (inputValue.length === 0) {
          this.props.onDeleteLastItem();
          input.value = '';
        }
        break;
      case 13: // enter
        if (inputValue.length > 0) {
          this.props.onItemAdded(inputValue);
          input.value = '';
          event.preventDefault();
        }
        break;
      case 188: // comma
      case 32: // space
        if (inputValue.length > 0) {
          this.props.onItemAdded(inputValue);
          input.value = '';
        }
        event.preventDefault();
        break;
      default:
        // do nothing
        break;
    }
  };

  handleBlur = () => {
    const input = this.input.current;
    if (!input) return;
    const inputValue = input.value.replace(',', '');
    if (inputValue.length > 0) {
      this.props.onItemAdded(inputValue);
      input.value = '';
    }
  };

  render() {
    return (
      <li className="tokenInput">
        <input
          ref={this.input}
          onBlur={this.handleBlur}
          onKeyDown={(e) => this.handleKeyDown(e)}
          disabled={this.props.readonly}
          readOnly={this.props.readonly}
        />
      </li>
    );
  }
}

const TokenListItem: React.FunctionComponent<{ label: string; isValid: boolean; onRemove: () => void }> = ({
  label,
  isValid,
  onRemove,
}) => (
  <li className={`token ${!isValid ? 'invalid' : ''}`}>
    <span className="delete" onClick={onRemove}>
      <Icons.X width={8} height={8} />
    </span>
    <span className="label">{label}</span>
  </li>
);

export default TokenizedInput;
