import React, { Component } from 'react';
import { clone, diff, DefaultOpeningHourRange, parsePageConfigErrors } from '../Utils';
import { FormWithSpy, Field } from '../components/FormHelpers';
import { TimeField } from './TimeField';

import Button from '../components/Button';
import SVGButton from '../components/SVGButton';
import * as Icons from '../components/SVGIcons';
import { OpeningHoursRow, HourRange } from './OpeningHoursView';
import { PageWizardStepViewProps } from './PageWizardView';

import './PageWizardEventAvailabilityView.scss';
import { makeRequest, RequestContext } from '../components/NetworkRequest';
import { FormattedMessage } from 'react-intl';
import { AlertBoxAlerts } from '../components/AlertBox';

interface FormValues {
  slug: string;
  futureLimit: number;
  [key: string]: any;
}

interface PageWizardEventAvailabilityViewState {
  fetchingBestSlug: boolean;
  [key: string]: any;
}

class PageWizardEventAvailabilityView extends Component<PageWizardStepViewProps, PageWizardEventAvailabilityViewState> {
  _mounted: boolean = false;

  constructor(props: PageWizardStepViewProps) {
    super(props);

    this.state = {
      fetchingBestSlug: false,
    };
  }

  getFormValues(): FormValues {
    return {
      slug: this.props.pageSlug,
      futureLimit: this.props.pageConfig.booking.available_days_in_future,
    };
  }

  async componentDidMount() {
    this._mounted = true;

    if (this.props.pageSlug === '') {
      this.setState({ fetchingBestSlug: true });
      try {
        const { slug } = await makeRequest<{ slug: any }>(this.context, '/manage/next-available-slug', 'POST', {
          event_config: this.props.pageConfig.event,
          api_token: this.context.apiAuthToken,
        });
        if (this.props.pageSlug === '' && this._mounted) {
          this.props.onChange(this.props.pageConfig, slug);
          this.setState({ fetchingBestSlug: false });
        }
      } catch (error) {
        let errors: AlertBoxAlerts[] = [];
        if (error instanceof Error) {
          errors = [{ message: parsePageConfigErrors(error.message, this.props.pageConfig) }];
        }
        this.props.setWizardError('An error occurred while attempting to fetch an available slug.', errors);
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleFormChange = (values: FormValues) => {
    const lastValues = this.getFormValues();
    const difference = diff(values, lastValues);
    if (difference.length === 0) {
      return;
    }

    // We keep track of the new props and state we're going to be persisting and then batch it all at the end.
    // - Props: Cloned so we can modify it based on the form values that have changed and then pass it to the change handler.
    // - State: Track changes and merge with existing state below to keep track of changes to time unit values.
    const updatedConfig = clone(this.props.pageConfig);

    difference.forEach((key) => {
      // If the unit has changed we track it in state here so it'll display correctly on re-render.
      if (key === 'futureLimit') {
        updatedConfig.booking.available_days_in_future = Number(values[key]);
      }
    });

    // Set the `values` key to the new form values based on our updated config (which is a copy of the
    // props passed in) and our updated state (joined this.state and updated state).
    this.props.onChange(updatedConfig, values.slug);
  };

  changeHours = (fn: (items: HourRange[]) => void) => {
    const nextPageConfig = clone(this.props.pageConfig);
    fn(nextPageConfig.booking.opening_hours);
    this.props.onChange(nextPageConfig);
  };

  handleAddButtonClicked = () => {
    this.changeHours((items) => items.push(Object.assign({}, DefaultOpeningHourRange)));
  };

  render() {
    const { behavior, pageDomain } = this.props.config;

    return (
      <div className="PageWizardEventAvailabilityView">
        <FormWithSpy onChange={this.handleFormChange} onSubmit={() => {}} initialValues={this.getFormValues()}>
          {() => (
            <>
              <div className="OpeningHoursView">
                <h2>
                  <FormattedMessage
                    defaultMessage="When can events be booked?"
                    description="Settings - Availability - Opening Hours Prompt"
                    id="Settings-aohp"
                  />
                </h2>
                {this.props.pageConfig.booking.opening_hours.map((item, index) => (
                  <OpeningHoursRow
                    key={index}
                    value={item}
                    onChange={(newItem) => this.changeHours((items) => items.splice(index, 1, newItem))}
                    action={
                      <SVGButton
                        title="Remove"
                        style={{ marginLeft: 15 }}
                        onClick={() => this.changeHours((items) => items.splice(index, 1))}
                      >
                        <Icons.X width={12} height={12} />
                      </SVGButton>
                    }
                  />
                ))}

                <Button onClick={this.handleAddButtonClicked} size="small">
                  <FormattedMessage defaultMessage="Add" description="Button-Add" id="Button-Add" />
                </Button>
              </div>

              {!behavior.disableEditing.includes('available_days_in_future') && (
                <div className="textInputContainer">
                  <h2>
                    <FormattedMessage
                      defaultMessage="How far in the future can events be booked?"
                      description="Settings - Availability - Future Days Prompt"
                      id="Settings-afdp"
                    />
                  </h2>
                  <TimeField fieldKey="futureLimit" label="" />
                </div>
              )}

              {!behavior.disableEditing.includes('slug') && (
                <div className="textInputContainer">
                  <h2>
                    <FormattedMessage
                      defaultMessage="Where should we create the booking page?"
                      description="Settings - Availability - Slug Prompt"
                      id="Settings-asp"
                    />
                  </h2>
                  <div className="slugContainer">
                    <div className="slugLabel">{pageDomain}/</div>
                    <Field className="slugField" name="slug" component="input" type="text" />
                    {this.state.fetchingBestSlug && <Icons.Spinner width={25} height={25} color="gray" />}
                  </div>
                </div>
              )}
            </>
          )}
        </FormWithSpy>
      </div>
    );
  }
}

PageWizardEventAvailabilityView.contextType = RequestContext;

export default PageWizardEventAvailabilityView;
