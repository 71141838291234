import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../components/Button';
import * as Icons from '../components/SVGIcons';
import { FormattedError } from './FormattedError';

export type ViewType = 'Calendar' | 'Default';

interface FullScreenErrorProps {
  message?: string;
  complete?: boolean;
  onRetry?: () => void;
}

export const FullScreenError: React.FunctionComponent<FullScreenErrorProps> = (props) => (
  <div className="FullScreenError">
    <Icons.UhOhFail width="160" height="160" />
    {props.complete ? (
      <FormattedError
        message={props.message} />
    ) :
      <div style={{ marginBottom: 30 }}>{props.message}</div>
    }
    {props.onRetry && (
      <Button onClick={props.onRetry}>
        <FormattedMessage description="Try Again" id="Try-Again" defaultMessage="Try Again" />
      </Button>
    )}
  </div>
);
