let storage: Storage | null = null;

class MockStorage implements Storage {
  items: { [key: string]: any } = {};

  getItem(key: string) {
    return this.items[key] || null;
  }
  setItem(key: string, val: any) {
    this.items[key] = `${val}`;
  }
  removeItem(key: string) {
    delete this.items[key];
  }
  clear() {
    this.items = {};
  }
  get length() {
    return Object.keys(this.items).length;
  }
  key(index: number) {
    return Object.keys(this.items)[index];
  }
}

// LocalStorage is unavailable in private browsing mode in some browsers (in "strict" mode).
// Check that it's available and if not, return a stand-in that will function the same way
// but with no persistent storage.
export function getLocalStorage() {
  if (storage) {
    return storage;
  }

  let available = false;
  try {
    'localStorage' in window && window.localStorage.setItem('test', 'test');
    if (window.localStorage.getItem('test') === 'test') {
      available = true;
    }
  } catch (err) {
    // ignore
  }
  storage = available ? window.localStorage : new MockStorage();

  return storage;
}
