import React from 'react';
import { TabComponentProps } from './PageDetailView';
import { Scheduling } from '../types/Scheduling';
import { FormBlock, TypedSelectField, FormWithSpy, Field } from '../components/FormHelpers';
import DropdownValuesPicker from '../components/DropdownValuesPicker';
import MultiSelectValuesPicker from '../components/MultiSelectValuesPicker';
import Button from '../components/Button';
import * as Icons from '../components/SVGIcons';
import { clone, DefaultAdditionalField, fieldNameFromLabel, USER_DEFINED_PREFIX } from '../Utils';

import './CustomFieldsView.scss';
import SVGButton from '../components/SVGButton';
import { Sheet, SheetBody } from '../components/Sheet';
import { FormattedMessage, injectIntl, IntlShape, useIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

type AdditionalField = Scheduling.PageConfigAdditionalField;
interface AdditionalFieldWithDropdownInput extends AdditionalField {
  addDropdownInput?: string;
  addMultiSelectInput?: string;
}

class CustomFieldsView extends React.Component<TabComponentProps & { intl: IntlShape }, { editing?: AdditionalField }> {
  constructor(props: any) {
    super(props);
    this.state = { editing: undefined };
  }

  handleRemoveField = (index: number) => {
    const page = clone(this.props.page);
    page.config.booking.additional_fields.splice(index, 1);
    this.props.onChange(page);
  };

  handleSaveField = (original: AdditionalField, updated: AdditionalField) => {
    const index = this.props.page.config.booking.additional_fields.indexOf(original);
    const page = clone(this.props.page);
    if (index === -1) {
      page.config.booking.additional_fields.push(updated);
    } else {
      page.config.booking.additional_fields[index] = updated;
    }

    this.props.onChange(page);

    this.setState({ editing: undefined });
  };

  onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newFields = Array.from(this.props.page.config.booking.additional_fields);
    const [removed] = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, removed);
    newFields.forEach((item, index) => {
      item.order = index + 1;
    });

    const page = clone(this.props.page);
    page.config.booking.additional_fields = newFields;
    this.props.onChange(page);
  };

  getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'white' : 'white',
  });

  render() {
    const { intl, page } = this.props;

    return (
      <div className="CustomFieldsView">
        <h2>
          <FormattedMessage
            description="Settings - Custom Field - Title"
            id="Settings-cft"
            defaultMessage="What info should guests provide?"
          />
        </h2>
        {!this.state.editing && (
          <div className="fieldsContainer">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={this.getListStyle(snapshot.isDraggingOver)}
                  >
                    <div className="field">
                      <div style={{ flex: 1, paddingLeft: 35 }}>
                        <FormattedMessage defaultMessage="Name" description="Field Label - Name" id="fl-n" />
                        <div style={{ width: 10 }} />
                        <FormattedMessage defaultMessage="(required)" description="(required)" id="(required)" />
                      </div>
                      <SVGButton
                        disabled
                        title={intl.formatMessage({
                          id: 'Button-Locked',
                          defaultMessage: 'Locked',
                          description: 'Button - Locked',
                        })}
                      >
                        <Icons.Lock width={12} height={12} />
                      </SVGButton>
                    </div>

                    <div className="field">
                      <div style={{ flex: 1, paddingLeft: 35 }}>
                        <FormattedMessage defaultMessage="Email" description="Field Label - Email" id="fl-e" />
                        <div style={{ width: 10 }} />
                        <FormattedMessage defaultMessage="(required)" description="(required)" id="(required)" />
                      </div>
                      <SVGButton
                        disabled
                        title={intl.formatMessage({
                          id: 'Button-Locked',
                          defaultMessage: 'Locked',
                          description: 'Button - Locked',
                        })}
                      >
                        <Icons.Lock width={12} height={12} />
                      </SVGButton>
                    </div>
                    {page.config.booking.additional_fields.map((e, index) => (
                      <CustomField
                        key={e.name}
                        field={e}
                        index={index}
                        onEdit={() => this.setState({ editing: e })}
                        onRemove={() => this.handleRemoveField(index)}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {!this.state.editing && (
          <Button
            style={{ marginTop: 15 }}
            title={intl.formatMessage({
              id: 'Settings-cfabb',
              defaultMessage: 'Add field',
              description: 'Settings - Custom Field - Add Button',
            })}
            size="small"
            onClick={() => this.setState({ editing: DefaultAdditionalField })}
          >
            <FormattedMessage
              id="Settings-cfabb"
              defaultMessage="Add field"
              description="Settings - Custom Field - Add Button"
            />
          </Button>
        )}
        {this.state.editing && (
          <AddFieldSheet
            field={this.state.editing}
            onSave={this.handleSaveField}
            onDismiss={() => this.setState({ editing: undefined })}
            intl={intl}
          />
        )}
      </div>
    );
  }
}

interface AddFieldSheetProps {
  field: AdditionalField;
  intl: IntlShape;
  onSave: (original: AdditionalField, changed: AdditionalField) => void;
  onDismiss: () => void;
}

interface AddFieldSheetState {
  scratchField: AdditionalField;
  addDropdownInput?: string;
  addMultiSelectInput?: string;
}

class AddFieldSheet extends React.Component<AddFieldSheetProps, AddFieldSheetState> {
  constructor(props: Readonly<AddFieldSheetProps>) {
    super(props);
    this.state = { scratchField: clone(props.field), addDropdownInput: '', addMultiSelectInput: '' };
  }

  handleFormChange = (values: AdditionalFieldWithDropdownInput) => {
    if (values.label && (!values.name || values.name.startsWith(USER_DEFINED_PREFIX))) {
      values.name = fieldNameFromLabel(values.label);
    }

    if (this.props.field.type === 'dropdown') {
      const addDropdownInput = values.addDropdownInput;
      delete values.addDropdownInput;

      const fieldValues = values as AdditionalField;
      this.setState({ scratchField: fieldValues, addDropdownInput });
    }

    if (this.props.field.type === 'multi-select list') {
      const addMultiSelectInput = values.addMultiSelectInput;
      delete values.addMultiSelectInput;

      const fieldValues = values as AdditionalField;
      this.setState({ scratchField: fieldValues, addMultiSelectInput });
    }
  };

  handleFormSave = (values: any) => {
    this.props.onSave(this.props.field, values);
  };

  render() {
    const { intl } = this.props;
    const { scratchField } = this.state;
    const labelStyle = { display: 'inline-block', minWidth: 80 };

    return (
      <Sheet
        onDismiss={this.props.onDismiss}
        title={intl.formatMessage({
          id: 'Settings-cfet',
          defaultMessage: 'Edit Field',
          description: 'Settings - Custom Field - Edit Title',
        })}
      >
        <SheetBody>
          <FormWithSpy
            onChange={this.handleFormChange}
            onSubmit={(values) => this.handleFormSave(values)}
            initialValues={scratchField}
          >
            {({ handleSubmit }) => (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormBlock
                    labelStyle={labelStyle}
                    label={intl.formatMessage({
                      id: 'Settings-cflp',
                      defaultMessage: 'Label:',
                      description: 'Settings - Custom Field - Label Prompt',
                    })}
                  >
                    <Field
                      name="label"
                      id="label"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'Settings-cflpf',
                        defaultMessage: 'Favorite Food',
                        description: 'Settings - Custom Field - Label Placeholder',
                      })}
                      component="input"
                    />
                  </FormBlock>
                  <FormBlock
                    labelStyle={labelStyle}
                    label={intl.formatMessage({
                      id: 'Settings-cftp',
                      defaultMessage: 'Type:',
                      description: 'Settings - Custom Field - Type Prompt',
                    })}
                  >
                    <TypedSelectField<AdditionalField['type']>
                      name="type"
                      style={{ width: 195 }}
                      options={[
                        { value: 'text' },
                        { value: 'multi-line text' },
                        { value: 'email' },
                        { value: 'phone' },
                        { value: 'number' },
                        { value: 'dropdown' },
                        { value: 'checkbox' },
                        { value: 'multi-select list' },
                        { value: 'date' },
                      ]}
                    />
                  </FormBlock>
                  <div style={{ flex: 1, marginBottom: 30 }}>
                    <Field
                      name="required"
                      id="required"
                      render={({ input }) => (
                        <label className="checkbox-container" htmlFor="required">
                          <input id="required" type="checkbox" checked={input.value} onChange={input.onChange} />{' '}
                          <FormattedMessage defaultMessage="Required" description="Required" id="Required" />
                        </label>
                      )}
                    />
                  </div>
                </div>
                <Field
                  name="type"
                  id="type"
                  render={({ input }) => (
                    <>
                      {input.value === 'dropdown' && (
                        <FormBlock label="Dropdown Options">
                          <Field name="dropdown_options" component={DropdownValuesPicker} />
                        </FormBlock>
                      )}
                      {input.value === 'multi-select list' && (
                        <FormBlock label="Multi-Select Options">
                          <Field name="multi_select_options" component={MultiSelectValuesPicker} />
                        </FormBlock>
                      )}
                    </>
                  )}
                />
                <Button size="small" intent="default" onClick={() => handleSubmit()}>
                  <FormattedMessage defaultMessage="Save" description="Button - Save" id="Button-Save" />
                </Button>
              </>
            )}
          </FormWithSpy>
        </SheetBody>
      </Sheet>
    );
  }
}

const CustomField: React.FunctionComponent<{
  field: AdditionalField;
  index: number;
  onEdit?: (field: AdditionalField) => void;
  onRemove?: (field: AdditionalField) => void;
}> = ({ field, index, onEdit, onRemove }) => {
  const intl = useIntl();

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // change background colour if dragging
    background: isDragging ? 'lightgray' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable draggableId={field.name} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="field"
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <SVGButton
            {...(provided.dragHandleProps as any)}
            title={intl.formatMessage({ id: 'Button-Drag', defaultMessage: 'Drag', description: 'Button - Drag' })}
          >
            <Icons.Drag width={15} height={15} />
          </SVGButton>

          <div style={{ flex: 1, paddingLeft: 10 }}>
            {field.label}
            {field.required && <div style={{ width: 10 }} />}
            {field.required && (
              <FormattedMessage defaultMessage="(required)" description="(required)" id="(required)" />
            )}
          </div>
          <div className="fieldActionButtons">
            {onEdit && onRemove && (
              <>
                <SVGButton
                  title={intl.formatMessage({
                    id: 'Button-Edit',
                    defaultMessage: 'Edit',
                    description: 'Button - Edit',
                  })}
                  onClick={() => onEdit(field)}
                >
                  <Icons.Edit width={15} height={15} />
                </SVGButton>
                <SVGButton
                  title={intl.formatMessage({
                    id: 'Button-Remove',
                    defaultMessage: 'Remove',
                    description: 'Button - Remove',
                  })}
                  onClick={() => onRemove(field)}
                >
                  <Icons.X width={12} height={12} />
                </SVGButton>
              </>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default injectIntl(CustomFieldsView);
