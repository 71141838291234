import React, { CSSProperties } from 'react';
import { Field, FieldProps } from 'react-final-form';
import { capitalize } from '../Utils';
import { Form as RFForm, FormSpy as RFFormSpy, FormRenderProps as RFFormRenderProps } from 'react-final-form';

import './FormHelpers.scss';
import { Tooltip } from './Tooltip';

// Re-export field so consumers can retrieve Form and Field from the same place
export { Field } from 'react-final-form';

/*
This is a small wrapper around React Final Form that types `values` so we don't have to 
deal with them being "any" all over the place. This also attaches a form spy if you pass
an `onChange` prop (indicating that you want the form to act as a controlled component.)
*/
export function FormWithSpy(props: {
  initialValues: any;
  onChange?: (values: any) => void;
  onSubmit: (values: any) => void;
  validate?: (values: any) => object | Promise<object>;
  children: (renderProps: RFFormRenderProps) => React.ReactNode;
}) {
  return (
    // Use initialValuesEqual to compare objects so we're only notified of changes when objects are actually modified
    <RFForm
      initialValues={props.initialValues}
      initialValuesEqual={(lhs, rhs) => JSON.stringify(lhs) === JSON.stringify(rhs)}
      onSubmit={(values) => props.onSubmit(values)}
      validate={props.validate ? (values) => props.validate!(values) : undefined}
    >
      {(renderProps) => (
        <form onSubmit={renderProps.handleSubmit}>
          {props.onChange && (
            <RFFormSpy onChange={(formState) => props.onChange!(formState.values)} subscription={{ values: true }} />
          )}
          {props.children(renderProps)}
        </form>
      )}
    </RFForm>
  );
}

export const FormBlock: React.FunctionComponent<{
  label: string;
  tooltip?: string;
  children: React.ReactElement<any>;
  bigLabel?: boolean;
  labelStyle?: CSSProperties;
}> = ({ label, children, bigLabel = false, tooltip, labelStyle = {} }) => (
  <div className={`FormBlock ${bigLabel && 'big-label'}`}>
    <label htmlFor={label} style={{ ...labelStyle }}>
      {bigLabel ? (
        <h2>
          {label} <Tooltip text={tooltip} />
        </h2>
      ) : (
        <span>
          {label} <Tooltip text={tooltip} />
        </span>
      )}
    </label>
    {React.cloneElement(children, { id: label })}
  </div>
);

interface TypedSelectFieldProps<T> extends FieldProps<any> {
  options: {
    key?: string;
    label?: string | React.ReactNode;
    value: T;
  }[];
}

export class TypedSelectField<T extends string> extends React.Component<TypedSelectFieldProps<T>> {
  render() {
    const { options, ...rest } = this.props;
    return (
      <Field component="select" {...rest}>
        {options.map((e) => (
          <option key={e.key || e.value} value={e.value}>
            {e.label || capitalize(e.value)}
          </option>
        ))}
      </Field>
    );
  }
}
