import { Scheduling } from './types/Scheduling';
import { getCurrentTimezone } from './DateUtils';
import { BookingFormValues } from './app-booking/BookingForm';

export const MIN_PARTICIPANTS = 1;
export const MAX_PARTICIPANTS = 200;

export type EmbedTabKey =
  | 'reminders'
  | 'event-info'
  | 'calendars'
  | 'opening-hours'
  | 'booking-flow'
  | 'custom-fields'
  | 'page-styles';

export interface TokenInfo {
  account_name: string;
  account_email: string;
  app_client_id: string;
  app_organization_id: number;
}
export interface EmbedConfig {
  pageDomain: string; // replace references to schedule.nylas.com used in page URLs (for whitelabeling)
  auth: { pageEditToken: string } | { accessToken: string };
  style: {
    tintColor?: string;
    backgroundColor?: string;
    modalTitle?: string;
  };
  locale?: string;
  locale12HourTime?: boolean;
  behavior: {
    disableEditing: ('slug' | 'available_days_in_future')[];
    dismissOnBackgroundClick?: boolean;
    disableViewingPages?: boolean;
    displayOnly?: EmbedTabKey[];
    displayDoneButton?: boolean;

    // deprecated, use disableEditing instead
    disableSlugChanges?: boolean;
  };
  defaults: Partial<Scheduling.PageConfig>;
  token?: TokenInfo;
}

export const DefaultAdditionalField: Scheduling.PageConfigAdditionalField = {
  name: '',
  label: '',
  type: 'text',
  required: false,
};

export const DefaultReminder: Scheduling.PageConfigReminder = {
  time_before_event: 30,
  delivery_method: 'email',
  delivery_recipient: 'customer',
  email_subject: 'Your meeting is coming up!',
  webhook_url: '',
};

export const DefaultOpeningHourRange = {
  days: ['M', 'T', 'W', 'R', 'F'],
  start: '09:00',
  end: '17:00',
};

export const BasePageConfig: Scheduling.PageConfig = {
  calendar_ids: {},
  timezone: getCurrentTimezone(),
  event: {
    duration: 45,
    location: 'Location TBD',
    title: 'Meeting',
    capacity: 1,
  },
  appearance: {
    color: '#0068D3',
    company_name: '',
    submit_text: 'Submit',
    show_nylas_branding: false,
    logo: '',
  },
  reminders: [],
  booking: {
    additional_fields: [],
    available_days_in_future: 14,
    confirmation_method: 'automatic',
    min_booking_notice: 120,
    min_buffer: 30,
    min_cancellation_notice: 180,
    opening_hours: [DefaultOpeningHourRange],
    scheduling_method: 'round-robin-maximize-availability',
  },
};

export const nameField: Scheduling.PageConfigAdditionalField = {
  label: 'Name',
  name: 'name',
  type: 'text',
  required: true,
  order: 0,
};

export const emailField: Scheduling.PageConfigAdditionalField = {
  label: 'Email',
  name: 'email',
  type: 'email',
  required: true,
  order: 0,
};

export const USER_DEFINED_PREFIX = 'user_defined_';

export function fieldNameFromLabel(label: string) {
  return `${USER_DEFINED_PREFIX}${label
    .trim()
    .toLowerCase()
    .replace(/[^A-Za-z0-9_-]/g, '_')}`;
}

type ParsedQuerystring = { [key: string]: string };

export function parseQuerystring(qs: string) {
  const result: ParsedQuerystring = {};
  if (qs[0] === '?') {
    qs = qs.substr(1);
  }
  for (const item of qs.split('&')) {
    const parts = item.split('=');
    if (parts.length !== 2) continue;
    result[decodeURIComponent(parts[0]).toLowerCase()] = decodeURIComponent(parts[1]);
  }
  return result;
}

const isTruthy = (str: string) => ['true', 'TRUE', '1', 'yes'].includes(str);

export function fieldValuesFromQuerystring(qs: ParsedQuerystring, fields: Scheduling.PageConfigAdditionalField[]) {
  const values: Partial<BookingFormValues> = {};
  const readonly: string[] = [];

  // Convert all the query string keys to valid field identifiers.
  // "?Phone Number=" becomes "?phone_number="
  const qsSanitized: { [key: string]: string } = {};
  for (const key of Object.keys(qs)) {
    qsSanitized[key.toLowerCase().replace(/[^A-Za-z0-9_-]/g, '_')] = qs[key];
  }

  // For each field defined in the form, come up with a few field identifiers that we
  // should search for. For {name: "Phone #", label: "user_defined_phone_number"},
  // we search for phone__, user_defined_phone_number, phone_number.
  for (const field of [nameField, emailField, ...fields]) {
    const possibleKeys = [
      field.name.toLowerCase(),
      field.name.toLowerCase().replace(USER_DEFINED_PREFIX, ''),
      field.label.toLowerCase().replace(/[^A-Za-z0-9]/g, '_'),
    ];
    for (const key of possibleKeys) {
      if (qsSanitized[key]) {
        if (field.type === 'checkbox') {
          values[field.name] = isTruthy(qsSanitized[key]);
        } else {
          values[field.name] = qsSanitized[key];
        }
        // If you pass "fieldkey_readonly", that field becomes un-editable in the UI
        if (isTruthy(qsSanitized[`${key}_readonly`])) {
          readonly.push(field.name);
        }
        continue;
      }
    }
  }

  // Handle additional emails separately
  if (qs.additional_emails) {
    values.additional_emails = qs.additional_emails.split(',');
    if (isTruthy(qsSanitized['additional_emails_readonly'])) {
      readonly.push('additional_emails');
    }
  }

  // If you pass "prefilled_readonly", all fields that are present in the query string,
  // including name / email become read-only
  if (isTruthy(qs.prefilled_readonly)) {
    readonly.push(...Object.keys(values));
  }

  return { values, readonly };
}

export function clone<T>(jsObject: T): T {
  return JSON.parse(JSON.stringify(jsObject));
}

export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export async function until({ timeSince, greaterThan }: { timeSince: number; greaterThan: number }) {
  return new Promise((resolve) => setTimeout(resolve, Math.max(0, greaterThan - (Date.now() - timeSince))));
}

export function range(start: number, end: number, incrementBy: number = 1): ReadonlyArray<number> {
  const array: number[] = [];
  for (let i = start; i <= end; i += incrementBy) {
    array.push(i);
  }
  return array;
}

export function diff<T extends Object>(lhs: T, rhs: T): (keyof T)[] {
  return Object.keys(lhs).filter((k) => (lhs as any)[k] !== (rhs as any)[k]) as any as (keyof T)[];
}

export function padWithZeros(value: number, maxLength: number = 2) {
  return String(value).padStart(maxLength, '0');
}

export function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export function getCalendarBgGridWithHeight(height: number): string {
  if (process.env.NODE_ENV === 'test') return '';

  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return '';
  }
  ctx.fillStyle = '#d6d6d6';
  ctx.fillRect(0, 0, 1, 1);
  ctx.fillStyle = '#b9b9b9';
  ctx.fillRect(0, height / 2, 1, 1);
  return canvas.toDataURL();
}

export function isValidEmail(value: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
}

export function isValidDomain(checkEmail: string, organizerEmail: string): boolean {
  const email = checkEmail.toLowerCase();
  const organizer = organizerEmail.toLowerCase();
  const emailDomain = email.split('@')[1] ?? '';
  const organizerDomain = organizer.split('@')[1] ?? '';
  return emailDomain === organizerDomain;
}

export async function copyValue(event: React.MouseEvent<any>, value: string) {
  event.preventDefault();

  const el = document.createElement('input');
  document.body.appendChild(el);
  el.value = value;
  el.select();
  document.execCommand('copy');
  el.remove();
}

export function parsePageConfigErrors(message: string, pageConfig: Partial<Scheduling.PageConfig>) {
  let parsedMessage = message;
  parsedMessage = parsedMessage.replace(/^Error:/, '');
  const participantsMatch = message.match(/(event_config|config)(.event)?.participants.(\d+)\.email/);
  if (participantsMatch && pageConfig?.event?.participants && pageConfig.event?.participants?.length > 0) {
    const index = participantsMatch[3] ? parseInt(participantsMatch[3]) : 0;
    if (typeof pageConfig.event.participants[index] !== 'undefined') {
      parsedMessage = parsedMessage.replace(participantsMatch[0], pageConfig.event.participants[index].email);
    }
  }
  return parsedMessage;
}
