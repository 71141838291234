import React from 'react';

import { emailField, isValidEmail, nameField } from '../Utils';
import { SubmitButton } from './SubmitButton';
import { FormWithSpy } from '../components/FormHelpers';
import { AdditionalEmailsField, BookingFormField } from './BookingFormField';
import { Scheduling } from '../types/Scheduling';
import { FormattedMessage, useIntl } from 'react-intl';

export interface BookingFormValues {
  name: string;
  email: string;
  additional_emails: string[];
  [key: string]: any;
}

interface BookingFormProps {
  loading: boolean;
  config: Scheduling.PageConfig;
  readonlyFieldNames: string[];
  onSubmit: (values: BookingFormValues) => void;
  initialValues?: BookingFormValues;
}

export const BookingForm: React.FunctionComponent<BookingFormProps> = ({
  onSubmit,
  loading,
  config,
  readonlyFieldNames,
  initialValues,
}) => {
  const intl = useIntl();
  const [showAdditionalEmailsField, setShowAdditionalEmailsField] = React.useState(
    (initialValues?.additional_emails?.length || 0) > 0,
  );
  const [formValues, setFormValues] = React.useState<BookingFormValues>(
    initialValues || { name: '', email: '', additional_emails: [] },
  );

  const defaultTitle =
    config.booking.confirmation_method === 'automatic'
      ? intl.formatMessage({ id: 'bcb', defaultMessage: 'Book It', description: 'Button - Confirm Booking' })
      : intl.formatMessage({ id: 'rtb', defaultMessage: 'Request to Book', description: 'Button - Request Booking' });

  let submitTitle = config.appearance.submit_text || defaultTitle;

  // Auto-translate if the word "Submit" is explicitly specified in the booking page config.
  // We used this in a lot of example code so many scheduling pages are using it.
  if (submitTitle === 'Submit') {
    submitTitle = intl.formatMessage({
      id: 'Button-Submit',
      defaultMessage: 'Submit',
      description: 'Button - Submit',
    });
  }

  const allFields = [...config.booking.additional_fields];

  allFields.unshift({
    ...emailField,
    label: intl.formatMessage({ id: 'fn-e', defaultMessage: 'Email', description: 'Field Label - Email' }),
  });
  if (!config.booking.name_field_hidden) {
    allFields.unshift({
      ...nameField,
      label: intl.formatMessage({ id: 'fn-n', defaultMessage: 'Name', description: 'Field Label - Name' }),
    });
  }

  // Sort the fields by the optional "order" value, falling back to the intrinsic ordering explicitly
  // to make sure no browsers re-sort 0,0,0,0 into another order.
  allFields.sort((a, b) => (a.order || 0) - (b.order || 0) || allFields.indexOf(a) - allFields.indexOf(b));

  const validate = (values: BookingFormValues) => {
    const errors: { [fieldName: string]: string } = {};

    for (const f of allFields) {
      const value = values[f.name] ? values[f.name].toString() : '';

      if (f.required && value.length === 0) {
        errors[f.name] = intl.formatMessage(
          f.type === 'multi-select list'
            ? {
                defaultMessage: 'Required - Choose at least one',
                description: 'Required - Choose at least one',
                id: 'Required - Choose at least one',
              }
            : {
                defaultMessage: 'Required',
                description: 'Required',
                id: 'Required',
              },
        );
      } else if (f.pattern && value.length) {
        try {
          const regexp = new RegExp(f.pattern);
          if (!regexp.test(value)) {
            errors[f.name] = intl.formatMessage({
              id: 'veip',
              defaultMessage: 'Invalid format',
              description: 'Validation Error - Invalid Pattern',
            });
          }
        } catch (err) {
          console.error(`Unable to validate ${f.name}, RegExp error: ${err}`);
        }
      } else if (f.type === 'email' && value.length && !isValidEmail(value)) {
        errors[f.name] = intl.formatMessage({
          id: 'veie',
          defaultMessage: 'Invalid email format',
          description: 'Validation Error - Invalid Email',
        });
      }
    }

    if (!isValidEmail(values.email)) {
      errors['email'] = intl.formatMessage({
        id: 'veie',
        defaultMessage: 'Invalid email format',
        description: 'Validation Error - Invalid Email',
      });
    }

    const emails = values.additional_emails;
    if (emails.length > 0) {
      const containsInvalid = emails.filter(isValidEmail).length !== emails.length;
      if (containsInvalid) {
        errors['additional_emails'] = intl.formatMessage({
          id: 'veie',
          defaultMessage: 'Invalid email format',
          description: 'Validation Error - Invalid Email',
        });
      }
    }

    return errors;
  };

  // Note these both default to true so undefined == true and we `=== false` on purpose.
  const calendarInvitesDisabled = config.booking.calendar_invite_to_guests === false;

  // The "Additional Emails" field is hidden if you have it explicitly disabled OR have all
  // invites disabled (in which case it does nothing).
  const enableAdditionalEmails = !calendarInvitesDisabled && !config.booking.additional_guests_hidden;

  return (
    <div className="BookingForm">
      <FormWithSpy
        initialValues={formValues}
        onChange={(values) => setFormValues(values)}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit }) => (
          <>
            {allFields.map((f, idx) =>
              f.name === 'email' ? (
                <React.Fragment key="email">
                  <BookingFormField
                    field={emailField}
                    autoFocus={idx === 0}
                    readonly={readonlyFieldNames.includes(f.name)}
                  />
                  {enableAdditionalEmails &&
                    (showAdditionalEmailsField ? (
                      <AdditionalEmailsField
                        name="additional_emails"
                        readonly={readonlyFieldNames.includes('additional_emails')}
                      />
                    ) : (
                      <button
                        className="addGuestsButton"
                        onClick={() => setShowAdditionalEmailsField(!showAdditionalEmailsField)}
                      >
                        <FormattedMessage
                          defaultMessage="+ Additional guests"
                          description="Button-Add-Guests"
                          id="Button-Add-Guests"
                        />
                      </button>
                    ))}
                </React.Fragment>
              ) : (
                <BookingFormField
                  key={f.name}
                  field={f}
                  autoFocus={idx === 0}
                  readonly={readonlyFieldNames.includes(f.name)}
                />
              ),
            )}

            <div>
              <SubmitButton
                style={{ marginBottom: 20 }}
                submitting={loading}
                title={submitTitle}
                onClick={() => handleSubmit()}
              />
            </div>

            <div style={{ marginBottom: 30, textAlign: 'center', fontSize: 12.4 }}>
              <FormattedMessage
                defaultMessage="By using this service you agree to our <a>Privacy Policy</a>."
                description="Privacy-Policy-Note"
                id="ppn"
                values={{
                  a: (chunks: any) => (
                    <a
                      href={
                        (config.appearance && config.appearance.privacy_policy_redirect) ||
                        'https://www.nylas.com/privacy-policy/'
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </div>
          </>
        )}
      </FormWithSpy>
    </div>
  );
};
