import color from 'color';
import * as React from 'react';

/*
Here's how themeing works:

1. We create plain old scss stylesheets for components, etc. These components
  are run through the SCSS pre-processor by webpack. Runtime theme variables
  (our own creation) are underscored in these files so they pass through unscathed:

  ex: `background-color: _tintColor_;`

2. When the embed page loads, we read the desired theme query parameters out
  of the URL. (The javascript that places the embed iframe on the page will add
  &tintColor=#ff0000 to the loaded URL.) We read the stylesheets on the page,
  find/replace the quoted theme variables, and rewrite the stylesheet contents.
  
This might seem a bit basic - there are any number of CSS-in-JS libraries that
would have let us inject the theme into React context and then style our
components with the theme color values. We started down this road, spent an
entire week arguing about styled-components vs emotion vs. emotion that-other-way
and at the end of the day, all we need is this 30 lines of code so here it is.
*/

let theme: { [key: string]: string } = {
  backgroundColor: '#FFF',
  tintColor: '#0068D3',
};

interface ThemeInput {
  backgroundColor?: string;
  tintColor?: string;
}

export function ensurePound(color: string) {
  // Turn FFF into #FFF, but don't change red to #red.
  if (/^([A-F0-9a-f]{3}|[A-F0-9a-f]{6})$/.test(color || '')) {
    return `#${color}`;
  }
  return color;
}

function rebuildThemeWithColors(input: ThemeInput) {
  let bg = color('#FFF');
  let tint = color('#0068D3');

  try {
    if (input.backgroundColor) bg = color(ensurePound(input.backgroundColor));
    if (input.tintColor) tint = color(ensurePound(input.tintColor));
  } catch (err: any) {
    console.warn(`One of your theme colors could not be parsed: ${err.toString()}`);
  }

  theme = {
    backgroundColor: bg.toString(),
    tintColor: tint.toString(),
  };

  const black = color(bg.isLight() ? '#111' : '#FFF');
  theme.black = black.toString();
  theme.sidebarColor = bg.mix(black, 0.04).mix(tint, 0.02).toString();
  theme.sidebarColorHover = color(theme.sidebarColor).mix(black, 0.07).toString();
  theme.grayEEE = black.mix(bg, 0.88).toString();
  theme.grayCCC = black.mix(bg, 0.7).toString();
  theme.gray999 = black.mix(bg, 0.5).toString();
  theme.gray666 = black.mix(bg, 0.3).toString();
  theme.gray5 = black.mix(bg, 0.1).toString();
}

function injectThemeIntoStyle(styleEl: HTMLStyleElement) {
  let css = styleEl.getAttribute('original') || styleEl.textContent || '';
  Object.keys(theme).forEach((key) => {
    css = css.replace(new RegExp(`_${key}_`, 'g'), theme[key]);
  });
  if (styleEl.textContent !== css) {
    if (!styleEl.hasAttribute('original')) {
      styleEl.setAttribute('original', styleEl.textContent || '');
    }
    styleEl.textContent = css;
    return true;
  }
  return false;
}

let observer: MutationObserver;

function injectTheme() {
  if (observer) {
    observer.disconnect();
  }

  Array.from(document.querySelectorAll('style')).forEach(injectThemeIntoStyle);
  Array.from(document.querySelectorAll('link[rel=stylesheet]')).map(async (linkEl) => {
    const href = linkEl.getAttribute('href');
    if (!href || linkEl.getAttribute('styled') === 'no') return;
    const resp = await fetch(href);
    const css = await resp.text();
    const styleEl = document.createElement('style');
    styleEl.textContent = css;
    if (injectThemeIntoStyle(styleEl)) {
      document.head.appendChild(styleEl);
      linkEl.parentNode && linkEl.parentNode.removeChild(linkEl);
    } else {
      linkEl.setAttribute('styled', 'no');
    }
  });

  // Make our theme injection compatible with React's hot reloading in development by
  // monitoring the document head for changes and running the theme injection after changes.
  if (process.env.NODE_ENV === 'development' && 'MutationObserver' in window) {
    const obs = new MutationObserver((records) => {
      const record = records.find((record) => record.addedNodes.length);
      if (!record) return;
      const added = record.addedNodes[0];
      if ((added.textContent || '').indexOf(':focus{outline:0}') === 0) return;
      injectTheme();
    });
    obs.observe(document.head, { subtree: true, characterData: true, childList: true });
  }
}

// Public methods for theme manipulation.

// This is a React Component you put in your application that takes the colors provided via
// props and updates the page CSS.

export const ThemeVariables: React.FunctionComponent<{ theme: ThemeInput }> = ({ theme }) => {
  React.useEffect(() => {
    rebuildThemeWithColors(theme);
    injectTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.backgroundColor, theme.tintColor]);

  return <span />;
};
