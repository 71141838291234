import React, { FunctionComponent } from 'react';

import './Button.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  intent?: 'cancel' | 'primary' | 'default' | 'outlined';
  size?: 'small' | 'normal';
}

const Button: FunctionComponent<ButtonProps> = ({
  intent = 'default',
  size = 'normal',
  className,
  children,
  onClick,
  disabled,
  ...rest
}) => (
  <button
    tabIndex={disabled ? -1 : 0}
    className={`Button ${intent} ${size} ${disabled && 'disabled'} ${className}`}
    onClick={disabled ? undefined : onClick}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
