import { Scheduling } from './types/Scheduling';
import timezones from './timezones.json';
import { getLocalStorage } from './Storage';

// By default, importing moment-timezone gives us the main file defined in it's
// package.json ("./builds/moment-timezone-with-data"). This file is a hilarious
// 931kb, and all we need is this 30kb version for dealing with dates/times in the
// near future. We manually require that pre-built version instead. TypeScript doesn't
// know how this maps to @types/moment-timezone, so we explicitly import the type
// and set it. The type import is removed at compile time so this doesn't impact
// the bundle size.
type Moment = typeof import('moment-timezone');
const moment: Moment = require('moment-timezone/builds/moment-timezone-with-data-10-year-range.min');

type TimezoneOffset = number;

export type TimezoneAndOffset = { name: string; offset: TimezoneOffset };
export const UTC = 'UTC';

let cachedTimezoneOffset: TimezoneAndOffset[] | undefined = undefined;

export function getTimezonesWithOffset(): TimezoneAndOffset[] {
  if (!cachedTimezoneOffset) {
    cachedTimezoneOffset = timezones.map((t) => ({
      name: t,
      offset: moment().tz(t).utcOffset() / 60,
    })) as TimezoneAndOffset[];
  }
  return cachedTimezoneOffset;
}

export function getTimezoneAdjusted<T extends { start: number; end: number }>(items: T[], timezone?: string) {
  const tz = timezone && isValidTimezone(timezone) ? timezone : getCurrentTimezone();
  return items.map((s) => {
    const offset =
      moment(s.start * 1000)
        .tz(tz, true)
        .utcOffset() * 60;
    return {
      ...s,
      start: s.start + offset,
      end: s.end + offset,
    };
  });
}

export function convertOffsetToString(offset: TimezoneOffset): string {
  // This whole mess takes a number and formats it to read like a time
  // where the hour and minute are padded to two digits. (e.g. -05:00)
  const offsetFloat = Number(offset.toFixed(2));
  const decimal = Math.abs(offsetFloat) - Math.floor(Math.abs(offsetFloat));
  const decimalString = decimal === 0 ? '00' : 60 * decimal;
  const wholeNumber = Math.floor(Math.abs(offsetFloat));

  return [
    Math.round(offset) < 0 ? '-' : Math.round(offset) > 0 ? '+' : '',
    wholeNumber <= 9 ? '0' : '',
    `${wholeNumber}:${decimalString}`,
  ].join('');
}

export function getBrowserDefaultTimezone(): string {
  return moment.tz.guess();
}

export function getCurrentTimezone(): string {
  return getLocalStorage().getItem('CurrentTimezone') || getBrowserDefaultTimezone();
}

export function setLocalStorageTimezone(value: string) {
  getLocalStorage().setItem('CurrentTimezone', value);
}

export function getSortedWeekSurroundingDate(date: Date): Date[] {
  let week: Date[] = [date];

  // Count backwards and forwards from the date passed in to get the dates spanning the week.
  // `getDay` is indexed 0-6 (Sunday - Saturday)
  let earlierDay = date.getUTCDay() - 1;
  let offset = 1;
  while (earlierDay >= 0) {
    const d = new Date(date);
    d.setUTCDate(date.getUTCDate() - offset);

    week.unshift(d);
    earlierDay -= 1;
    offset += 1;
  }

  let laterDay = date.getUTCDay() + 1;
  offset = 1;
  while (laterDay < 7) {
    const d = new Date(date);
    d.setUTCDate(date.getUTCDate() + offset);
    week.push(d);
    laterDay += 1;
    offset += 1;
  }

  return week;
}

export function getSlotsInWeekOfDate(date: Date, slots: Scheduling.TimeSlot[]) {
  const startOfWeek = getStartOfWeek(date);
  const endOfWeek = getEndOfWeek(date);
  return slots.filter(
    (s) =>
      (s.start >= startOfWeek.getTime() / 1000 && s.start < endOfWeek.getTime() / 1000) ||
      (s.end >= startOfWeek.getTime() / 1000 && s.end < endOfWeek.getTime() / 1000),
  );
}

export function getMinHourInSlot(slot: Scheduling.TimeSlot): number {
  const startDate = new Date(slot.start * 1000);
  const endDate = new Date(slot.end * 1000);
  // Check for midnight explicit since if a date ends on midnight the Date API will return `hours()` as
  // 0, but we don't want it to wrap to the next day in the calendar.
  if (!isDateMidnight(endDate) && endDate.getUTCHours() < startDate.getUTCHours()) {
    return 0;
  }

  return startDate.getUTCHours();
}

export function getMaxHourInSlot(slot: Scheduling.TimeSlot): { value: number; through: boolean } {
  const startDate = new Date(slot.start * 1000);
  const endDate = new Date(slot.end * 1000);
  // Check for midnight explicit since if a date ends on midnight the Date API will return `hours()` as
  // 0, but we don't want it to wrap to the next day in the calendar.
  if (!isDateMidnight(endDate) && endDate.getUTCHours() < startDate.getUTCHours()) {
    return { value: 23, through: true };
  }

  const through = getSecondsInDay(endDate) % (60 * 60) !== 0;
  return { value: endDate.getUTCHours(), through };
}

export function getSecondsInDay(date: Date): number {
  return date.getUTCSeconds() + date.getUTCMinutes() * 60 + date.getUTCHours() * 60 * 60;
}

export function isDateMidnight(date: Date): boolean {
  return date.getUTCMilliseconds() === 0;
}

export function getStartOfWeek(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCDate(copy.getUTCDate() - copy.getUTCDay());
  copy.setUTCHours(0, 0, 0, 0);
  return copy;
}

export function getEndOfWeek(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCDate(copy.getUTCDate() - copy.getUTCDay() + 6);
  copy.setUTCHours(23, 59, 59, 999);
  return copy;
}

export function decrementDateByOneWeek(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCDate(date.getUTCDate() - 7);
  return copy;
}

export function incrementDateByOneWeek(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCDate(date.getUTCDate() + 7);
  return copy;
}

export function getWeekday(date: Date): string {
  return moment.utc(date).format('dddd');
}

export function getLongDateString(date: Date): string {
  return moment.utc(date).format('LL');
}

export function getHHMMString(date: Date): string {
  return moment.utc(date).format('LT');
}

export type OverlappingRanges = Scheduling.PageConfigOpeningHourRange[][] | null;

/**
 * Converts hh:mm time string formats to
 * moment object.
 */
export function timeStringToMoment(time: string) {
  return moment(`1970-01-01T${time}:00`);
}

/**
 * Finds overlapping ranges across any number of days.
 *
 * If any range {start, end}, for any day or set of days [M...F] overlaps
 * a range in any other day or set of days, those ranges will be flagged
 * as overlapping.
 *
 * Examples:
 *
 *
 * [M, T, W, R, F] {start: 09:00, end: 17:00}
 * [M, T, W, R, F] {start: 09:00, end: 17:00}
 * Simplest case, all days overlapping will be flagged as overlapping.
 *
 * [T, W, R, F] {start: 09:00, end: 17:00}
 * [M] {start: 09:00, end: 17:00}
 * Even though the ranges are the same, the ranges for [M]
 * does not conflict with ranges for [T, W, R, F].
 *
 * [T, W, R, F] {start: 09:00, end: 17:00}
 * [M] {start: 09:00, end: 17:00}
 * [W, R, F] {start: 09:00, end: 17:00}
 * The second range does not conflict with the first set of
 * ranges but the last range [W, R, F] conflicts.
 *
 * @param dateRanges An array of time ranges
 * @returns An array of time range tuples
 */
export function findOverlappingRanges(dateRanges: Scheduling.PageConfigOpeningHourRange[]): OverlappingRanges {
  const sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    const previousTime = timeStringToMoment(previous.start).toDate().getTime();
    const currentTime = timeStringToMoment(current.start).toDate().getTime();

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // if the previous time is later than the current time
    return 1;
  });

  return sortedRanges.reduce((overlappingRanges, currentRange, currentIndex, ranges) => {
    // get the previous range
    if (currentIndex === 0) {
      return overlappingRanges;
    }
    const previous = ranges[currentIndex - 1];

    // check for any overlap
    const previousEnd = timeStringToMoment(previous.end).toDate().getTime();
    const currentStart = timeStringToMoment(currentRange.start).toDate().getTime();
    const overlap = previousEnd >= currentStart;
    // store the result
    if (overlap && overlappingRanges) {
      overlappingRanges.push([previous, currentRange]);
    }

    return overlappingRanges;

    // seed the reduce
  }, [] as OverlappingRanges);
}

/**
 * Recursive function.
 * When detecting multiple accounts, any range having account_id,
 * the ranges are grouped by account_id removing the account_id value from range,
 * then calls it's self again to groupBy days and complete calculation.
 */
export function findOverlappingRangeByDay(dateRanges: Scheduling.PageConfigOpeningHourRange[]): OverlappingRanges {
  if (dateRanges.some((r) => r.account_id != null)) {
    const groupedRanges = groupRangesByAccountId(dateRanges);

    return Object.keys(groupedRanges)
      .map((accountId) => {
        return findOverlappingRangeByDay(groupedRanges[accountId]) || [];
      })
      .flat();
  } else {
    const groupedRanges = groupRangesByDays(dateRanges);

    return Object.keys(groupedRanges)
      .map((day) => {
        return findOverlappingRanges(groupedRanges[day]) || [];
      })
      .flat();
  }
}

function groupRangesByAccountId(
  dateRanges: Scheduling.PageConfigOpeningHourRange[],
): Record<string, Scheduling.PageConfigOpeningHourRange[]> {
  const groupedRanges: Record<string, Scheduling.PageConfigOpeningHourRange[]> = {};

  dateRanges.forEach((range: Scheduling.PageConfigOpeningHourRange) => {
    if (range.account_id) {
      groupedRanges[range.account_id]
        ? groupedRanges[range.account_id].push({
            start: range.start,
            end: range.end,
            days: range.days,
          })
        : (groupedRanges[range.account_id] = [
            {
              start: range.start,
              end: range.end,
              days: range.days,
            },
          ]);
    }
  });

  return groupedRanges;
}

function groupRangesByDays(
  dateRanges: Scheduling.PageConfigOpeningHourRange[],
): Record<string, Scheduling.PageConfigOpeningHourRange[]> {
  const groupedRanges: Record<string, Scheduling.PageConfigOpeningHourRange[]> = {};

  dateRanges.forEach((range: Scheduling.PageConfigOpeningHourRange) => {
    range.days.forEach((day: string) => {
      groupedRanges[day] ? groupedRanges[day].push(range) : (groupedRanges[day] = [range]);
    });
  });

  return groupedRanges;
}

export function isValidTimezone(timezone: string): boolean {
  return timezones.includes(timezone);
}
