import React from 'react';

// Load localizations and activate the locale for the user if it's available.
// Note: in the future we could conditionally import these but currently they are small
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import 'moment/locale/sv';
import 'moment/locale/ja';
import 'moment/locale/nl';
import fr from './compiled-lang/fr.json';
import en from './compiled-lang/en.json';
import es from './compiled-lang/es.json';
import de from './compiled-lang/de.json';
import zhCn from './compiled-lang/zh-cn.json';
import sv from './compiled-lang/sv.json';
import ja from './compiled-lang/ja.json';
import nl from './compiled-lang/nl.json';
import { IntlProvider } from 'react-intl';

type SupportedLocale = { key: string; label: string };

const LANGS: any = { fr, en, es, de, sv, 'zh-cn': zhCn, ja, nl };

const SUPPORTED: SupportedLocale[] = [
  { key: 'en', label: 'English' },
  { key: 'fr', label: 'Français' },
  { key: 'es', label: 'Español' },
  { key: 'de', label: 'Deutsche' },
  { key: 'sv', label: 'Svenska' },
  { key: 'zh-cn', label: '中文' },
  { key: 'ja', label: '日本語' },
  { key: 'nl', label: 'Nederlands' },
];

export function isValidLocale(locale?: string): boolean {
  return locale ? locale in LANGS : false;
}

// We want to display "Thursday, March 10th" but without the rest of the
// date string. These are manually sliced from the `LLLL` formats in Moment here:
// https://github.com/moment/moment/blob/develop/locale/de.js
//
export const LONG_DAY_FORMATS: { [locale: string]: string } = {
  en: 'dddd, MMMM Do',
  fr: 'dddd D MMMM',
  de: 'dddd, D. MMMM',
  es: 'dddd, D [de] MMMM',
  sv: 'dddd, Do MMMM',
};

export const LocaleContext = React.createContext<{
  locales: SupportedLocale[];
  locale12HourTime: boolean;
  locale: string;
  setLocale: (locale: string) => void;
}>(new Error('No LocaleContext') as any);

/**
 * Small wrapper around the react-intl library that resolves the locale we should use given the
 * available localizations and provides it to both moment and react-intl.
 */
export const AutoIntlProvider: React.FunctionComponent<{
  localeOverride?: string;
  locale12HourTimeOverride?: boolean;
}> = ({ children, localeOverride, locale12HourTimeOverride }) => {
  const [locale, setLocale] = React.useState(() => {
    let locale = (localeOverride || navigator.language || 'en-US').toLowerCase();
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    const short = locale.split('-')[0];
    return locale in LANGS ? locale : short in LANGS ? short : 'en';
  });

  if (moment.locale() !== locale) {
    moment.locale(locale);
  }

  const locale12HourTime = locale12HourTimeOverride !== undefined ? locale12HourTimeOverride : locale === 'en';

  return (
    <LocaleContext.Provider value={{ locales: SUPPORTED, locale, setLocale, locale12HourTime }}>
      <IntlProvider messages={LANGS[locale]} locale={locale} defaultLocale="en">
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
