import React from 'react';
import * as Icons from './SVGIcons';
import { isMobile, isBrowser } from 'react-device-detect';

import './Tooltip.scss';

export const Tooltip: React.FunctionComponent<{ text?: string }> = ({ text }) => {
  const [showing, setShowing] = React.useState<boolean>(false);
  const [orientation, setOrientation] = React.useState<'left' | 'right'>('left');
  const el = React.createRef<HTMLDivElement>();

  const windowWidth = window.innerWidth;

  React.useEffect(() => {
    if (!el.current) return;
    const xPos = el.current.getBoundingClientRect().left;

    const tooltipVW = windowWidth < 360 ? 0.3 : 0.4;
    const tooltipMaxWidth = windowWidth < 360 ? 200 : 160;
    const buffer = 30;

    if (xPos + Math.min(windowWidth * tooltipVW, tooltipMaxWidth) + buffer > windowWidth) {
      if (orientation !== 'right') setOrientation('right');
    } else {
      if (orientation !== 'left') setOrientation('left');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  if (!text) return <span />;
  return (
    <div
      ref={el}
      className={`Tooltip ${orientation}`}
      onMouseOver={() => isBrowser && setShowing(true)}
      onMouseOut={() => isBrowser && setShowing(false)}
      onClick={() => isMobile && setShowing(!showing)}
    >
      <Icons.TooltipInfo
        tabIndex={0}
        onFocus={() => isBrowser && setShowing(true)}
        onBlur={() => isBrowser && setShowing(false)}
      />

      {showing && <div>{text}</div>}
    </div>
  );
};
