import React, { Component } from 'react';
import { Field } from 'react-final-form';

import './RadioGroup.scss';

interface RadioGroupProps<T> {
  inputName: string;
  options: { label: string; value: T; description: string | React.ReactNode }[];
  size?: 'normal' | 'small';
}

class RadioGroup<T> extends Component<RadioGroupProps<T>> {
  render() {
    return (
      <div className="RadioGroup">
        {this.props.options.map(element =>
          element.value ? (
            <div key={`${element.value}`} className={`RadioButton ${this.props.size}`}>
              <Field
                id={`${element.value}`}
                name={this.props.inputName}
                component="input"
                type="radio"
                value={element.value}
              />{' '}
              <label htmlFor={`${element.value}`}>
                <div className="title">{element.label}</div>
                <div className="description">{element.description}</div>
              </label>
            </div>
          ) : (
            {}
          ),
        )}
      </div>
    );
  }
}

export default RadioGroup;
