import React from 'react';
import { Field } from '../components/FormHelpers';
import { Scheduling } from '../types/Scheduling';
import TokenizedInput from '../components/TokenizedInput';
import { isValidEmail } from '../Utils';
import { FormattedMessage } from 'react-intl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

type AdditionalField = Scheduling.PageConfigAdditionalField;

interface BookingFormFieldProps {
  field: AdditionalField;
  autoFocus?: boolean;
  readonly?: boolean;
}

const MULTISELECT_VALUE_SEPARATOR = '\n';

const DATE_INPUT_SUPPORTED = (function () {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);
  const supported = input.value !== value;
  input.remove();
  return supported;
})();

export const BookingFormField: React.FunctionComponent<BookingFormFieldProps> = ({ field, autoFocus, readonly }) => {
  const { name, type, label, required, pattern, dropdown_options, multi_select_options } = field;

  switch (type) {
    case 'dropdown':
      return (
        <Field name={name}>
          {({ input, meta }) => (
            <div>
              <label className={`${meta.error && meta.touched && 'invalid'}`} htmlFor={`input-${name}`}>
                {label}
                {meta.error && meta.touched && <span>{` (${meta.error})`}</span>}
                {required ? ' *' : ''}
              </label>
              <select
                {...input}
                autoFocus={autoFocus}
                id={`input-${name}`}
                style={{ marginBottom: 10, marginTop: 5 }}
                required={required}
                disabled={readonly}
                className={`${meta.error && meta.touched && 'invalid'}`}
              >
                <option value="" disabled>
                  Choose an option...
                </option>
                {(dropdown_options || []).map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Field>
      );

    case 'multi-line text':
      return (
        <Field
          name={name}
          id={name}
          render={({ input, meta }) => (
            <div>
              <label className={`${meta.error && meta.touched && 'invalid'}`} htmlFor={`input-${name}`}>
                {label}
                {meta.error && meta.touched && <span>{` (${meta.error})`}</span>}
                {required ? ' *' : ''}
              </label>
              <textarea
                {...input}
                rows={3}
                id={`input-${name}`}
                required={required}
                autoFocus={autoFocus}
                disabled={readonly}
                readOnly={readonly}
                style={{ width: '100%', font: '400 1.1rem Arial', lineHeight: '1.23' }}
                className={`${meta.error && meta.touched && 'invalid'}`}
              />
            </div>
          )}
        />
      );
    case 'checkbox': // Don't need validation for checkboxes
      return (
        <Field
          name={name}
          id={name}
          render={({ input, meta }) => (
            <label className={`checkbox-container ${meta.error && meta.touched && 'invalid'}`} htmlFor={name}>
              <input
                id={name}
                type="checkbox"
                checked={input.value}
                onChange={input.onChange}
                disabled={readonly}
                readOnly={readonly}
              />
              {` ${label}${meta.error && meta.touched ? ` (${meta.error})` : ''}${required ? ' *' : ''}`}
            </label>
          )}
        />
      );
    case 'multi-select list':
      return (
        <Field
          name={name}
          id={name}
          render={({ input, meta }) => {
            const values: string[] = input.value.split(MULTISELECT_VALUE_SEPARATOR);

            return (
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                <label className={`${meta.error && meta.touched && 'invalid'}`}>
                  {label}
                  {meta.error && meta.touched && <span>{` (${meta.error})`}</span>}
                  {required ? ' *' : ''}
                </label>
                {(multi_select_options || []).map((o, idx) => (
                  <label
                    key={o}
                    className={`checkbox-container ${meta.error && meta.touched && 'invalid'}`}
                    htmlFor={`${name}-${idx}`}
                  >
                    <input
                      id={`${name}-${idx}`}
                      type="checkbox"
                      checked={values.includes(o)}
                      onChange={() =>
                        input.onChange({
                          target: {
                            value: (values.includes(o) ? values.filter((i) => i !== o) : [...values, o]).join(
                              MULTISELECT_VALUE_SEPARATOR,
                            ),
                          },
                        } as any)
                      }
                    />
                    {` ${o}`}
                  </label>
                ))}
              </div>
            );
          }}
        />
      );
    default:
      const fieldType = inputTypeForFieldType(type);
      const fallbackDateInput = fieldType === 'date' && !DATE_INPUT_SUPPORTED;
      return (
        <Field name={name} type={inputTypeForFieldType(type)}>
          {({ input, meta }) => (
            <div>
              <label className={`${meta.error && meta.touched && 'invalid'}`} htmlFor={`input-${name}`}>
                {label}
                {meta.error && meta.touched && <span>{` (${meta.error})`}</span>}
                {required ? ' *' : ''}
              </label>
              <div>
                {fallbackDateInput ? (
                  <DatePicker
                    name={input.name}
                    id={`input-${name}`}
                    required={required}
                    disabled={readonly}
                    readOnly={readonly}
                    placeholderText="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    className={`${meta.error && meta.touched && 'invalid'}`}
                    selected={input.value ? moment(input.value, 'YYYY-MM-DD').toDate() : null}
                    onBlur={input.onBlur as any}
                    onFocus={input.onFocus as any}
                    onChange={(date) =>
                      input.onChange({
                        target: { value: date instanceof Date ? moment(date).format('YYYY-MM-DD') : '' },
                      } as any)
                    }
                  />
                ) : (
                  <input
                    {...input}
                    type={inputTypeForFieldType(type)}
                    id={`input-${name}`}
                    required={required}
                    pattern={pattern}
                    autoFocus={autoFocus}
                    disabled={readonly}
                    readOnly={readonly}
                    className={`${meta.error && meta.touched && 'invalid'}`}
                  />
                )}
              </div>
            </div>
          )}
        </Field>
      );
  }
};

function inputTypeForFieldType(type: 'number' | 'text' | 'email' | 'phone' | 'date') {
  // It sucks this mapping is necessary, but we accidentally used `phone` instead of `tel`
  // and it does not seem worth a migration + docs update.
  return { number: 'number', text: 'text', email: 'email', phone: 'tel', date: 'date' }[type];
}

export const AdditionalEmailsField = ({ name, readonly }: { name: string; readonly: boolean }) => (
  <div style={{ marginBottom: 10 }}>
    <label htmlFor={'additional-guests'}>
      <FormattedMessage
        defaultMessage="Additional guests"
        description="Field-Label-Additional-guests"
        id="Field-Label-Additional-guests"
      />
    </label>
    <Field
      name={name}
      id={'additional-guests'}
      render={({ input }) => (
        <TokenizedInput
          items={input.value || []}
          tokenValidator={isValidEmail}
          readonly={readonly}
          onChange={(items) => {
            if (readonly) return;
            input.onChange({ target: { value: items } } as any);
          }}
        />
      )}
    />
  </div>
);
