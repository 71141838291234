import React from 'react';
import { getBrowserDefaultTimezone, getCurrentTimezone, setLocalStorageTimezone } from '../DateUtils';

// If the user is loading a scheduling page that does NOT allow for timezone customization
// after viewing and editing their timezone on another scheduling page, reset the timezone
// to their default so it's not locked in a weird timezone.
//
const TimezoneDefaultEnforcer: React.FunctionComponent = () => {
  React.useEffect(() => {
    if (getCurrentTimezone() !== getBrowserDefaultTimezone()) {
      setLocalStorageTimezone(getBrowserDefaultTimezone());
    }
  }, []);
  return <span />;
};

export default TimezoneDefaultEnforcer;
