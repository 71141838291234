import React from 'react';

import * as Icons from '../components/SVGIcons';
import Button, { ButtonProps } from '../components/Button';

interface SubmitButtonProps extends ButtonProps {
  submitting: boolean;
  title: string;
}

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = ({ submitting, title, ...rest }) => (
  <Button {...rest} disabled={submitting}>
    {submitting ? (
      <div
        style={{
          color: '#666',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icons.Spinner width={25} height={25} color="white" />
      </div>
    ) : (
      title
    )}
  </Button>
);
