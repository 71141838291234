import React from 'react';
import { Scheduling } from '../types/Scheduling';
import { Sheet, SheetBody } from '../components/Sheet';
import { Field, TypedSelectField, FormWithSpy } from '../components/FormHelpers';
import './CalendarAdvancedSheet.scss';
import { clone } from '../Utils';
import { FormattedMessage, useIntl } from 'react-intl';

type AccountCalendarIDs = Scheduling.PageConfigAccountCalendarIDs;

interface CalendarAdvancedSheetProps {
  account: Scheduling.PageConfigCalendars;
  value: AccountCalendarIDs;
  onChange: (cal_ids: AccountCalendarIDs) => void;
  onDismiss: () => void;
}

export const CalendarAdvancedSheet: React.FunctionComponent<CalendarAdvancedSheetProps> = ({
  account,
  value,
  onChange,
  onDismiss,
}) => {
  const intl = useIntl();
  const handleFormChange = (values: AccountCalendarIDs) => {
    // Either report the new values if it's non empty, or report the initial values
    // as a fallback (we don't want to allow no calendars to be selected).
    onChange(values.availability.length > 0 ? values : clone(value));
  };

  return (
    <Sheet
      onDismiss={onDismiss}
      title={intl.formatMessage({
        id: 'settings-csat',
        defaultMessage: 'Calendar Settings',
        description: 'Settings - Calendar Settings Advanced Title',
      })}
    >
      <SheetBody>
        <div className="CalendarAdvancedSheet" style={{ display: 'flex', flexDirection: 'column' }}>
          <FormWithSpy onChange={handleFormChange} onSubmit={() => {}} initialValues={value}>
            {() => (
              <>
                <h2 style={{ marginTop: 0, marginBottom: 5 }}>
                  <FormattedMessage
                    defaultMessage="Book {name}'s meetings on:"
                    description="Settings - Booking - Calendar Prompt"
                    id="Settings-bcp"
                    values={{
                      name: account.name || account.email,
                    }}
                  />
                </h2>
                <div style={{ flex: 1, flexDirection: 'row' }}>
                  <TypedSelectField
                    name="booking"
                    options={account.calendars.filter((c) => !c.read_only).map((c) => ({ label: c.name, value: c.id }))}
                  />
                </div>

                <h2 style={{ marginTop: 30 }}>
                  <FormattedMessage
                    defaultMessage="Only schedule meetings when I'm available on:"
                    description="Settings - Booking - Scheduling Prompt"
                    id="Settings-bsp"
                  />
                </h2>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {account.calendars.map((c) => (
                    <label htmlFor={`${c.id}-availability`} className="checkbox-container" key={c.id}>
                      <Field
                        id={`${c.id}-availability`}
                        name="availability"
                        component="input"
                        type="checkbox"
                        value={c.id}
                      />
                      {c.name}
                    </label>
                  ))}
                </div>
              </>
            )}
          </FormWithSpy>
        </div>
      </SheetBody>
    </Sheet>
  );
};
