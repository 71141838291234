import React from 'react';
import { useIntl } from 'react-intl';
import { FullScreenError } from './app-booking/FullScreenError';
import * as Booking from './app-booking/MainView';
import { CustomAlertProvider } from './app-embed/CustomAlertProvider';
import * as Embed from './app-embed/MainView';
import { RequestContext } from './components/NetworkRequest';
import { AutoIntlProvider } from './LocaleSupport';
import { getNylasWindowContext } from './NylasWindowContext';
import { ThemeVariables } from './ThemeSupport';
import { EmbedConfig } from './Utils';


export let App: React.FunctionComponent<{}>;

function tokenFromEmbedConfig(config: EmbedConfig) {
  return 'pageEditToken' in config.auth ? config.auth.pageEditToken : config.auth.accessToken;
}

const { apiBaseURL, appType, pageSlug } = getNylasWindowContext();

if (appType === 'embed') {
  App = () => (
    <ParentFrameConfigProvider>
      {(config) => (
        <AutoIntlProvider localeOverride={config.locale} locale12HourTimeOverride={config.locale12HourTime}>
          <RequestContext.Provider value={{ apiBaseURL, apiAuthToken: tokenFromEmbedConfig(config) }}>
            <>
              <ThemeVariables theme={config.style} />
              <Embed.MainView config={config} />
              <CustomAlertProvider />
            </>
          </RequestContext.Provider>
        </AutoIntlProvider>
      )}
    </ParentFrameConfigProvider>
  );
} else {
  App = () => (
    <RequestContext.Provider value={{ apiBaseURL, apiAuthToken: '' }}>
      <AutoIntlProvider>{pageSlug ? <Booking.MainView slug={pageSlug} /> : <NotFoundError />}</AutoIntlProvider>
    </RequestContext.Provider>
  );
}

const NotFoundError: React.FunctionComponent = () => {
  const intl = useIntl();
  return (
    <FullScreenError
      message={intl.formatMessage({
        id: 'error-invalid-slug',
        defaultMessage: "Nothing to see here! To book meetings with our team, visit a scheduling page using it's URL.",
        description: 'Error - 404 Invalid Scheduling Page Slug',
      })}
    />
  );
};

/*
This is a React component that sends a postMessage to the parent frame and listens for
a response containing the embed's configuration. This is how we transfer options from
the schedule-editor script into the embed iframe.
*/
interface ParentFrameConfigProviderProps {
  children: (config: EmbedConfig) => React.ReactNode;
}

const DEFAULT_CONFIG: EmbedConfig = {
  pageDomain: 'https://schedule.nylas.com',
  auth: { pageEditToken: '' },
  style: {},
  behavior: {
    disableEditing: [],
  },
  defaults: {},
};

const ParentFrameConfigProvider = (props: ParentFrameConfigProviderProps) => {
  const [config, setConfig] = React.useState<EmbedConfig | null>(null);

  React.useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.data && e.data.type === 'nylas:config-response') {
        const inflated = Object.assign({}, DEFAULT_CONFIG, e.data.config);

        // Fill in data lost because we are not deep merging above
        inflated.behavior.disableEditing = inflated.behavior.disableEditing || [];

        // Remove + replace deprecated embed config
        if (inflated.behavior.disableSlugChanges) {
          inflated.behavior.disableEditing.push('slug');
          delete inflated.behavior.disableSlugChanges;
        }
        setConfig(inflated);
      }
    };
    window.parent.postMessage({ type: 'nylas:config-request' }, '*');
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  return <>{config && props.children(config)}</>;
};
