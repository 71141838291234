import React from 'react';
import * as Icons from '../components/SVGIcons';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const ModalLoadingState = () => (
  <div className="state-wrap">
    <div style={{ color: '#666', margin: 'auto' }}>
      <Icons.Spinner width={40} height={40} />
    </div>
  </div>
);

export const ModalDoneState = (props: { title: string; children: React.ReactNode }) => (
  <div className="state-wrap">
    <div>
      <Icons.BookingConfirmationCheckmark className="checkmark" checkmarkClassName="checkmark-check" />
    </div>
    <h3>{props.title}</h3>
    {props.children}
  </div>
);

export const ModalErrorState = (props: { onClick: () => void; buttonType: 'back' | 'try-again'; message: string }) => {
  const message: { message?: string; type?: string } = JSON.parse(props.message);

  return (
    <div className="state-wrap">
      <div className="big-icon">
        <Icons.X />
      </div>
      <h3>
        <FormattedMessage
          description="Booking Error - Generic"
          id="beg"
          defaultMessage="Sorry, we couldn't create your booking."
        />
      </h3>
      {props.message ? (
        message.type && message.message ? (
          <>
            <div style={{ padding: '0 30px' }}>{`message: ${message.message}`}</div>
            <div style={{ padding: '0 30px' }}>{`type: ${message.type}`}</div>
          </>
        ) : (
          <div style={{ padding: '0 30px' }}>{props.message}</div>
        )
      ) : (
        <div style={{ padding: '0 30px' }}>
          <FormattedMessage
            defaultMessage="If you are the page owner, please contact your administrator for help"
            description="Support request"
            id="Request-Support"
          />
        </div>
      )}
      <Button onClick={props.onClick}>
        {props.buttonType === 'back' ? (
          <FormattedMessage defaultMessage="Go Back" description="Go Back" id="Go-Back" />
        ) : (
          <FormattedMessage defaultMessage="Try Again" description="Try Again" id="Try-Again" />
        )}
      </Button>
    </div>
  );
};

export const ModalNotFoundState = (props: { slug: string }) => (
  <div className="state-wrap">
    <div className="big-icon">
      <Icons.X />
    </div>
    <h3>
      <FormattedMessage
        description="Booking Error - Cancelled"
        id="bec"
        defaultMessage="This booking has been cancelled."
      />
    </h3>
    <div>
      <FormattedMessage
        description="Booking Error - Cancelled Note"
        id="becn"
        defaultMessage="Click below to schedule another meeting."
      />
    </div>
    <Link to={`/${props.slug}`}>
      <Button>
        <FormattedMessage description="Booking Error - Show Times Button" id="bestb" defaultMessage="Show Times" />
      </Button>
    </Link>
  </div>
);
