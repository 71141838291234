import React, { Component } from 'react';
import { clone, diff, assertUnreachable, MAX_PARTICIPANTS, MIN_PARTICIPANTS } from '../Utils';
import { TabComponentProps } from './PageDetailView';
import { FormWithSpy, Field } from '../components/FormHelpers';
import { Tooltip } from '../components/Tooltip';
import { TimeField } from './TimeField';
import './EventInfoView.scss';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { ParticipantsField } from './ParticipantsField';
import { Scheduling } from '../types/Scheduling';

interface FormValues {
  name: string;
  template_title: string;
  location: string;
  duration: number;
  minBookingNotice: number;
  minCancellationNotice: number;
  futureLimit: number;
  bufferTime: number;
  cancellationPolicy?: string;
  participants?: Scheduling.Participant[];
  attendee_capacity?: number;
}

// minBookingNoticeUnit: config.booking.min_booking_notice <= 23 * 1440 ? 'Hours' : 'Days',

class EventInfoView extends Component<TabComponentProps & { intl: IntlShape }> {
  isAttendeeCapacityInvalid = false;

  getFormValues(): FormValues {
    const { event, booking } = this.props.page.config;

    return {
      name: event.title,
      template_title: event.template_title ?? '',
      location: event.location,
      duration: event.duration,
      minBookingNotice: booking.min_booking_notice,
      minCancellationNotice: booking.min_cancellation_notice,
      futureLimit: booking.available_days_in_future,
      bufferTime: booking.min_buffer,
      cancellationPolicy: booking.cancellation_policy,
      participants: event.participants,
      attendee_capacity: event.capacity,
    };
  }

  updateParticipants = async (participants: Scheduling.Participant[]) => {
    this.handleFormChange({ ...this.getFormValues(), participants });
  };

  handleFormChange = (values: FormValues) => {
    const lastValues = this.getFormValues();
    const difference = diff(values, lastValues);
    if (difference.length === 0) {
      return;
    }

    // We keep track of the new props and state we're going to be persisting and then batch it all at the end.
    // - Props: Cloned so we can modify it based on the form values that have changed and then pass it to the change handler.
    // - State: Track changes and merge with existing state below to keep track of changes to time unit values.
    const updatedConfig = clone(this.props.page.config);

    difference.forEach((key) => {
      const activeValue = (values as any)[key];

      switch (key) {
        case 'name':
          updatedConfig.event.title = activeValue;
          break;
        case 'template_title':
          updatedConfig.event.template_title = activeValue;
          break;
        case 'location':
          updatedConfig.event.location = activeValue;
          break;
        case 'duration':
          updatedConfig.event.duration = activeValue;
          break;
        case 'minBookingNotice':
          updatedConfig.booking.min_booking_notice = Number(activeValue);
          break;
        case 'minCancellationNotice':
          updatedConfig.booking.min_cancellation_notice = Number(activeValue);
          break;
        case 'futureLimit':
          updatedConfig.booking.available_days_in_future = Number(activeValue);
          break;
        case 'bufferTime':
          updatedConfig.booking.min_buffer = Number(activeValue);
          break;
        case 'cancellationPolicy':
          updatedConfig.booking.cancellation_policy = activeValue.length === 0 ? undefined : activeValue;
          break;
        case 'participants':
          updatedConfig.booking.scheduling_method =
            activeValue.length > 0 ? 'collective' : 'round-robin-maximize-availability';
          updatedConfig.event.participants = activeValue;
          break;
        case 'attendee_capacity':
          updatedConfig.event.capacity = activeValue ? Number(activeValue) : undefined;
          break;
        default:
          assertUnreachable(key);
      }
    });

    if (this.props.page.config.features?.group_meetings) {
      this.isAttendeeCapacityInvalid = !!(
        updatedConfig.event.capacity &&
        (updatedConfig.event.capacity > MAX_PARTICIPANTS || updatedConfig.event.capacity < MIN_PARTICIPANTS)
      );

      updatedConfig.hasFormErrors = this.isAttendeeCapacityInvalid;
    }

    // Set the `values` key to the new form values based on our updated config (which is a copy of the
    // props passed in) and our updated state (joined this.state and updated state).
    this.props.onChange({ ...this.props.page, config: updatedConfig });
  };

  render() {
    const { disableEditing } = this.props.config.behavior;

    const access_token =
      this.props.page.access_token_infos && this.props.page.access_token_infos.length > 0
        ? this.props.page.access_token_infos[0]
        : undefined;
    const organizer = {
      account_email: access_token?.account_email ?? '',
      account_name: access_token?.account_name ?? '',
    };

    return (
      <div className="EventInfoView">
        <FormWithSpy onChange={this.handleFormChange} onSubmit={() => {}} initialValues={this.getFormValues()}>
          {() => (
            <>
              <div className="textInputContainer">
                <Field
                  name="name"
                  label={this.props.intl.formatMessage({
                    id: 'Settings-eienl',
                    description: 'Settings - Event Info - Event name label',
                    defaultMessage: 'Event Name',
                  })}
                  placeholder={this.props.intl.formatMessage({
                    id: 'Settings-eienp',
                    description: 'Settings - Event Info - Event name placeholder',
                    defaultMessage: 'Event Name',
                  })}
                  component="input"
                  type="text"
                />
              </div>
              <div className="textInputContainer">
                <Field
                  name="template_title"
                  label={this.props.intl.formatMessage({
                    id: 'Settings-eiettpl',
                    description: 'Settings - Event Info - Event Template Title label',
                    defaultMessage: 'Event Template Title',
                  })}
                  placeholder={this.props.intl.formatMessage({
                    id: 'Settings-eiettpl',
                    description: 'Settings - Event Info - Event Template Title placeholder',
                    defaultMessage: 'Event Template Title',
                  })}
                  component="input"
                  type="text"
                />
              </div>
              <div className="textInputContainer">
                <Field
                  name="location"
                  label={this.props.intl.formatMessage({
                    id: 'Settings-eiell',
                    description: 'Settings - Event Info - Event location label',
                    defaultMessage: 'Location',
                  })}
                  placeholder={this.props.intl.formatMessage({
                    id: 'Settings-eielp',
                    description: 'Settings - Event Info - Event location placeholder',
                    defaultMessage: 'Event Location',
                  })}
                  component="input"
                  type="text"
                />
              </div>
              <div className="timeFieldSection">
                <div style={{ flex: 1 }}>
                  <TimeField
                    fieldKey="duration"
                    label={this.props.intl.formatMessage({
                      id: 'Settings-eiedl',
                      description: 'Settings - Event Info - Event duration label',
                      defaultMessage: 'Duration',
                    })}
                    tooltip={this.props.intl.formatMessage({
                      id: 'Settings-eiedt',
                      description: 'Settings - Event Info - Event duration tooltip',
                      defaultMessage: 'The length of the calendar events created by the page.',
                    })}
                  />
                </div>
                <div style={{ flex: 1.1 }}>
                  <TimeField
                    fieldKey="minBookingNotice"
                    label={this.props.intl.formatMessage({
                      id: 'Settings-eibnl',
                      description: 'Settings - Event Info - Booking notice label',
                      defaultMessage: 'Min. Booking Notice',
                    })}
                    tooltip={this.props.intl.formatMessage({
                      id: 'Settings-eibnt',
                      description: 'Settings - Event Info - Booking notice tooltip',
                      defaultMessage: 'How far an event must be planned into the future.',
                    })}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimeField
                    fieldKey="minCancellationNotice"
                    label={this.props.intl.formatMessage({
                      id: 'Settings-eicnl',
                      description: 'Settings - Event Info - Cancel notice label',
                      defaultMessage: 'Min. Cancel Notice',
                    })}
                    tooltip={this.props.intl.formatMessage({
                      id: 'Settings-eicnt',
                      description: 'Settings - Event Info - Cancel notice tooltip',
                      defaultMessage:
                        'The minimum time before an event that the guest is allowed to cancel via the booking page.',
                    })}
                  />
                </div>
              </div>
              <div className="timeFieldSection">
                {!disableEditing.includes('available_days_in_future') && (
                  <div style={{ flex: 1 }}>
                    <TimeField
                      fieldKey="futureLimit"
                      label={this.props.intl.formatMessage({
                        id: 'Settings-eifll',
                        description: 'Settings - Event Info - Future limit label',
                        defaultMessage: 'Future Limit',
                      })}
                      tooltip={this.props.intl.formatMessage({
                        id: 'Settings-eiflt',
                        description: 'Settings - Event Info - Future limit tooltip',
                        defaultMessage: 'The number of days into the future events should be allowed.',
                      })}
                    />
                  </div>
                )}
                <div style={{ flex: 1 }}>
                  <TimeField
                    fieldKey="bufferTime"
                    label={this.props.intl.formatMessage({
                      id: 'Settings-eibtl',
                      description: 'Settings - Event Info - Buffer time label',
                      defaultMessage: 'Buffer Time',
                    })}
                    tooltip={this.props.intl.formatMessage({
                      id: 'Settings-eibttp',
                      description: 'Settings - Event Info - Buffer time tooltip',
                      defaultMessage:
                        'The amount of time that should be kept between events on your calendar so you have a break between meetings.',
                    })}
                  />
                </div>
                <div style={{ flex: 1 }} />
              </div>
              <div className="cancellationContainer">
                <label htmlFor="cancellationPolicy">
                  <FormattedMessage
                    defaultMessage="Cancellation Policy"
                    description="Settings - Event Info - Cancellation Policy"
                    id="Settings-eicp"
                  />
                  <Tooltip
                    text={this.props.intl.formatMessage({
                      id: 'Settings-eicpt',
                      description: 'Settings - Event Info - Cancellation Policy tooltip',
                      defaultMessage:
                        'This text appears when visitors cancel a booking and are asked to provide a quick reason for cancellation.',
                    })}
                  />
                </label>
                <Field
                  id="cancellationPolicy"
                  name="cancellationPolicy"
                  label={this.props.intl.formatMessage({
                    id: 'Settings-eicpl',
                    description: 'Settings - Event Info - Cancellation Policy label',
                    defaultMessage: 'Cancellation Policy',
                  })}
                  placeholder=""
                  component="textarea"
                  type="text"
                />
              </div>
              {this.props.page.config.features?.group_meetings && (
                <div className="attendeeCapacityContainer">
                  <label htmlFor="attendeeCapacity">
                    <FormattedMessage
                      defaultMessage="What is the maximum number of attendees for each event?"
                      description="Settings - Event Info - Event attendee capacity prompt"
                      id="Settings-eacp"
                    />
                  </label>
                  <Field
                    name="attendee_capacity"
                    label="Capacity"
                    placeholder={this.props.intl.formatMessage({
                      id: 'Settings-attca',
                      description: 'Settings - Event Info - Event attendee capacity placeholder',
                      defaultMessage: 'Capacity',
                    })}
                    min={MIN_PARTICIPANTS}
                    max={MAX_PARTICIPANTS}
                    component="input"
                    type="number"
                  />
                  {this.isAttendeeCapacityInvalid && (
                    <div className="formErrors">
                      <FormattedMessage
                        defaultMessage="An event must have between {MIN_PARTICIPANTS} and {MAX_PARTICIPANTS} attendees."
                        description="Settings - Event Info - Invalid attendee count"
                        id="Settings-iac"
                        values={{
                          MIN_PARTICIPANTS,
                          MAX_PARTICIPANTS,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {this.props.page.config.features?.collective_meetings && (
                <div className="participantFieldSection">
                  <label htmlFor="participants">
                    <FormattedMessage
                      defaultMessage="Who else will be joining you?"
                      description="Settings - Event Info - Event participants"
                      id="Settings-eiepp"
                    />
                    <ParticipantsField
                      organizer={organizer}
                      participants={this.getFormValues().participants}
                      updateParticipants={this.updateParticipants}
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </FormWithSpy>
      </div>
    );
  }
}

export default injectIntl(EventInfoView);
