import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Scheduling } from '../types/Scheduling';

import { TabComponentProps } from './PageDetailView';
import { clone, padWithZeros, DefaultOpeningHourRange } from '../Utils';
import TimePicker from 'rc-time-picker';
import TimezoneSelectView from '../components/TimezoneSelectView';
import Button from '../components/Button';
import * as Icons from '../components/SVGIcons';
import SVGButton from '../components/SVGButton';
import BoxSelectView from '../components/BoxSelectView';
import { Tooltip } from '../components/Tooltip';
import { RequestForAccountsAndCalendars } from './RequestForAccountsAndCalendars';

import './OpeningHoursView.scss';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { LocaleContext } from '../LocaleSupport';
import AlertBox from '../components/AlertBox';
import { findOverlappingRangeByDay, OverlappingRanges } from '../DateUtils';

export const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
export type DaysType = typeof Days[number];

export type HourRange = Scheduling.PageConfigOpeningHourRange;

const toTimeString = (hh: number, mm: number) => `${padWithZeros(hh)}:${padWithZeros(mm)}`;

const HOUR_NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

interface OpeningHoursRowProps {
  value: HourRange;
  onChange: (value: HourRange) => void;
  action: React.ReactNode;
}

export const OpeningHoursRow: React.FunctionComponent<OpeningHoursRowProps> = ({ value, onChange, action }) => {
  const intl = useIntl();
  const { locale12HourTime } = useContext(LocaleContext);

  const DAYS_OF_WEEK = [
    {
      value: 'U',
      label: intl.formatMessage({ defaultMessage: 'S', description: 'Day of Week Letter - Sunday', id: 'dwl-0' }),
    },
    {
      value: 'M',
      label: intl.formatMessage({ defaultMessage: 'M', description: 'Day of Week Letter - Monday', id: 'dwl-1' }),
    },
    {
      value: 'T',
      label: intl.formatMessage({ defaultMessage: 'T', description: 'Day of Week Letter - Tuesday', id: 'dwl-2' }),
    },
    {
      value: 'W',
      label: intl.formatMessage({ defaultMessage: 'W', description: 'Day of Week Letter - Wednesday', id: 'dwl-3' }),
    },
    {
      value: 'R',
      label: intl.formatMessage({ defaultMessage: 'T', description: 'Day of Week Letter - Thursday', id: 'dwl-4' }),
    },
    {
      value: 'F',
      label: intl.formatMessage({ defaultMessage: 'F', description: 'Day of Week Letter - Friday', id: 'dwl-5' }),
    },
    {
      value: 'S',
      label: intl.formatMessage({ defaultMessage: 'S', description: 'Day of Week Letter - Saturday', id: 'dwl-6' }),
    },
  ];

  const start = moment(`1970-01-01T${value.start}:00`);
  const end = moment(`1970-01-01T${value.end}:00`);
  const disabledEndHours = HOUR_NUMBERS.slice(0, start.minute() >= 45 ? start.hour() + 1 : start.hour());
  const disabledStartHours = HOUR_NUMBERS.slice(end.minute() === 0 ? end.hour() : end.hour() + 1);

  return (
    <div className="OpeningHoursPicker">
      <BoxSelectView
        items={DAYS_OF_WEEK}
        selected={value.days}
        onItemsUpdated={(days) => onChange({ ...value, days })}
      />
      <div className="pickers-container">
        <div style={{ width: 100 }}>
          <TimePicker
            minuteStep={15}
            showSecond={false}
            allowEmpty={false}
            disabledHours={() => disabledStartHours}
            disabledMinutes={(h) => (h === end.hour() ? [0, 15, 30, 45].slice(end.minute() / 15) : [])}
            use12Hours={locale12HourTime}
            value={start}
            onChange={(m) => {
              m = m.isBefore(end) ? m : end.subtract(30, 'm');
              onChange({ ...value, start: toTimeString(m.hour(), m.minute()) })
            }
            }
          />
        </div>
        <div className="until" style={{ padding: '0 12px' }}>
          <FormattedMessage defaultMessage="until" description="Settings - Opening Hours - until" id="Settings-ohu" />
        </div>
        <div style={{ width: 100 }}>
          <TimePicker
            minuteStep={15}
            showSecond={false}
            allowEmpty={false}
            disabledHours={() => disabledEndHours}
            disabledMinutes={(h) => (h === start.hour() ? [0, 15, 30, 45].slice(0, start.minute() / 15 + 1) : [])}
            use12Hours={locale12HourTime}
            value={end}
            onChange={(m) => {
              m = m.isAfter(start) ? m : start.add(30, 'm');
              onChange({ ...value, end: toTimeString(m.hour(), m.minute()) })
            }
            }
          />
        </div>
        {action}
      </div>
    </div>
  );
};

const OpeningHoursView: React.FunctionComponent<TabComponentProps> = ({ page, config, onChange }) => {
  const intl = useIntl();
  return (
    <div className="OpeningHoursView">
      <h2 style={{ marginBottom: 7 }}>
        <FormattedMessage
          defaultMessage="What is the event time zone?"
          description="Settings - Opening Hours - Time Zone Prompt"
          id="Settings-ohtzp"
        />
      </h2>
      <p style={{ marginTop: 0, opacity: 0.6 }}>
        <FormattedMessage
          defaultMessage="Time zone for opening hours and new events. Your invitees will see your availability in their local time zone."
          description="Settings - Opening Hours - Time Zone Description"
          id="Settings-ohtzd"
        />
      </p>
      <TimezoneSelectView
        value={page.config.timezone}
        onChange={(tz) => onChange({ ...page, config: { ...page.config, timezone: tz } })}
      />
      <h2 style={{ marginTop: 40 }}>
        <FormattedMessage
          defaultMessage="What are the opening hours?"
          description="Settings - Opening Hours - Prompt"
          id="Settings-ohhp"
        />
        <Tooltip 
          text={intl.formatMessage({
          id: 'Settings-ei-cptt',
          description: 'Settings - Event Info - Cancellation Policy tooltip',
          defaultMessage:
            'Meetings can only be booked during your opening hours when there are no conflicts on your availability calendars.',
          })}
        />
      </h2>
      <RequestForAccountsAndCalendars pageId={page.id}>
        {(calsByAccount) => (
          <OpeningHoursEditor
            accounts={calsByAccount}
            values={page.config.booking.opening_hours}
            onChange={(openingHours) => {
              const nextPage = clone(page);
              nextPage.config.booking.opening_hours = openingHours;
              onChange(nextPage);
            }}
          />
        )}
      </RequestForAccountsAndCalendars>
    </div>
  );
};

function cloneAndSplice<T>(values: T[], index: number, removeCount: number, ...items: T[]) {
  const next = [...values];
  next.splice(index, removeCount, ...items);
  return next;
}

const OpeningHoursEditor: React.FunctionComponent<{
  accounts: Scheduling.PageConfigCalendars[];
  values: Scheduling.PageConfigOpeningHourRange[];
  onChange: (values: Scheduling.PageConfigOpeningHourRange[]) => void;
}> = ({ values, accounts, onChange }) => {
  const intl = useIntl();

  const [overlappingRangeError, setOverlappingRangeError] = React.useState<OverlappingRanges>(null)

  React.useEffect(() => {
    // If you have more than one account, take any opening hours defined for all account IDs and convert them into
    // N opening hours entries, one bound to each account ID so the per-account editing works as expected.
    if (accounts.length > 1) {
      const withValidAccountID = values.filter((v) => v.account_id && accounts.find((a) => a.id === v.account_id));
      const withNoAccountID = values.filter((v) => !v.account_id);
      if (withNoAccountID.length) {
        onChange([
          ...withValidAccountID,
          ...accounts.map((a) => withNoAccountID.map((item) => ({ ...item, account_id: a.id }))).flat(),
        ]);
      }
    }

    setOverlappingRangeError(findOverlappingRangeByDay(values))

  }, [onChange, accounts, values]);

  return (
    <>
      {overlappingRangeError && overlappingRangeError?.length > 0 &&
        <div className="">
          <AlertBox
            message={"Overlapping Time Ranges"}
            hint={"Having overlapping time ranges can cause some unexpected behaviors. Update open hours by removing overlapping time ranges."}
            type="warning"
            onClickDismiss={() => setOverlappingRangeError(null)}
          />
        </div>
      }
      {accounts.map((account) => (
        <div key={account.id} style={{ paddingBottom: 20 }}>
          {accounts.length > 1 && <label style={{ marginBottom: 3, display: 'block' }}>{`${account.email}:`}</label>}
          {values
            .filter((v) => !v.account_id || v.account_id === account.id)
            .map((item, filteredIdx) => (
              <OpeningHoursRow
                key={filteredIdx}
                value={item}
                onChange={(newItem) => onChange(cloneAndSplice(values, values.indexOf(item), 1, newItem))}
                action={
                  <SVGButton
                    style={{ marginLeft: 15 }}
                    onClick={() => onChange(cloneAndSplice(values, values.indexOf(item), 1))}
                    title={intl.formatMessage({
                      defaultMessage: 'Remove',
                      description: 'Button - Remove',
                      id: 'Button-Remove',
                    })}
                  >
                    <Icons.X width={12} height={12} />
                  </SVGButton>
                }
              />
            ))}
          <Button
            size="small"
            intent="outlined"
            style={{ paddingLeft: 0, border: 0, fontSize: '1em', lineHeight: '18px', height: 18 }}
            onClick={() =>
              onChange([
                ...values,
                Object.assign({}, DefaultOpeningHourRange, accounts.length > 1 ? { account_id: account.id } : {}),
              ])
            }
          >
            <Icons.Plus width={12} height={12} style={{ marginRight: 4 }} />
            <FormattedMessage defaultMessage="Add" description="Button-Add" id="Button-Add" />
          </Button>
        </div>
      ))}
      <div style={{ height: 105, flexShrink: 0 }} />
    </>
  );
};
export default OpeningHoursView;
