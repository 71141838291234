import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Icons from '../components/SVGIcons';
import TimezoneDefaultEnforcer from '../components/TimezoneDefaultEnforcer';
import TimezoneSelectView from '../components/TimezoneSelectView';
import { setLocalStorageTimezone } from '../DateUtils';
import { track } from '../NylasWindowContext';
import { getLocalStorage } from '../Storage';
import { ViewType } from './MainView';
import { LanguageSelectView } from './LanguageSelectView';

export interface MainViewFooterProps {
  hideTimezoneOptions: boolean;
  hideWeekView: boolean;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  localeOverride?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  timezone: string;
  setTimezone: (timezone: string) => void;
}

export const MainViewFooter: React.FC<MainViewFooterProps> = ({
  hideTimezoneOptions,
  hideWeekView,
  viewType,
  setViewType,
  localeOverride,
  style,
  className,
  timezone,
  setTimezone,
}) => {
  const handleToggleViewType = () => {
    const nextViewType = {
      Default: 'Calendar',
      Calendar: 'Default',
    }[viewType] as ViewType;
    getLocalStorage().setItem('ViewType', nextViewType);
    track('Calendar Style Changed', { viewType: nextViewType });
    setViewType(nextViewType);
  };

  const handleTimezoneChange = (value: string) => {
    setLocalStorageTimezone(value);
    track('Timezone Changed', { value });
    setTimezone(value);
  };

  return (
    <div className={`footer ${className ? className : ''}`} style={style}>
      <div className="footer--left">
        {!hideWeekView && (
          <button
            className="view-type"
            onClick={handleToggleViewType}
            aria-label={`change to ${viewType === 'Calendar' ? 'month' : 'week'} view`}
          >
            <Icons.View />
            {viewType === 'Calendar' ? (
              <FormattedMessage
                id="Button-stmv"
                description="Button - Switch to Month View"
                defaultMessage="Switch to Month View"
              />
            ) : (
              <FormattedMessage
                id="Button-stwv"
                description="Button - Switch to Week View"
                defaultMessage="Switch to Week View"
              />
            )}
          </button>
        )}
      </div>
      <div className="footer--spacer" />
      <div className="footer--right">
        <LanguageSelectView override={localeOverride} />
        {!hideTimezoneOptions ? (
          <TimezoneSelectView value={timezone} onChange={handleTimezoneChange} />
        ) : (
          <TimezoneDefaultEnforcer />
        )}
      </div>
    </div>
  );
};
