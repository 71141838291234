import React, { Component } from 'react';
import { clone, diff, assertUnreachable, MAX_PARTICIPANTS, MIN_PARTICIPANTS } from '../Utils';
import { FormWithSpy, Field } from '../components/FormHelpers';
import { PageWizardStepViewProps } from './PageWizardView';

import './PageWizardEventInfoView.scss';
import { TimeField } from './TimeField';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Scheduling } from '../types/Scheduling';
import { ParticipantsField } from './ParticipantsField';

interface FormValues {
  duration: number;
  name: string;
  template_name?: string;
  location: string;
  participants?: Scheduling.Participant[];
  attendee_capacity?: number;
}

class PageWizardEventInfoView extends Component<PageWizardStepViewProps & { intl: IntlShape }> {
  isAttendeeCapacityInvalid = false;

  getFormValues(): FormValues {
    return {
      name: this.props.pageConfig.event.title,
      template_name: this.props.pageConfig.event.template_title,
      location: this.props.pageConfig.event.location,
      duration: this.props.pageConfig.event.duration,
      participants: this.props.pageConfig.event.participants,
      attendee_capacity: this.props.pageConfig.event.capacity,
    };
  }

  handleFormChange = (values: FormValues) => {
    const lastValues = this.getFormValues();
    const difference = diff({ template_name: undefined, ...values }, lastValues);
    if (difference.length === 0) {
      return;
    }

    // We keep track of the new props and state we're going to be persisting and then batch it all at the end.
    // - Props: Cloned so we can modify it based on the form values that have changed and then pass it to the change handler.
    // - State: Track changes and merge with existing state below to keep track of changes to time unit values.
    const updatedConfig = clone(this.props.pageConfig);

    difference.forEach((key) => {
      const activeValue = (values as any)[key];
      switch (key) {
        case 'duration':
          updatedConfig.event.duration = activeValue;
          break;
        case 'name':
          updatedConfig.event.title = activeValue;
          break;
        case 'template_name':
          updatedConfig.event.template_title = activeValue;
          break;
        case 'location':
          updatedConfig.event.location = activeValue;
          break;
        case 'participants':
          updatedConfig.booking.scheduling_method =
            activeValue.length > 0 ? 'collective' : 'round-robin-maximize-availability';
          updatedConfig.event.participants = activeValue;
          break;
        case 'attendee_capacity':
          updatedConfig.event.capacity = activeValue;
          break;
        default:
          assertUnreachable(key);
      }
    });

    if (this.props.pageConfig.features?.group_meetings) {
      this.isAttendeeCapacityInvalid = !!(
        updatedConfig.event.capacity &&
        (updatedConfig.event.capacity > MAX_PARTICIPANTS || updatedConfig.event.capacity < MIN_PARTICIPANTS)
      );

      updatedConfig.hasFormErrors = this.isAttendeeCapacityInvalid;
    }

    this.props.onChange(updatedConfig);
  };

  updateParticipants = async (participants: Scheduling.Participant[]) => {
    this.handleFormChange({ ...this.getFormValues(), participants });
  };

  handleSaveButtonClicked = async () => {
    await this.props.onChange(this.props.pageConfig);
  };

  render() {
    return (
      <div className="PageWizardEventInfoView">
        <FormWithSpy onChange={this.handleFormChange} onSubmit={() => {}} initialValues={this.getFormValues()}>
          {() => (
            <>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <div className="textInputContainer">
                    <h2>
                      <FormattedMessage
                        defaultMessage="What should new events be named?"
                        description="Settings - Event Info - Event name prompt"
                        id="Settings-eien"
                      />
                    </h2>
                    <Field
                      name="name"
                      label="Name"
                      placeholder={this.props.intl.formatMessage({
                        id: 'Settings-eienp',
                        description: 'Settings - Event Info - Event name placeholder',
                        defaultMessage: 'Event Name',
                      })}
                      component="input"
                      type="text"
                    />
                  </div>
                  {/* Template Title */}
                  <div className="textInputContainer">
                    <h2>
                      <FormattedMessage
                        defaultMessage="Are you using an event template title?"
                        description="Settings - Event Info - Event template title prompt"
                        id="Settings-eiettp"
                      />
                    </h2>
                    <Field
                      name="template_name"
                      label="Template Title"
                      placeholder={this.props.intl.formatMessage({
                        id: 'Settings-eiettpl',
                        description: 'Settings - Event Info - Event template title placeholder',
                        defaultMessage: 'Event Template Title',
                      })}
                      component="input"
                      type="text"
                    />
                  </div>
                  {/* Location */}
                  <div className="textInputContainer  ">
                    <h2>
                      <FormattedMessage
                        defaultMessage="Where do events take place?"
                        description="Settings - Event Info - Event location prompt"
                        id="Settings-eiel"
                      />
                    </h2>
                    <Field
                      name="location"
                      label="Location"
                      placeholder={this.props.intl.formatMessage({
                        description: 'Settings - Event Info - Event location placeholder',
                        defaultMessage: 'Event Location',
                        id: 'Settings-eielp',
                      })}
                      component="input"
                      type="text"
                    />
                  </div>
                  <div className="textInputContainer">
                    <h2>
                      <FormattedMessage
                        defaultMessage="How long is each event?"
                        description="Settings - Event Info - Event duration prompt"
                        id="Settings-eiedp"
                      />
                    </h2>
                    <TimeField fieldKey="duration" label="" />
                  </div>
                  {this.props.pageConfig.features?.group_meetings && (
                    <div className="textInputContainer">
                      <h2>
                        <FormattedMessage
                          defaultMessage="What is the maximum number of attendees for each event?"
                          description="Settings - Event Info - Event attendee capacity prompt"
                          id="Settings-eacp"
                        />
                      </h2>
                      <Field
                        name="attendee_capacity"
                        label="Capacity"
                        placeholder={this.props.intl.formatMessage({
                          id: 'Settings-attca',
                          description: 'Settings - Event Info - Event attendee capacity placeholder',
                          defaultMessage: 'Capacity',
                        })}
                        min={MIN_PARTICIPANTS}
                        max={MAX_PARTICIPANTS}
                        component="input"
                        type="number"
                      />
                      {this.isAttendeeCapacityInvalid && (
                        <div className="formErrors">
                          <p>
                            <FormattedMessage
                              defaultMessage="An event must have between {MIN_PARTICIPANTS} and {MAX_PARTICIPANTS} attendees."
                              description="Settings - Event Info - Invalid attendee count"
                              id="Settings-iac"
                              values={{
                                MIN_PARTICIPANTS,
                                MAX_PARTICIPANTS,
                              }}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {this.props.pageConfig.features?.collective_meetings && (
                    <div className="textInputContainer">
                      <h2>
                        <FormattedMessage
                          defaultMessage="Who else will be joining you?"
                          description="Settings - Event Info - Event participants"
                          id="Settings-eiepp"
                        />
                      </h2>
                      <ParticipantsField
                        organizer={this.props.config.token}
                        participants={this.getFormValues().participants}
                        updateParticipants={this.updateParticipants}
                      />
                    </div>
                  )}
                </div>
                <div className="calendar-preview">
                  <div className="preview">
                    <FormattedMessage
                      defaultMessage="PREVIEW"
                      description="Settings - Event Info - Preview"
                      id="Settings-eip"
                    />
                  </div>
                  <div className="calendar-time-line" style={{ top: 0 }} />
                  <div className="calendar-time-line dark" style={{ top: 50 }} />
                  <div className="calendar-time-line" style={{ top: 100 }} />
                  <div className="calendar-time-line dark" style={{ top: 150 }} />
                  <div className="calendar-time-line" style={{ top: 200 }} />
                  <div className="calendar-time-line dark" style={{ top: 250 }} />
                  <div className="calendar-time-line" style={{ top: 300 }} />
                  <div className="calendar-time-line dark" style={{ top: 350 }} />
                  <div
                    className="calendar-event"
                    style={{
                      height: Math.min(320, (100 / 60) * this.props.pageConfig.event.duration - 10),
                    }}
                  >
                    <div>
                      <strong>{this.props.pageConfig.event.template_title ?? this.props.pageConfig.event.title}</strong>
                    </div>
                    <div>{this.props.pageConfig.event.location}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </FormWithSpy>
      </div>
    );
  }
}

export default injectIntl(PageWizardEventInfoView);
