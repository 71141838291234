import React from 'react';
import { Field } from 'react-final-form';
import { Scheduling } from '../types/Scheduling';
import { clone } from '../Utils';
import { TabComponentProps } from './PageDetailView';
import './WidgetView.scss';
import { ImageInput } from '../components/ImageInput';
import { FormBlock, FormWithSpy } from '../components/FormHelpers';
import { ensurePound } from '../ThemeSupport';
import { useIntl } from 'react-intl';

interface WidgetFields extends Scheduling.PageConfigAppearance {
  slug: string;
}

const WidgetView: React.FunctionComponent<TabComponentProps> = (props) => {
  const { appearance } = props.page.config;
  const intl = useIntl();

  const handleFormChange = ({ slug, ...appearance }: WidgetFields) => {
    // The field props all match the backing appearance so we can spat
    // the values instead of manually needing to specify them.
    const page = clone(props.page);
    page.slug = slug;
    page.config.appearance = appearance;
    props.onChange(page);
  };

  // https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
  const isValidColor = /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(appearance.color || '');
  const disableSlugChanges = props.config.behavior.disableEditing.includes('slug');
  const initialValues = { ...appearance, slug: props.page.slug };
  return (
    <div className="WidgetView">
      <FormWithSpy onChange={handleFormChange} onSubmit={() => {}} initialValues={initialValues}>
        {() => (
          <>
            <div className="companyFormsContainer">
              <FormBlock
                label={intl.formatMessage({
                  id: 'Settings-p-cn',
                  defaultMessage: 'Company name',
                  description: 'Settings - Page - Company Name',
                })}
                bigLabel={true}
                tooltip={intl.formatMessage({
                  id: 'Settings-pcntt',
                  defaultMessage:
                    'Your company name appears in the page title and is shown when the page is embedded on Facebook, Twitter, etc.',
                  description: 'Settings - Page - Company Name Tooltip',
                })}
              >
                <Field
                  label={intl.formatMessage({
                    id: 'Settings-p-cn',
                    defaultMessage: 'Company name',
                    description: 'Settings - Page - Company Name',
                  })}
                  className="widgetField"
                  name="company_name"
                  component="input"
                  type="text"
                />
              </FormBlock>
              <FormBlock
                bigLabel={true}
                label={intl.formatMessage({
                  id: 'Settings-p-cl',
                  defaultMessage: 'Company logo',
                  description: 'Settings - Page - Company Logo',
                })}
                tooltip={intl.formatMessage({
                  id: 'Settings-p-clt',
                  defaultMessage:
                    'Your company logo appears in the header of the booking page. For best results, choose an image larger than 300x150px.',
                  description: 'Settings - Page - Company Logo Tooltip',
                })}
              >
                <Field name="logo" type="text">
                  {({ input }) => (
                    <ImageInput
                      value={input.value}
                      pageId={props.page.id}
                      onChange={(url) => input.onChange({ target: { value: url } } as any)}
                      minimumSize={{ width: 300, height: 150 }}
                    />
                  )}
                </Field>
              </FormBlock>
            </div>

            <div className="styleFormsContainer">
              {!disableSlugChanges && (
                <FormBlock
                  bigLabel={true}
                  label={intl.formatMessage({
                    id: 'Settings-p-cs',
                    defaultMessage: 'Custom page slug',
                    description: 'Settings - Page - Custom Slug',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'Settings-p-cst',
                    defaultMessage: 'This changes the unique URL of your booking page, eg: scheduling.app.com/slug',
                    description: 'Settings - Page - Custom Slug Tooltip',
                  })}
                >
                  <Field name="slug" component="input" type="text" style={{ flex: 1 }} />
                </FormBlock>
              )}
              <FormBlock
                bigLabel={true}
                label={intl.formatMessage({
                  id: 'Settings-p-tc',
                  defaultMessage: 'Theme color',
                  description: 'Settings - Page - Theme Color',
                })}
                tooltip={intl.formatMessage({
                  id: 'Settings-p-tct',
                  defaultMessage: 'The accent color used on your page. You may want to match the color in your logo.',
                  description: 'Settings - Page - Theme Color Tooltip',
                })}
              >
                <div className="widgetField" style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className={`colorSwatch ${isValidColor ? '' : 'invalidColorSwatch'}`}
                    style={{
                      backgroundColor: isValidColor ? ensurePound(appearance.color || '') : 'transparent',
                    }}
                  />
                  <Field name="color" component="input" type="text" style={{ flex: 1 }} />
                </div>
              </FormBlock>
              <FormBlock
                bigLabel={true}
                label={intl.formatMessage({
                  id: 'Settings-p-sbl',
                  defaultMessage: 'Submit button label',
                  description: 'Settings - Page - Submit Label',
                })}
                tooltip={intl.formatMessage({
                  id: 'Settings-p-sblt',
                  defaultMessage:
                    'The text of the large `Booking` button. You may want it to say `Reserve Appointment`, for example, if you provide your visitors a service.',
                  description: 'Settings - Page - Submit Label Tooltip',
                })}
              >
                <Field name="submit_text" className="widgetField" component="input" type="text" />
              </FormBlock>
              <FormBlock
                bigLabel={true}
                label={intl.formatMessage({
                  id: 'Settings-p-tym',
                  defaultMessage: 'Thank you message',
                  description: 'Settings - Page - Thank you message',
                })}
                tooltip={intl.formatMessage({
                  id: 'Settings-p-tymt',
                  defaultMessage: 'The text that appears after visitors create bookings.',
                  description: 'Settings - Page - Thank you message Tooltip',
                })}
              >
                <Field name="thank_you_text" className="widgetField" component="input" type="text" />
              </FormBlock>
            </div>
          </>
        )}
      </FormWithSpy>
    </div>
  );
};

export default WidgetView;
