import React from 'react';
import { Field } from 'react-final-form';
import { Sheet, SheetBody, SheetFooter } from '../components/Sheet';
import { TypedSelectField, FormBlock, FormWithSpy } from '../components/FormHelpers';
import Button from '../components/Button';
import { Scheduling } from '../types/Scheduling';
import { clone } from '../Utils';
import { TimeField } from './TimeField';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

interface ReminderEditSheetProps {
  intl: IntlShape;
  reminder: Scheduling.PageConfigReminder;
  config: Scheduling.PageConfig;
  onCreate: (reminder: Scheduling.PageConfigReminder) => void;
  onCancel: () => void;
}

interface ReminderEditSheetState {
  scratchReminder: Scheduling.PageConfigReminder;
  [key: string]: any;
}

class ReminderEditSheetBase extends React.Component<ReminderEditSheetProps, ReminderEditSheetState> {
  constructor(props: Readonly<ReminderEditSheetProps>) {
    super(props);

    let scratchReminder = clone(props.reminder);
    let timeBeforeEvent = scratchReminder.time_before_event;

    this.state = {
      scratchReminder: scratchReminder,
      timeBeforeEvent: timeBeforeEvent,
    };
  }

  getFormValues() {
    return {
      scratchReminder: this.state.scratchReminder,
      timeBeforeEvent: this.state.scratchReminder.time_before_event,
    };
  }

  handleFormChange = (values: ReminderEditSheetState) => {
    let { scratchReminder, timeBeforeEvent } = values;
    scratchReminder.time_before_event = Number(timeBeforeEvent);
    this.setState({ scratchReminder, timeBeforeEvent });
  };

  render() {
    return (
      <Sheet
        onDismiss={this.props.onCancel}
        title={this.props.intl.formatMessage({
          id: 'Settings-rcrh',
          description: 'Settings - Reminders - Create Reminder Header',
          defaultMessage: 'Create Reminder',
        })}
      >
        <SheetBody>
          <FormWithSpy onChange={this.handleFormChange} onSubmit={() => {}} initialValues={this.getFormValues()}>
            {() => (
              <>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <TimeField fieldKey="timeBeforeEvent" />
                  <h2 style={{ marginLeft: 5 }}>
                    <FormattedMessage
                      defaultMessage="before the event:"
                      description="Settings - Reminders - Recipient Prompt"
                      id="Settings-r-rp"
                    />
                  </h2>
                </div>
                {this.state.scratchReminder.delivery_method === 'email' && (
                  <div className="reminder-edit-form">
                    <FormBlock
                      bigLabel={true}
                      label={this.props.intl.formatMessage({
                        id: 'Settings-rer',
                        description: 'Settings - Reminders - Email Recipients',
                        defaultMessage: 'Email Recipients',
                      })}
                    >
                      <TypedSelectField<Scheduling.PageConfigReminder['delivery_recipient']>
                        name="scratchReminder.delivery_recipient"
                        style={{ width: '100%' }}
                        options={[
                          {
                            key: 'customer',
                            value: 'customer',
                            label: this.props.intl.formatMessage({
                              defaultMessage: 'Event guests',
                              description: 'Settings - Reminders - Recipient - Guests',
                              id: 'Settings-rereg',
                            }),
                          },
                          {
                            key: 'owner',
                            value: 'owner',
                            label: this.props.intl.formatMessage({
                              defaultMessage: 'Event host',
                              id: 'Settings-rereh',
                              description: 'Settings - Reminders - Recipient - Host',
                            }),
                          },
                          {
                            key: 'both',
                            value: 'both',
                            label: this.props.intl.formatMessage({
                              defaultMessage: 'Send to both',
                              id: 'Settings-rereb',
                              description: 'Settings - Reminders - Recipient - Both',
                            }),
                          },
                        ]}
                      />
                    </FormBlock>
                    <FormBlock
                      bigLabel={true}
                      label={this.props.intl.formatMessage({
                        id: 'Settings-res',
                        description: 'Settings - Reminders - Email Subject',
                        defaultMessage: 'Email Subject',
                      })}
                    >
                      <Field
                        name="scratchReminder.email_subject"
                        component="input"
                        type="text"
                        style={{ width: 200 }}
                      />
                    </FormBlock>
                  </div>
                )}
                {this.state.scratchReminder.delivery_method === 'webhook' && (
                  <div style={{ display: 'flex', marginTop: 15 }}>
                    <Field
                      name="scratchReminder.webhook_url"
                      label={this.props.intl.formatMessage({
                        id: 'Settings-repwu',
                        description: 'Settings - Reminders - Public Webhook URL',
                        defaultMessage: 'Public Webhook URL',
                      })}
                      component="input"
                      type="text"
                      style={{ width: 400 }}
                    />
                  </div>
                )}
              </>
            )}
          </FormWithSpy>
        </SheetBody>
        <SheetFooter>
          <Button intent="default" size="small" onClick={() => this.props.onCreate(this.state.scratchReminder)}>
            <FormattedMessage defaultMessage="Create" description="Button-Create" id="Button-Create" />
          </Button>
        </SheetFooter>
      </Sheet>
    );
  }
}

export const ReminderEditSheet = injectIntl(ReminderEditSheetBase);
