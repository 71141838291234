import { Scheduling } from './types/Scheduling';

// Note: This file provides a Typescript-friendly interface to the data that is
// injected into the window from the server in index.html.

interface NylasWindowContext {
  apiBaseURL: string;
  appType: 'embed' | 'booking';
  pageSlug: string;
  page: Scheduling.Page | null;
}

export function getNylasWindowContext() {
  return (window as any).nylasWindowContext as NylasWindowContext;
}

export function setNylasWindowContext(val: NylasWindowContext) {
  (window as any).nylasWindowContext = val;
}

export function track(eventType: string, eventProperties: { [key: string]: any }) {
  if (process.env.NODE_ENV === 'development') {
    console.log(`Track: ${eventType} ${JSON.stringify(eventProperties)}`);
  }

  if ((window as any).amplitude) {
    const { page, pageSlug, appType } = getNylasWindowContext();
    (window as any).amplitude.logEvent(
      eventType,
      Object.assign(
        {
          app_client_id: page ? page.app_client_id : undefined,
          internal_org_id: page ? page.app_organization_id : undefined,
          category: appType,
          page_slug: pageSlug,
          label: pageSlug,
        },
        eventProperties,
      ),
    );
  }
}
