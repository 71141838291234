import React from 'react';
import { clone } from '../Utils';
import './BoxSelectView.scss';

interface BoxSelectViewProps {
  items: { label: string; value: string }[];
  selected: string[];
  disabled?: boolean;
  canSelectNone?: boolean;
  onItemsUpdated?: (selected: string[]) => void;
}
const BoxSelectView: React.FunctionComponent<BoxSelectViewProps> = ({
  items,
  selected,
  disabled,
  canSelectNone,
  onItemsUpdated,
}) => {
  const handleItemClicked = (item: string) => {
    const selectedCopy = clone(selected);
    const index = selectedCopy.findIndex(e => e === item);
    if (index === -1) {
      selectedCopy.push(item);
    } else if (canSelectNone || selectedCopy.length > 1) {
      // disallow selecting all
      selectedCopy.splice(index, 1);
    }
    if (onItemsUpdated) {
      onItemsUpdated(selectedCopy);
    }
  };

  return (
    <div className={`BoxSelectView ${disabled && 'disabled'}`}>
      {items.map((i, index) => (
        <button
          key={i.value}
          className={selected.findIndex(e => e === i.value) !== -1 ? 'active' : ''}
          onClick={() => handleItemClicked(i.value)}
        >
          {i.label}
        </button>
      ))}
    </div>
  );
};

BoxSelectView.defaultProps = {
  canSelectNone: false,
};

export default BoxSelectView;
