import React, { Component } from 'react';
import { Scheduling } from '../types/Scheduling';
import Button from '../components/Button';
import './CalendarWeekView.scss';
import * as DateUtils from '../DateUtils';
import * as Icons from '../components/SVGIcons';
import SVGButton from '../components/SVGButton';
import { getCalendarBgGridWithHeight } from '../Utils';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const fifteenMinuteSegmentHeight = 24.0; // pixels
const hourSegmentHeight = fifteenMinuteSegmentHeight * 4;
const secondHeight = fifteenMinuteSegmentHeight / 15.0 / 60.0;
const calendarBgDataUrl = getCalendarBgGridWithHeight(fifteenMinuteSegmentHeight * 4);
const defaultHourRange = { min: 8, max: { value: 20, through: false } }; // We'll always show  8am - 8pm

type TimeSlot = Scheduling.TimeSlot;
type WeekChangeType = 'prev' | 'next' | 'today';
type HourRange = { min: number; max: { value: number; through: boolean } };

interface CalendarWeekViewProps {
  slots: TimeSlot[];
  onSlotClicked: (slot: TimeSlot) => Promise<void>;
  loading: boolean;
}

interface CalendarWeekViewState {
  currentDate: Date | null;
  weekHeaderMarginRight: number;
}

class CalendarWeekView extends Component<CalendarWeekViewProps, CalendarWeekViewState> {
  outerScrollRef = React.createRef<HTMLDivElement>();
  innerScrollRef = React.createRef<HTMLDivElement>();
  calendarRef = React.createRef<HTMLDivElement>();

  state: CalendarWeekViewState = {
    currentDate: null,
    weekHeaderMarginRight: 0,
  };

  componentDidMount() {
    const outer = this.outerScrollRef.current;
    const inner = this.innerScrollRef.current;
    const calendar = this.calendarRef.current;
    const firstSlot = calendar && calendar.querySelector('.slot');

    if (outer && inner) {
      this.setState({ weekHeaderMarginRight: outer.offsetWidth - inner.offsetWidth });
    }

    if (calendar && firstSlot instanceof HTMLElement) {
      // scroll to show the first slot. We can do this the hard way, or we can do this the
      // easy way (just locate the first slot in the DOM subtree, which happens to be in
      // calendar order.)
      const scrollContainer = calendar.parentElement!;
      scrollContainer.scrollTop = firstSlot.offsetTop - Math.max(20, scrollContainer.clientHeight / 6);
    }
  }

  handleChangeWeekClicked = (operation: WeekChangeType) => {
    // Either adjust the visible date or reset to today.
    let date: Date;
    switch (operation) {
      case 'prev':
        date = DateUtils.decrementDateByOneWeek(this.getDisplayedDate());
        break;
      case 'next':
        date = DateUtils.incrementDateByOneWeek(this.getDisplayedDate());
        break;
      default:
        date = new Date();
        break;
    }

    this.setState({ currentDate: date });
  };

  getDisplayedDate() {
    if (this.state.currentDate) return this.state.currentDate;
    return this.props.slots.length ? new Date(this.props.slots[0].start * 1000) : new Date();
  }

  getCalendarContentHeight(hourRange: HourRange): number {
    const length = hourRange.max.value + (hourRange.max.through ? 1 : 0) - hourRange.min;
    return length * hourSegmentHeight;
  }

  getHourRange(slots: TimeSlot[], defaultHourRange: HourRange): HourRange {
    let min = defaultHourRange.min;
    let max = defaultHourRange.max;
    slots.forEach((s) => {
      min = Math.min(min, DateUtils.getMinHourInSlot(s));

      const maxHour = DateUtils.getMaxHourInSlot(s);
      if ((maxHour.value === max.value && maxHour.through) || maxHour.value > max.value) {
        max = maxHour;
      }
    });
    return { min, max };
  }

  render() {
    const { loading, slots, onSlotClicked } = this.props;
    const { weekHeaderMarginRight } = this.state;
    const currentDate = this.getDisplayedDate();

    const monthString = moment.utc(currentDate).format('MMMM');

    const getDayRange = (date: Date, hourRange: HourRange): { start: Date; end: Date } => {
      const start = new Date(date.getTime());
      start.setUTCHours(hourRange.min, 0, 0, 0);
      const end = new Date(start.getTime());
      end.setUTCHours(hourRange.max.value, 59, 59, 999);
      return { start, end };
    };

    const weekDates = DateUtils.getSortedWeekSurroundingDate(currentDate);
    const startOfWeekUnix = DateUtils.getStartOfWeek(weekDates[0]).getTime() / 1000;
    const endOfWeekUnix = DateUtils.getEndOfWeek(weekDates[0]).getTime() / 1000;

    const hasPrevSlots = slots.find((s) => s.start < startOfWeekUnix) !== undefined;
    const hasNextSlots = slots.find((s) => s.start >= endOfWeekUnix) !== undefined;
    const hasSlots = slots.find((s) => s.start >= startOfWeekUnix && s.start < endOfWeekUnix) !== undefined;

    const hourRange = this.getHourRange(DateUtils.getSlotsInWeekOfDate(currentDate, slots), defaultHourRange);
    const calendarContentHeight = this.getCalendarContentHeight(hourRange);

    return (
      <div className={`CalendarWeekView`}>
        {/* Use this div for getting the width of the scrollbar. Should this be moved into its own component for reuse? */}
        <div
          ref={this.outerScrollRef}
          style={{ position: 'absolute', visibility: 'hidden', width: 100, overflowY: 'scroll' }}
        >
          <div ref={this.innerScrollRef} />
        </div>
        <CalendarNavHeader
          title={monthString}
          prevWeekEnabled={hasPrevSlots}
          nextWeekEnabled={hasNextSlots}
          onChangeWeek={this.handleChangeWeekClicked}
        />
        <CalendarWeekHeader weekDates={weekDates} scrollbarInset={weekHeaderMarginRight} />
        <div className="calendar">
          <div className="outerCalendarContent">
            {hourRange.min !== 0 && <CalendarRibbon />}

            <div className="calendarContent" ref={this.calendarRef}>
              <div
                className="calendarBackground"
                style={{
                  backgroundImage: `url(${calendarBgDataUrl})`,
                  height: calendarContentHeight,
                }}
              />
              <TimeColumn hourRange={hourRange} height={calendarContentHeight} />
              {weekDates
                .map((d) => getDayRange(d, hourRange))
                .map((range) => {
                  const currentDate = new Date();
                  const isCurrentDay = range.start.getUTCDate() === currentDate.getUTCDate();
                  return (
                    <DayColumn
                      key={`day-${range.start.getTime()}`}
                      startTimeSeconds={range.start.getTime() / 1000}
                      endTimeSeconds={range.end.getTime() / 1000}
                      slots={slots}
                      height={calendarContentHeight}
                      isCurrentDay={isCurrentDay}
                      onSlotClicked={onSlotClicked}
                    />
                  );
                })}
            </div>
            {!(hourRange.max.value === 23 && hourRange.max.through) && <CalendarRibbon />}
          </div>
          {!hasSlots && !loading && (
            <div className="no-slots-available">
              <div>Sorry, we're booked this week!</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const CalendarNavHeader: React.FunctionComponent<{
  title: string;
  prevWeekEnabled: boolean;
  nextWeekEnabled: boolean;
  onChangeWeek: (changeType: WeekChangeType) => void;
}> = ({ title, prevWeekEnabled, nextWeekEnabled, onChangeWeek }) => {
  return (
    <div className="CalendarNavHeader">
      <div className="side-items">
        <SVGButton
          className="tinted"
          title="previous week"
          onClick={() => prevWeekEnabled && onChangeWeek('prev')}
          disabled={!prevWeekEnabled}
        >
          <Icons.ArrowLeft />
        </SVGButton>
      </div>
      <div className="title">{title}</div>
      <div className="side-items" style={{ justifyContent: 'flex-end' }}>
        <Button
          size="small"
          intent="outlined"
          style={{ minWidth: 70, width: 'auto', lineHeight: '1.5rem' }}
          onClick={() => onChangeWeek('today')}
        >
          <FormattedMessage
            defaultMessage="Today"
            description="Calendar - Today Button Label"
            id="Calendar-Today-Button-Label"
          />
        </Button>
        <SVGButton
          className="tinted"
          title="next week"
          onClick={() => nextWeekEnabled && onChangeWeek('next')}
          disabled={!nextWeekEnabled}
        >
          <Icons.ArrowRight />
        </SVGButton>
      </div>
    </div>
  );
};

const CalendarWeekHeader: React.FunctionComponent<{ weekDates: Date[]; scrollbarInset: number }> = ({
  weekDates,
  scrollbarInset,
}) => {
  const currentDate = new Date();

  return (
    <div className="weekHeader" style={{ marginRight: scrollbarInset }}>
      <div className="timeColumnSpacer" />
      {weekDates.map((d, index) => {
        const isCurrentDay = d.getUTCDate() === currentDate.getUTCDate();
        const m = moment(d).tz('utc');
        return (
          <div key={index} className="week">
            <div className={`weekContents ${isCurrentDay ? 'currentDay' : ''}`}>
              <div>{m.format('ddd')}</div>
              <div>{m.format('D')}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CalendarRibbon: React.FunctionComponent = () => <div className="calendarRibbon" />;

const TimeColumn: React.FunctionComponent<{ hourRange: HourRange; height: number }> = ({ hourRange, height }) => {
  // Any date will work here since the hours in the day are the same for every day
  const date = new Date();
  date.setUTCMinutes(0);
  const length = hourRange.max.value + (hourRange.max.through ? 1 : 0) - hourRange.min;
  const hours = Array.from({ length: length + 1 }, (x, i) => i + hourRange.min);

  const hourLabels: JSX.Element[] = [];
  hours.forEach((h) => {
    date.setUTCHours(h);
    hourLabels.push(
      <div
        key={h}
        style={{
          position: 'absolute',
          top:
            (h - hourRange.min) * hourSegmentHeight +
            (h === hourRange.min ? 5 : h === hours[hours.length - 1] ? -18 : -5),
          right: 8,
          left: 0,
          textAlign: 'right',
        }}
      >
        {moment(date).tz('utc').format('LT')}
      </div>,
    );
  });

  return (
    <div className="timeColumn" style={{ height }}>
      {hourLabels}
    </div>
  );
};

const DayColumn: React.FunctionComponent<{
  startTimeSeconds: number;
  endTimeSeconds: number;
  slots: TimeSlot[];
  height: number;
  isCurrentDay: boolean;
  onSlotClicked: (slot: TimeSlot) => void;
}> = ({ startTimeSeconds, endTimeSeconds, slots, height, isCurrentDay, onSlotClicked }) => {
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', timeZone: DateUtils.UTC };
  const slotDivs: JSX.Element[] = [];

  slots.forEach((s, index) => {
    const key = `dayColumnSlot-${startTimeSeconds}-${index}`;
    const dateString = new Date(s.start * 1000).toLocaleTimeString(undefined, timeOptions);
    const ariaLabel =  new Date(s.start * 1000).toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', timeZone: DateUtils.UTC });

    if (s.start >= startTimeSeconds && s.end <= endTimeSeconds) {
      const secondsIntoDay = s.start - startTimeSeconds;
      const duration = s.end - s.start;
      const height = Math.floor(secondHeight * duration);

      slotDivs.push(
        <button
          key={key}
          className="slot"
          aria-label={ariaLabel}
          style={{ position: 'absolute', top: Math.floor(secondsIntoDay * secondHeight), right: 1, left: 1, height }}
          onClick={() => onSlotClicked(s)}
        >
          {dateString}
        </button>,
      );
    } else if (s.start < endTimeSeconds && s.end > endTimeSeconds) {
      // Covers the first part of slots overlapping into two days
      const secondsIntoDay = s.start - startTimeSeconds;
      const height = Math.floor(secondHeight * (endTimeSeconds - s.start));
      slotDivs.push(
        <button
          key={key}
          className="slot"
          aria-label={ariaLabel}
          style={{ position: 'absolute', top: Math.floor(secondsIntoDay * secondHeight), right: 1, left: 1, height }}
          onClick={() => onSlotClicked(s)} 
        >
          {dateString}
        </button>,
      );
    } else if (s.start < startTimeSeconds && s.end > startTimeSeconds) {
      // Convers the second part of slots overlapping into two days
      const height = secondHeight * (s.end - startTimeSeconds);

      slotDivs.push(
        <button
          key={key}
          className="endSlot"
          aria-label={`Continued for ${ariaLabel}`}
          style={{ position: 'absolute', top: 0, right: 1, left: 1, height }}
          onClick={() => onSlotClicked(s)}
        >
          {`(cont.) ${dateString}`}
        </button>,
      );
    }
  });

  return (
    <div className={`dayColumn ${isCurrentDay ? 'currentDayColumn' : ''}`} style={{ height }}>
      {slotDivs}
    </div>
  );
};

export default CalendarWeekView;
