import React from 'react';
import { useIntl } from 'react-intl';
import './AlertBox.scss';
import SVGButton from './SVGButton';
import * as Icons from '../components/SVGIcons';

export interface AlertBoxAlerts {
    message: string;
}

interface AlertBoxProps {
    alerts?: AlertBoxAlerts[],
    message?: string,
    hint?: string,
    type: 'error' | 'warning',
    onClickDismiss: () => void,
}


const AlertBox: React.FunctionComponent<AlertBoxProps> = ({
    alerts,
    message,
    hint,
    type,
    onClickDismiss
}) => {

    const intl = useIntl();
    const AlertBoxIcon = () => {
        switch (type) {
            case "warning":
                return <Icons.TooltipInfo />
            case "error":
            default:
                return <Icons.Circle />
        }
    }

    return (
        <div className={`alert-box ${type}`}>
            <SVGButton
                title={intl.formatMessage({ id: 'Close', defaultMessage: 'Close', description: 'Close' })}
                className="close-x"
                onClick={onClickDismiss}
                style={{ width: 20, height: 20 }}
            >
                <Icons.X />

            </SVGButton>
            <div className="alert-body">
                <AlertBoxIcon />
                <div>
                    {message && <p>{message}</p>}
                    {
                        alerts && <ul>
                            {alerts.map((alert, index) => <li key={index}>{alert.message}</li>)}
                        </ul>
                    }
                </div>
            </div>
            {hint && <p>{hint}</p>}
        </div>
    );
};

AlertBox.defaultProps = {
    type: "error",
}

export default AlertBox;
