import React from 'react';
import { Link } from 'react-router-dom';
import { ImmediateRequest } from '../components/NetworkRequest';
import { Scheduling } from '../types/Scheduling';
import { ModalDoneState, ModalErrorState, ModalLoadingState } from './ModalCommonStates';
import { BookingSummary } from './BookingSummary';
import Button from '../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';

interface HostApproveBookingViewProps {
  page: Scheduling.Page;
  editHash: string;
}

export const HostApproveBookingView: React.FunctionComponent<HostApproveBookingViewProps> = ({ page, editHash }) => {
  const intl = useIntl();

  return (
    <div className="HostApproveBookingView">
      <ImmediateRequest<Scheduling.Booking, Scheduling.ConfirmSlotPayload>
        path={`/schedule/${page.slug}/${editHash}/confirm`}
        method="POST"
      >
        {(result, sendRequest) => {
          if (result instanceof Error) {
            return <ModalErrorState message={result.message} onClick={() => sendRequest()} buttonType="try-again" />;
          }
          if (result === 'loading' || result === 'empty') {
            return <ModalLoadingState />;
          }
          return (
            <>
              <div className="left-panel">
                <BookingSummary start={result.start_time} end={result.end_time} />
              </div>
              <div className="divider" style={{ alignSelf: 'stretch' }} />
              <div className="right-panel">
                <ModalDoneState
                  title={intl.formatMessage({
                    id: 'Booking-ct',
                    description: 'Booking Complete - Title',
                    defaultMessage: 'Thank You',
                  })}
                >
                  <div>
                    <FormattedMessage
                      defaultMessage="Your booking has been confirmed."
                      description="BookingComplete - Description Text"
                      id="Booking-cdt"
                    />
                  </div>
                  <Link to={`/${page.slug}`}>
                    <Button>
                      <FormattedMessage
                        defaultMessage="Done"
                        description="Booking Complete - Done Button"
                        id="Booking-cdb"
                      />
                    </Button>
                  </Link>
                </ModalDoneState>
              </div>
            </>
          );
        }}
      </ImmediateRequest>
    </div>
  );
};
