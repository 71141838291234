import React from 'react';
import { convertOffsetToString, getTimezonesWithOffset } from '../DateUtils';
import * as Icons from './SVGIcons';

import './TimezoneSelectView.scss';

interface TimezoneSelectViewProps {
  value: string;
  onChange: (value: string) => void;
}

const displayNameFor = (tzname: string) => {
  return tzname.replace(/_/g, ' ');
};

const TimezoneSelectView: React.FunctionComponent<TimezoneSelectViewProps> = ({ value, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [keyFocusIdx, setKeyFocusIdx] = React.useState(-1);
  const keyFocusRef = React.createRef<HTMLLIElement>();
  const listContainerRef = React.createRef<HTMLUListElement>();
  const timezoneSelectTrigger = React.createRef<HTMLButtonElement>();
  const [keyFocusEvent, setKeyFocusEvent] = React.useState(false);

  React.useEffect(() => {
    const offset = (keyFocusRef.current?.offsetTop || 0)
      - (listContainerRef.current?.scrollTop || 0);

    if (keyFocusEvent) {
      if (offset > (listContainerRef.current?.clientHeight || 0)){
        keyFocusRef.current?.scrollIntoView(false);
      } else if (offset <= (keyFocusRef.current?.clientHeight || 0)) {
        keyFocusRef.current?.scrollIntoView();
      }
      setKeyFocusEvent(false);
    }
  }, [keyFocusRef, keyFocusEvent, listContainerRef]);

  const topZones = ['America/Los_Angeles', 'America/Denver', 'America/Chicago', 'America/New_York'];
  const displayed = getTimezonesWithOffset()
    .filter((t) => !filter || t.name.toLowerCase().includes(filter.toLowerCase()))
    .sort((a, b) => {
      const aIsTop = topZones.includes(a.name);
      const bIsTop = topZones.includes(b.name);
      if (aIsTop !== bIsTop) return aIsTop ? -1 : 1;

      if (a.offset !== b.offset) return a.offset - b.offset;
      return b.name.localeCompare(a.name);
    });

  const firstNonTopIndex = displayed.findIndex((t) => !topZones.includes(t.name));

  // a11y req: return focus to trigger element
  const setFocus = (el: any) => {
   el.setAttribute('tabindex', '-1'); // required to set programmatic focus
   el.focus();
   el.removeAttribute('tabindex');
  }

  return (
    <div
      className="TimezoneSelectView"
      onClick={() => {
        setFilter('');
        setKeyFocusIdx(-1);
        setOpen(true);
      }}
    >
      <button className="item" aria-label={displayNameFor(value)} ref={timezoneSelectTrigger}>
        <Icons.Timezone />
        <div className="text">{displayNameFor(value)}</div>
      </button>
      {open && (
        <div className="menu" tabIndex={0}>
          <div className="search">
            <input
              autoFocus
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onBlur={() => setOpen(false)}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown') {
                  setKeyFocusEvent(true);
                  setKeyFocusIdx(Math.min(displayed.length - 1, keyFocusIdx + 1));
                  e.preventDefault();
                  return;
                }
                if (e.key === 'ArrowUp') {
                  setKeyFocusEvent(true);
                  setKeyFocusIdx(Math.max(0, keyFocusIdx - 1));
                  e.preventDefault();
                  return;
                }
                if (e.key === 'Escape') {
                  setOpen(false);
                  // a11y req: return focus to trigger element
                  setFocus(timezoneSelectTrigger?.current);
                  return;
                }
                if (keyFocusIdx >= 0 && (e.key === 'Enter' || e.key === 'Return')) {
                  onChange(displayed[keyFocusIdx]!.name);
                  // a11y req: return focus to trigger element
                  setFocus(timezoneSelectTrigger?.current);
                  setOpen(false);
                  e.preventDefault();
                  return;
                }
                if (keyFocusIdx === -1) {
                  setKeyFocusIdx(0);
                }
              }}
            />
          </div>
          <ul ref={listContainerRef}>
            {displayed.map((t, idx) => (
              <div key={t.name}>
                {firstNonTopIndex > 0 && firstNonTopIndex === idx && <div className="divider" />}
                <li
                  ref={idx === keyFocusIdx ? keyFocusRef : undefined}
                  className={`${idx === keyFocusIdx && 'keyfocus'}`}
                  onMouseDown={() => onChange(t.name)}
                  onMouseEnter={() => setKeyFocusIdx(idx)}
                >
                  {displayNameFor(t.name)}
                  <span className="offset">{convertOffsetToString(t.offset)}</span>
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TimezoneSelectView;
