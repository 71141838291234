import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import * as Icons from '../components/SVGIcons';
import Button from './Button';
import SVGButton from './SVGButton';
import './DropdownValuesPicker.scss';
import { clone } from '../Utils';

const DropdownValuesPicker = (props: FieldRenderProps<any>) => {
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [values, setValues] = useState((props.input.value || []) as string[]);
  const valueAlreadyExists = values.includes(currentInputValue);

  const handleAddNewValue = () => {
    if (valueAlreadyExists || currentInputValue.length === 0) return;

    values.splice(0, 0, currentInputValue);
    props.input.onChange({ target: { value: values } } as any);
    setCurrentInputValue('');
  };

  const handleRemoveValue = (index: number) => {
    const valuesCopy = clone(values);
    valuesCopy.splice(index, 1);

    props.input.onChange({ target: { value: valuesCopy } } as any);
    setValues(valuesCopy);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.key === 'Enter' || e.key === 'Return')) return;

    handleAddNewValue();
    e.preventDefault();
  };

  return (
    <div className="DropdownValuesPicker">
      <div className="addDropdownOption">
        <input
          name="addDropdownInput"
          type="text"
          value={currentInputValue}
          onChange={(e) => setCurrentInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          size="small"
          type="button"
          onClick={handleAddNewValue}
          style={{ marginLeft: 10, minWidth: 60 }}
          disabled={valueAlreadyExists || currentInputValue.length === 0}
        >
          Add
        </Button>
      </div>
      <div>
        {values.map((value, index) => (
          <div key={index} className="dropdownOption">
            {value}
            <SVGButton title="Remove" onClick={() => handleRemoveValue(index)}>
              <Icons.X width={12} height={12} />
            </SVGButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownValuesPicker;
