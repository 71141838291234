import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormWithSpy } from '../components/FormHelpers';
import { Scheduling } from '../types/Scheduling';
import { Request, ImmediateRequest } from '../components/NetworkRequest';
import { ModalDoneState, ModalErrorState, ModalNotFoundState } from './ModalCommonStates';
import { SubmitButton } from './SubmitButton';
import { BookingFormField } from './BookingFormField';
import Button from '../components/Button';
import { BookingSummary } from './BookingSummary';
import { track } from '../NylasWindowContext';
import * as Icons from '../components/SVGIcons';
import { FormattedMessage, useIntl } from 'react-intl';

interface SlotCancelViewProps {
  page: Scheduling.Page;
  editHash: string;
}

export const SlotCancelView: React.FunctionComponent<SlotCancelViewProps> = ({ page, editHash }) => {
  const intl = useIntl();
  const cancelParam = new URLSearchParams(useLocation().search).get('email');
  const emailQueryParam = !!cancelParam ? `?email=${cancelParam}` : ""

  return (
    <div className="SlotCancelView">
      <ImmediateRequest<Scheduling.Booking, {}> path={`/schedule/${page.slug}/${editHash}`} method="GET">
        {(result, makeRequest) =>
          result instanceof Error ? (
            result.status === 404 ? (
              <ModalNotFoundState slug={page.slug} />
            ) : (
              <ModalErrorState message={result.message} onClick={() => makeRequest()} buttonType="try-again" />
            )
          ) : result === 'loading' || result === 'empty' ? (
            <div className="spinner-container">
              <Icons.Spinner color="#666" width={40} height={40} />
            </div>
          ) : (
            <>
              <div className="left-panel">
                <BookingSummary start={result.start_time} end={result.end_time} />
              </div>

              <div className="divider" style={{ alignSelf: 'stretch' }} />

              <div className="right-panel">
                <Request<Scheduling.Success, Scheduling.CancelSlotPayload>
                  path={`/schedule/${page.slug}/${editHash}/cancel${emailQueryParam}`}
                  method="POST"
                >
                  {(result, sendRequest, clearRequest) => {
                    if (result instanceof Error && result.status !== 404) {
                      return <ModalErrorState message={result.message} onClick={clearRequest} buttonType="try-again" />
                    }
                    if (result === 'empty' || result === 'loading') {
                      return (
                        <FormWithSpy
                          initialValues={{ reason: '' }}
                          validate={(values) => {
                            if (!values.reason) {
                              return {
                                reason: intl.formatMessage({
                                  id: 'be-rr',
                                  defaultMessage: 'Please add a brief reason.',
                                  description: 'Booking Error - Reason required',
                                }),
                              };
                            }
                            return {};
                          }}
                          onSubmit={(payload) => {
                            track('Booking Cancelled', {});
                            sendRequest(payload);
                          }}
                        >
                          {() => (
                            <>
                              <h3 style={{ marginBottom: 15 }}>
                                <FormattedMessage
                                  defaultMessage="Are you sure?"
                                  description="Booking - Cancel Prompt"
                                  id="bcp"
                                />
                              </h3>
                              {page.config.booking.cancellation_policy && (
                                <div className="cancellation-policy">{page.config.booking.cancellation_policy}</div>
                              )}
                              <BookingFormField
                                field={{
                                  label: intl.formatMessage({
                                    id: 'b-cr',
                                    defaultMessage: 'Reason for canceling',
                                    description: 'Booking - Cancel Reason',
                                  }),
                                  name: 'reason',
                                  type: 'text',
                                  required: true,
                                }}
                              />
                              <div>
                                <SubmitButton
                                  submitting={result === 'loading'}
                                  type="submit"
                                  title={intl.formatMessage({
                                    id: 'bcebb',
                                    defaultMessage: 'Cancel Event',
                                    description: 'Booking Cancel Event Button',
                                  })}
                                />
                              </div>
                            </>
                          )}
                        </FormWithSpy>
                      );
                    }
                    return (
                      <ModalDoneState
                        title={intl.formatMessage({
                          id: 'Booking-ct',
                          description: 'Booking Complete - Title',
                          defaultMessage: 'Thank You',
                        })}
                      >
                        <div>
                          <FormattedMessage
                            defaultMessage="Your booking has been cancelled."
                            description="Booking - Cancel Complete"
                            id="bcc"
                          />
                        </div>
                        <Link to={`/${page.slug}`}>
                          <Button>
                            <FormattedMessage defaultMessage="Done" description="Button - Done" id="Button-Done" />
                          </Button>
                        </Link>
                      </ModalDoneState>
                    );
                  }}
                </Request>
              </div>
            </>
          )
        }
      </ImmediateRequest>
    </div>
  );
};
