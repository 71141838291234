import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '../components/Button';
import { Scheduling } from '../types/Scheduling';
import { until, BasePageConfig, EmbedConfig, parsePageConfigErrors } from '../Utils';
import * as Icons from '../components/SVGIcons';

import { EmbedPageContainer, EmbedPageContent, EmbedPageFooter } from './EmbedPageContainer';
import PageWizardEventAvailabilityView from './PageWizardEventAvailabilityView';
import PageWizardEventInfoView from './PageWizardEventInfoView';

import './PageWizardView.scss';
import { track } from '../NylasWindowContext';
import { defineMessage, FormattedMessage, injectIntl, IntlShape, MessageDescriptor } from 'react-intl';
import AlertBox, { AlertBoxAlerts } from '../components/AlertBox';

interface PageWizardViewProps {
  config: EmbedConfig;
  onDismiss: () => void;
  onCreatePage: (slug: string, config: Scheduling.PageConfig) => Promise<any>;
}

export interface PageWizardStepViewProps {
  config: EmbedConfig;
  pageSlug: string;
  pageConfig: Scheduling.PageConfig;
  onChange: (pageConfig: Scheduling.PageConfig, slug?: string) => void;
  setWizardError: (message: string, errors: AlertBoxAlerts[]) => void;
}
interface PageWizardViewState {
  saving: boolean;
  currentPage: number;
  pageSlug: string;
  pageConfig: Scheduling.PageConfig;
  error?: {
    message: string;
    errors: AlertBoxAlerts[];
  };
}

interface WizardPageConfig {
  header: MessageDescriptor;
  Component: React.ComponentType<PageWizardStepViewProps>;
}

const wizardPages: WizardPageConfig[] = [
  {
    Component: PageWizardEventInfoView,
    header: defineMessage({
      id: 'Settings-tl-ei',
      description: 'Settings - Tab Labels - Event Info',
      defaultMessage: 'Event Info',
    }),
  },
  {
    Component: PageWizardEventAvailabilityView,
    header: defineMessage({
      id: 'Settings-tl-a',
      defaultMessage: 'Availability',
      description: 'Settings - Tab Labels - Availability',
    }),
  },
];

function mergeConfig(base: { [key: string]: any }, defaults: { [key: string]: any } = {}) {
  const next = { ...base };

  // string or array keys override the base configuration entirely.
  // object keys are merged recursively.
  for (const key of Object.keys(defaults)) {
    if (typeof defaults[key] === 'object' && !(defaults[key] instanceof Array)) {
      next[key] = mergeConfig(next[key], defaults[key]);
    } else {
      next[key] = defaults[key];
    }
  }
  return next;
}

class PageWizardViewBase extends Component<PageWizardViewProps & { intl: IntlShape }, PageWizardViewState> {
  _mounted = false;

  constructor(props: PageWizardViewProps & { intl: IntlShape }) {
    super(props);

    const intl = props.intl;

    let pageConfig: any = {
      ...BasePageConfig,
    };

    pageConfig = mergeConfig(pageConfig, {
      locale: intl.locale,
      event: {
        location: intl.formatMessage({
          id: 'pd-el',
          defaultMessage: 'Location TBD',
          description: 'Page Defaults - Event Location',
        }),
        title: intl.formatMessage({
          id: 'pd-en',
          defaultMessage: 'Meeting',
          description: 'Page Defaults - Event Title',
        }),
        participants: [
          {
            // I don't see why token would be undefined
            // they can't access this page without a token anyways
            email: this.props.config.token!.account_email,
            role: 'organizer',
          },
        ],
      },
      appearance: {
        submit_text: intl.formatMessage({
          id: 'Button-Submit',
          defaultMessage: 'Submit',
          description: 'Button - Submit',
        }),
      },
    });

    pageConfig = mergeConfig(pageConfig, props.config.defaults);

    this.state = {
      saving: false,
      currentPage: 0,
      pageSlug: '',
      pageConfig: pageConfig as Scheduling.PageConfig,
    };
  }

  componentDidMount() {
    this._mounted = true;
    track('Add Page Clicked', {});
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleBackButtonClicked = async () => {
    let { currentPage } = this.state;

    this.setState({ currentPage: currentPage - 1 });
  };

  handleCreateButtonClicked = async () => {
    const { pageSlug, pageConfig } = this.state;
    let { currentPage } = this.state;

    if (currentPage === wizardPages.length - 1) {
      const start = Date.now();
      this.setState({ saving: true });
      const response = await this.props.onCreatePage(pageSlug, pageConfig);
      if (response) {
        this.setWizardError('Sorry, we were unable to create the scheduling page.', [
          { message: parsePageConfigErrors(response, pageConfig) },
        ]);
      }
      await until({ timeSince: start, greaterThan: 400 });
      if (!this._mounted) return;
      this.setState({ saving: false });
    } else {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handleChange = (pageConfig: Scheduling.PageConfig, pageSlug: string = this.state.pageSlug) => {
    this.setState({ pageConfig, pageSlug });
  };

  handleWizardBreadcrumbClicked = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    this.setState({ currentPage: index });
  };

  setWizardError = (message: string, errors: AlertBoxAlerts[] = []) => {
    this.setState({ error: { message, errors } });
  };

  dismissErrors = () => {
    this.setState({ error: undefined });
  };

  renderBreadcrumbs = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="breadcrumbs">
          <Link to="/embed">
            <FormattedMessage
              defaultMessage="Scheduling Pages"
              description="Settings - Back to Pages Label"
              id="Settings-btpl"
            />
          </Link>
          <Icons.ArrowRight width="12" height="12" style={{ padding: `0 10px` }} />
        </div>
        {wizardPages.slice(0, this.state.currentPage).map((p: WizardPageConfig, index: number) => (
          <div key={index} className="breadcrumbs">
            <a
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                this.handleWizardBreadcrumbClicked(e, index);
              }}
            >
              <FormattedMessage {...p.header} />
            </a>
            <Icons.ArrowRight width="12" height="12" style={{ padding: `0 10px` }} />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { onDismiss, config } = this.props;
    const { currentPage, pageConfig, pageSlug, saving } = this.state;
    const { header, Component } = wizardPages[currentPage];

    return (
      <EmbedPageContainer
        onDismiss={onDismiss}
        className="PageWizardView"
        breadcrumb={this.renderBreadcrumbs()}
        headerName={config.style.modalTitle || <FormattedMessage {...header} />}
      >
        <EmbedPageContent>
          <div className="main">
            <Component
              config={config}
              pageSlug={pageSlug}
              pageConfig={pageConfig}
              onChange={this.handleChange}
              setWizardError={this.setWizardError}
            />
            {this.state.error && (
              <AlertBox
                alerts={this.state.error.errors}
                message={this.state.error.message}
                type="error"
                onClickDismiss={this.dismissErrors}
              />
            )}
          </div>
        </EmbedPageContent>
        <EmbedPageFooter>
          {currentPage === 1 && (
            <Button className={`save`} intent="default" onClick={this.handleBackButtonClicked}>
              <FormattedMessage defaultMessage="Back" id="Button-Back" description="Button-Back" />
            </Button>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }} />
          <div style={{ flex: 1 }} />
          {saving ? (
            <Button className="save" intent="default" disabled>
              <Icons.Spinner width={15} height={15} style={{ marginRight: 7 }} />
              <FormattedMessage
                defaultMessage="Creating..."
                id="Settings-wsl"
                description="Settings-Wizard-Saving-Label"
              />
            </Button>
          ) : (
            <Button
              className={`save`}
              intent="default"
              onClick={this.handleCreateButtonClicked}
              disabled={pageConfig.hasFormErrors}
            >
              {currentPage === wizardPages.length - 1 ? (
                <FormattedMessage defaultMessage="Create" id="Button-Create" description="Button-Create" />
              ) : (
                <FormattedMessage defaultMessage="Next" id="Button-Next" description="Button-Next" />
              )}
            </Button>
          )}
        </EmbedPageFooter>
      </EmbedPageContainer>
    );
  }
}

export const PageWizardView = injectIntl(PageWizardViewBase);
