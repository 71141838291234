import React from 'react';
import CalendarWeekView from './CalendarWeekView';
import CalendarMonthView from './CalendarMonthView';
import { RequestState } from '../components/NetworkRequest';
import { Scheduling } from '../types/Scheduling';
import { ViewType } from './MainView';

import { assertUnreachable } from '../Utils';
import { getTimezoneAdjusted } from '../DateUtils';
import { track } from '../NylasWindowContext';
import { useIntl } from 'react-intl';

interface CalendarViewProps {
  viewType: ViewType;
  showAutoschedule: boolean;
  guestAvailability: RequestState<Scheduling.GuestAvailabilityResponse>;
  slots: RequestState<Scheduling.TimeSlot[]>;
  onSlotClicked: (slot: Scheduling.TimeSlot) => Promise<void>;
  timezoneOverride?: string;
}

export const CalendarView: React.FunctionComponent<CalendarViewProps> = ({
  slots,
  viewType,
  showAutoschedule,
  guestAvailability,
  onSlotClicked,
  timezoneOverride,
}) => {
  const intl = useIntl();

  if (!(slots instanceof Array || slots === 'loading')) {
    return <span />;
  }

  const slotsArray = (slots === 'loading' ? [] : slots) as Scheduling.TimeSlot[];
  const timezoneAdjustedSlots = getTimezoneAdjusted(slotsArray, timezoneOverride);
  const onTZSlotClicked = async (timezoneAdjustedSlot: Scheduling.TimeSlot) => {
    track('Timeslot Selected', {});
    await onSlotClicked(slotsArray[timezoneAdjustedSlots.indexOf(timezoneAdjustedSlot)]);
  };

  switch (viewType) {
    case 'Default':
      return (
        <CalendarMonthView
          key={intl.locale}
          showAutoschedule={showAutoschedule}
          slots={timezoneAdjustedSlots}
          onSlotClicked={onTZSlotClicked}
          ownEvents={
            guestAvailability instanceof Object && 'busy' in guestAvailability
              ? getTimezoneAdjusted(guestAvailability.busy)
              : guestAvailability
          }
          loading={slots === 'loading'}
        />
      );
    case 'Calendar':
      return (
        <CalendarWeekView
          key={intl.locale}
          slots={timezoneAdjustedSlots}
          onSlotClicked={onTZSlotClicked}
          loading={slots === 'loading'}
        />
      );
    default:
      assertUnreachable(viewType);
  }
};
