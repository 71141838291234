import React, { Component } from 'react';
import * as Icons from '../components/SVGIcons';
import Button from '../components/Button';
import { Scheduling } from '../types/Scheduling';
import { clone, DefaultReminder } from '../Utils';
import { ReminderEditSheet } from './ReminderEditSheet';
import { TabComponentProps } from './PageDetailView';
import './RemindersView.scss';
import SVGButton from '../components/SVGButton';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

interface RemindersViewState {
  popoverReminder?: Scheduling.PageConfigReminder;
}

export class RemindersViewBase extends Component<TabComponentProps & { intl: IntlShape }, RemindersViewState> {
  state: RemindersViewState = { popoverReminder: undefined };

  handleCreateReminder = (reminder: Scheduling.PageConfigReminder) => {
    const page = clone(this.props.page);
    page.config.reminders.push(reminder);

    this.setState({ popoverReminder: undefined });
    this.props.onChange(page);
  };

  handleEditReminder = (reminder: Scheduling.PageConfigReminder) => {
    this.setState({ popoverReminder: reminder });
  };

  handleDeleteReminder = (reminder: Scheduling.PageConfigReminder) => {
    const idx = this.props.page.config.reminders.indexOf(reminder);
    const page = clone(this.props.page);
    page.config.reminders.splice(idx, 1);
    this.props.onChange(page);
  };

  render() {
    const { page, intl } = this.props;
    const { confirmation_emails_to_host, confirmation_emails_to_guests } = page.config.booking;

    const displayNameFor = (recipient: 'owner' | 'customer' | 'both') => {
      return (
        {
          owner: intl.formatMessage({ defaultMessage: 'host', id: 'rr-h', description: 'Reminder Recipient - Host' }),
          customer: intl.formatMessage({
            id: 'rr-g',
            defaultMessage: 'guests',
            description: 'Reminder Recipient - Guests',
          }),
          both: intl.formatMessage({
            id: 'rr-e',
            defaultMessage: 'everyone',
            description: 'Reminder Recipient - Everyone',
          }),
        }[recipient] || recipient
      );
    };

    const reminderSettingsLabel = (reminder: Scheduling.PageConfigReminder) =>
      reminder.delivery_method === 'email'
        ? intl.formatMessage(
            {
              id: 'rs-e',
              defaultMessage: 'Email {recipient} with subject "{subject}"',
              description: 'Reminder Summary - Email',
            },
            {
              recipient: displayNameFor(reminder.delivery_recipient),
              subject: reminder.email_subject,
            },
          )
        : intl.formatMessage(
            {
              id: 'rs-w',
              defaultMessage: 'Post to {webhook_url}',
              description: 'Reminder Summary - Webhook',
            },
            { webhook_url: reminder.webhook_url },
          );

    const reminderTimeTilLabel = (reminder: Scheduling.PageConfigReminder) => {
      if (reminder.time_before_event < 60) {
        return intl.formatMessage(
          {
            id: 'rtbe-ms',
            defaultMessage: '{minutes_before_event} min before the event',
            description: 'Reminder Time Before Event - Minutes',
          },
          { minutes_before_event: reminder.time_before_event },
        );
      }
      return reminder.time_before_event / 60 === 1
        ? intl.formatMessage(
            {
              id: 'rtbe-h',
              defaultMessage: '{hours_before_event} hour before the event',
              description: 'Reminder Time Before Event - Hour',
            },
            { hours_before_event: reminder.time_before_event / 60 },
          )
        : intl.formatMessage(
            {
              id: 'rtbe-hs',
              defaultMessage: '{hours_before_event} hours before the event',
              description: 'Reminder Time Before Event - Hours',
            },
            { hours_before_event: reminder.time_before_event / 60 },
          );
    };

    return (
      <div className="RemindersView">
        <h2>
          <FormattedMessage
            id="Settings-rcep"
            defaultMessage="How should new bookings and cancellations be communicated?"
            description="Settings - Reminders - Confirmation emails - prompt"
          />
        </h2>

        <label htmlFor="confirmation_emails_to_host" className="checkbox-container">
          <input
            id="confirmation_emails_to_host"
            type="checkbox"
            checked={confirmation_emails_to_host !== false}
            onChange={(e) => {
              this.props.onChange({
                ...page,
                config: {
                  ...page.config,
                  booking: { ...page.config.booking, confirmation_emails_to_host: e.target.checked },
                },
              });
            }}
          />
          <FormattedMessage
            id="Settings-rceem"
            defaultMessage="Send me a confirmation email"
            description="Settings - Reminders - Confirmation emails - Email Me"
          />
        </label>

        <label htmlFor="confirmation_emails_to_guests" className="checkbox-container">
          <input
            id="confirmation_emails_to_guests"
            type="checkbox"
            checked={confirmation_emails_to_guests !== false}
            onChange={(e) => {
              this.props.onChange({
                ...page,
                config: {
                  ...page.config,
                  booking: { ...page.config.booking, confirmation_emails_to_guests: e.target.checked },
                },
              });
            }}
          />
          <FormattedMessage
            id="Settings-rceeg"
            defaultMessage="Send guests a confirmation email"
            description="Settings - Reminders - Confirmation emails - Email Guests"
          />
        </label>

        <h2>
          <FormattedMessage
            id="Settings-rrp"
            defaultMessage="How should everyone be reminded of upcoming bookings?"
            description="Settings - Reminders - Reminders Prompt"
          />
        </h2>
        <div className="reminders">
          {this.props.page.config.reminders
            .filter((r) => r.delivery_method === 'email')
            .map((r, idx) => (
              <div key={idx} className="reminder">
                <div>
                  {reminderSettingsLabel(r)}
                  <div style={{ color: 'gray' }}>{reminderTimeTilLabel(r)}</div>
                </div>
                <div style={{ flex: 1 }} />
                <SVGButton title="Edit" onClick={() => this.handleEditReminder(r)}>
                  <Icons.Edit width={15} height={15} />
                </SVGButton>
                <SVGButton title="Remove" onClick={() => this.handleDeleteReminder(r)}>
                  <Icons.X width={12} height={12} />
                </SVGButton>
              </div>
            ))}
        </div>

        <Button
          size="small"
          onClick={() =>
            this.setState({
              popoverReminder: DefaultReminder,
            })
          }
        >
          <FormattedMessage
            id="Settings-rar"
            defaultMessage="Add Reminder"
            description="Settings - Reminders - Add Reminder"
          />
        </Button>

        {this.state.popoverReminder && (
          <ReminderEditSheet
            reminder={this.state.popoverReminder}
            config={this.props.page.config}
            onCreate={this.handleCreateReminder}
            onCancel={() => this.setState({ popoverReminder: undefined })}
          />
        )}
      </div>
    );
  }
}

const RemindersView = injectIntl(RemindersViewBase);

export default RemindersView;
