import { Scheduling } from '../types/Scheduling';
import qs from 'query-string';

function addParamsToBaseURL(baseURL: string, params: { [key: string]: any }) {
  const sep = baseURL.includes('?') ? '&' : '?';
  let fullURL = `${baseURL}${sep}${qs.stringify(params)}`;
  if (!fullURL.startsWith('http')) {
    fullURL = `https://${fullURL}`;
  }
  return fullURL;
}

export function buildThankYouRedirect(args: {
  url: string;
  slug: string;
  appProvidedQuerystring: string;
  booking: Scheduling.Booking;
  changeUrlParameter?: boolean;
}) {
  const { url, slug, booking, appProvidedQuerystring } = args;

  const paramsObj: any = {
    ...qs.parse(appProvidedQuerystring),
    start_time: booking.start_time,
    end_time: booking.end_time,
    page_slug: slug,
    tz: booking.recipient_tz,
    email: booking.recipient_email,
    location: booking.location,
    edit_hash: booking.edit_hash,
    event_id: booking.calendar_event_id,
    calendar_id: booking.calendar_id,
    account_id: booking.account_id,
    additional_values: JSON.stringify(booking.additional_field_values),
  }

  if (args.changeUrlParameter) {
    paramsObj.participant_name = booking.recipient_name
  } else {
    paramsObj.name = booking.recipient_name;
  }

  return addParamsToBaseURL(url, paramsObj);
}

export function buildThankYouRedirectForExternalBooking(args: {
  url: string;
  slug: string;
  appProvidedQuerystring: string;
  slot: Scheduling.TimeSlot;
}) {
  const { url, slug, slot, appProvidedQuerystring } = args;

  return addParamsToBaseURL(url, {
    ...qs.parse(appProvidedQuerystring),
    start_time: slot.start,
    end_time: slot.end,
    page_slug: slug,
    calendar_id: slot.calendar_id,
    account_id: slot.account_id,
  });
}
