import React from 'react';

import { getTimezoneAdjusted, getWeekday, getLongDateString, getHHMMString, getCurrentTimezone } from '../DateUtils';

interface BookingSummaryProps {
  start: number;
  end: number;
  host_name?: string;
}

export const BookingSummary = ({ start, end, host_name }: BookingSummaryProps) => {
  const [local] = getTimezoneAdjusted([{ start, end }]);
  const tz = getCurrentTimezone();

  const startDate = new Date(local.start * 1000);
  const endDate = new Date(local.end * 1000);
  let dateString = `${getWeekday(startDate)}\n${getLongDateString(startDate)}`;
  const timeRangeString = `${getHHMMString(startDate)} - ${getHHMMString(endDate)}`;

  // Not all languages naturally capitalize dates but the start of the sentence should be
  dateString = dateString.slice(0, 1).toUpperCase() + dateString.slice(1);

  return (
    <div className="BookingSummary">
      <h2>{dateString}</h2>
      <h4>{`${timeRangeString}${host_name ? `\n${host_name}` : ''}`}</h4>
      <p style={{ opacity: 0.6 }}>{tz.replace(/_/g, ' ')}</p>
    </div>
  );
};
