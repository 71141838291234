import React from 'react';
import { Scheduling } from '../types/Scheduling';
import { CalendarAdvancedSheet } from './CalendarAdvancedSheet';
import { TabComponentProps } from './PageDetailView';
import * as Icons from '../components/SVGIcons';
import RadioGroup from '../components/RadioGroup';

import './CalendarsView.scss';
import { RequestForAccountsAndCalendars } from './RequestForAccountsAndCalendars';
import SVGButton from '../components/SVGButton';
import { FormWithSpy } from '../components/FormHelpers';
import { clone } from '../Utils';
import { FormattedMessage, useIntl } from 'react-intl';

type AccountCalendarIds = Scheduling.PageConfigAccountCalendarIDs;

const CalendarsView: React.FunctionComponent<TabComponentProps> = ({ page, onChange }) => {
  const intl = useIntl();
  const [expandedAccountId, setExpandedAccountId] = React.useState<string>();
  const handleFormChange = (values: any) => {
    const newPage = clone(page);
    newPage.config.booking.scheduling_method = values.scheduling_method;
    onChange(newPage);
  };

  const handleAccountCalsChange = (aid: string, ids: AccountCalendarIds) => {
    const newPage = clone(page);
    newPage.config.calendar_ids = { ...newPage.config.calendar_ids, [aid]: ids };
    onChange(newPage);
  };

  const numberOfAccounts = Object.keys(page.config.calendar_ids).length;

  return (
    <div className="CalendarsView">
      <h2>
        <FormattedMessage
          defaultMessage="Which calendar should be used for availability and booking?"
          description="Settings - Booking - Calendars Prompt"
          id="Settings-bcpp"
        />
      </h2>
      <RequestForAccountsAndCalendars pageId={page.id}>
        {(calsByAccount) => (
          <div>
            {calsByAccount.map((a) => (
              <CalendarInputForAccount
                key={a.name}
                account={a}
                value={page.config.calendar_ids[a.id] || null}
                onChange={(calIds) => handleAccountCalsChange(a.id, calIds)}
                onSelectAdvanced={() => setExpandedAccountId(a.id)}
              />
            ))}
            {expandedAccountId && (
              <CalendarAdvancedSheet
                account={calsByAccount.find((a) => a.id === expandedAccountId)!}
                value={page.config.calendar_ids[expandedAccountId]}
                onChange={(calIds) => handleAccountCalsChange(expandedAccountId, calIds)}
                onDismiss={() => setExpandedAccountId(undefined)}
              />
            )}
          </div>
        )}
      </RequestForAccountsAndCalendars>

      {numberOfAccounts > 1 && (
        <>
          <div style={{ height: 30 }} />
          <h2>
            <FormattedMessage
              defaultMessage="How should bookings be assigned?"
              description="Settings - Booking - Assignment - Prompt"
              id="Settings-bap"
            />
          </h2>
          <FormWithSpy
            onChange={handleFormChange}
            onSubmit={() => {}}
            initialValues={{
              scheduling_method: page.config.booking.scheduling_method,
            }}
          >
            {({ handleSubmit }) => (
              <RadioGroup<Scheduling.PageConfigBooking['scheduling_method']>
                inputName="scheduling_method"
                options={[
                  {
                    label: intl.formatMessage({
                      id: 'Settings-smrr',
                      defaultMessage: 'Round Robin',
                      description: 'Settings - Scheduling Method - Round Robin',
                    }),
                    value: 'round-robin-maximize-fairness',
                    description: intl.formatMessage({
                      id: 'Settings-smrrd',
                      defaultMessage: 'Meeting slots are determined so each calendar gets an equal number of bookings.',
                      description: 'Settings - Scheduling Method - Round Robin Description',
                    }),
                  },
                  {
                    label: intl.formatMessage({
                      id: 'Settings-smma',
                      defaultMessage: 'Maximize Availability',
                      description: 'Settings - Scheduling Method - Maximize Availability',
                    }),
                    value: 'round-robin-maximize-availability',
                    description: intl.formatMessage({
                      id: 'Settings-smmad',
                      defaultMessage: 'Meetings may be booked freely, some calendars may get more events than others.',
                      description: 'Settings - Scheduling Method - Maximize Availability Description',
                    }),
                  },
                ]}
              />
            )}
          </FormWithSpy>
        </>
      )}
    </div>
  );
};

const CalendarInputForAccount: React.FunctionComponent<{
  account: Scheduling.PageConfigCalendars;
  value: AccountCalendarIds | null;
  onChange: (ids: AccountCalendarIds) => void;
  onSelectAdvanced: () => void;
}> = ({ account, value, onChange, onSelectAdvanced }) => {
  const intl = useIntl();

  // It's technically possible for there to be no config in the page that matches this account,
  // in which case we receive null as a value. Immediately select something before showing the UI
  React.useEffect(() => {
    if (!value && account.calendars.length) {
      onChange({ availability: [account.calendars[0].id], booking: account.calendars[0].id });
    }
  }, [onChange, account, value]);

  if (!value) {
    return <span />;
  }
  // Only show a selection if there's one availability calendar and it's the same as the booking
  // calendar. Otherwise, we want to show the "Advanced" option as the current selection.
  const { booking: bookingId, availability: availabilityIDs } = value;
  const onlyId = availabilityIDs.length === 1 && bookingId === availabilityIDs[0] ? bookingId : 'ADVANCED';
  const isAdvanced = availabilityIDs.length > 1;
  const writableCalendars = account.calendars.filter((c) => !c.read_only);
  const bookingCalendar = account.calendars.find((a) => a.id === bookingId);
  const availableCalendars = account.calendars.filter((a) => availabilityIDs.includes(a.id));

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <label htmlFor={`select-${account.id}`}>{`${account.email}:`}</label>
        </div>
        <div style={{ display: 'flex' }}>
          <select
            value={onlyId}
            id={`select-${account.id}`}
            style={{ width: 270, textOverflow: 'ellipsis' }}
            onChange={(e) => {
              if (e.target.value === 'ADVANCED') {
                onSelectAdvanced();
              } else {
                onChange({ booking: e.target.value, availability: [e.target.value] });
              }
            }}
          >
            {writableCalendars.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
            {writableCalendars.length === 0 && (
              <option disabled>
                {intl.formatMessage({
                  id: 'All calendars are read-only',
                  description: 'All calendars are read-only',
                  defaultMessage: 'All calendars are read-only',
                })}
              </option>
            )}
            {writableCalendars.length > 0 && account.calendars.length > 1 && (
              <>
                <option disabled>–––––––––––––––––––</option>
                <option value="ADVANCED">
                  {intl.formatMessage({ description: 'Advanced', id: 'Advanced...', defaultMessage: 'Advanced...' })}
                </option>
              </>
            )}
          </select>
          {isAdvanced && (
            <SVGButton title="Advanced" onClick={() => onSelectAdvanced()} style={{ marginLeft: 10 }}>
              <Icons.TabCustomFields width={15} height={15} />
            </SVGButton>
          )}
        </div>
      </div>
      {isAdvanced && (
        <div style={{ marginTop: 5, paddingLeft: 4, borderLeft: `2px solid #aaa` }}>
          <div style={{ color: 'rgb(88, 88, 88)', opacity: 0.6, marginBottom: 3 }}>
            <FormattedMessage
              description="Settings - Booking - Calendar Summary"
              id="Settings-bcss"
              defaultMessage="Booking: {calendar_name}"
              values={{ calendar_name: bookingCalendar ? bookingCalendar.name : 'None' }}
            />
          </div>
          <div style={{ color: 'rgb(88, 88, 88)', opacity: 0.6 }}>
            <FormattedMessage
              description="Settings - Availability - Calendar Summary"
              id="Settings-acss"
              defaultMessage="Availability: {calendar_names}"
              values={{ calendar_names: (availableCalendars || []).map((c) => c.name).join(', ') || 'None' }}
            />
          </div>
        </div>
      )}
      <div />
    </div>
  );
};

export default CalendarsView;
