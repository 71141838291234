import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../components/Button';

import './CustomAlertProvider.scss';

const CURRENT_ALERT_CHANGED = 'alert-changed';

interface ICustomAlert {
  body: React.ReactNode | string;
  callback: (confirmed: boolean) => void;
}

let CurrentAlert: ICustomAlert | null = null;

const setCustomAlert = (alert: ICustomAlert | null) => {
  CurrentAlert = alert;
  document.dispatchEvent(new CustomEvent(CURRENT_ALERT_CHANGED));
};

export const showCustomConfirm = (opts: ICustomAlert) => {
  setCustomAlert(opts);
};

export class CustomAlertProvider extends React.Component {
  state = { alert: CurrentAlert };

  bodyRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener(CURRENT_ALERT_CHANGED, () => this.setState({ alert: CurrentAlert }));
  }

  render() {
    const alert = this.state.alert;

    if (!alert) {
      return <span />;
    }
    return (
      <>
        <div className="alert-background" />
        <div
          className="alert"
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              alert.callback(false);
              setCustomAlert(null);
            }
          }}
        >
          <div className="alert-padded">{alert.body}</div>

          <div className="alert-padded alert-actions">
            <Button
              tabIndex={1}
              intent="outlined"
              size="small"
              onClick={() => {
                alert.callback(false);
                setCustomAlert(null);
              }}
            >
              <FormattedMessage defaultMessage="Cancel" description="Confirm - Cancel" id="cancel" />
            </Button>
            <div style={{ width: 15 }} />
            <Button
              tabIndex={2}
              autoFocus
              intent="primary"
              size="small"
              onClick={() => {
                alert.callback(true);
                setCustomAlert(null);
              }}
            >
              <FormattedMessage defaultMessage="Ok" description="Confirm - Ok" id="ok" />
            </Button>
          </div>
        </div>
      </>
    );
  }
}
