import React from 'react';

// React Context used to inject API globals. This allows us to avoid passing "apiBaseURL"
// everywhere we make a network request.

interface IRequestContext {
  apiBaseURL: string;
  apiAuthToken: string;
}

const DEFAULT_INVALID_CONTEXT: IRequestContext = { apiBaseURL: '', apiAuthToken: '' };

export const RequestContext = React.createContext<IRequestContext>(DEFAULT_INVALID_CONTEXT);

// Low level wrapper around fetch for making network requests

export async function makeRequest<T>(
  context: IRequestContext,
  path: string,
  method = 'GET',
  itemPayload: any = null,
): Promise<T> {
  const options: any = {
    method,
    withCredentials: true,
    credentials: 'include',
    headers: {
      Authorization: `Token ${context.apiAuthToken}`,
    },
  };

  if (itemPayload) {
    options.body = JSON.stringify(itemPayload);
    options.headers['content-type'] = 'application/json';
  }
  const response = await fetch(`${context.apiBaseURL}${path}`, options);
  const text = await response.text();

  try {
    const json = JSON.parse(text);

    if (response.status < 200 || response.status >= 300) {
      const message = (json && json.message) || `Invalid Response Code: ${response.status}`;
      const error = new Error(message) as NetworkError;
      error.status = response.status;
      throw error;
    }

    return json as T;
  } catch (err) {
    if (err instanceof SyntaxError) {
      /*
       * When the error was caused by parsing json response
       */
      throw new Error(`Invalid Response JSON: ${text}`);
    }
    else {
      throw new Error(text);
    }
  }
}

interface NetworkError extends Error {
  status: number;
}

// React component modeled after Apollo Client's "<Mutation />" and "<Query />" components
// that allows us to make network requests and forces you to handle loading / error responses.
// https://www.apollographql.com/docs/react/essentials/mutations/
export type RequestState<T> = 'empty' | 'loading' | NetworkError | T;

interface RequestProps<T, U> {
  path: string;
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';

  __immediate?: boolean;
  __immediatePayload?: U;

  children: (
    result: RequestState<T>,
    perform: (payload?: U) => Promise<T | NetworkError>,
    clear: () => void,
  ) => React.ReactElement<any>;
}

export function Request<T, U>(props: RequestProps<T, U>) {
  const { path, method, children, __immediate, __immediatePayload } = props;
  const context = React.useContext(RequestContext);
  const [status, setStatus] = React.useState<RequestState<T>>(__immediate ? 'loading' : 'empty');

  const clear = () => {
    setStatus('empty');
  };

  const perform = async (payload?: U) => {
    // In general if we have an error state and you click to make the request again,
    // we want it to look like it /did/ something. Ensure we sit in the loading state
    // for at least 500ms when making a request after an error for max user satisfaction.
    const start = Date.now();
    const minLoadingTime = status instanceof Error ? 500 : 0;
    const setStatusAfterTime = (status: NetworkError | T) => {
      setTimeout(() => setStatus(status), Math.max(0, minLoadingTime - (Date.now() - start)));
    };

    if (status !== 'loading') {
      setStatus('loading');
    }

    let resp;
    try {
      resp = await makeRequest<T>(context, path, method || 'GET', payload);
    } catch (err) {
      setStatusAfterTime(err as NetworkError);
      return err as NetworkError;
    }

    setStatusAfterTime(resp);
    return resp;
  };

  React.useEffect(() => {
    if (__immediate) perform(__immediatePayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, method]);

  return children(status, perform, clear);
}

interface ImmediateRequestProps<T, U> {
  path: string;
  payload?: U;
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';

  children: (
    result: RequestState<T>,
    perform: (payload?: U) => Promise<T | Error>,
    clear: () => void,
  ) => React.ReactElement<any>;
}

export function ImmediateRequest<T, U>({ children, payload, ...rest }: ImmediateRequestProps<T, U>) {
  return (
    <Request<T, U> {...rest} __immediate={true} __immediatePayload={payload}>
      {children as any}
    </Request>
  );
}
