import React from 'react';
import './SVGButton.scss';

interface SVGButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const SVGButton: React.FunctionComponent<SVGButtonProps> = ({
  className,
  children,
  onClick,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      tabIndex={disabled ? -1 : 0}
      className={`SVGButton ${className ? className : ''} ${disabled && 'disabled'} `}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SVGButton;
