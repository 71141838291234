import React, { Component } from 'react';
import { NavLink, Redirect, Route, Link, Switch } from 'react-router-dom';

import Button from '../components/Button';
import { Scheduling } from '../types/Scheduling';
import { clone, until, EmbedConfig } from '../Utils';
import * as Icons from '../components/SVGIcons';

import { EmbedPageContainer, EmbedPageContent, EmbedPageFooter } from './EmbedPageContainer';
import EventInfoView from './EventInfoView';
import OpeningHoursView from './OpeningHoursView';
import CustomFieldsView from './CustomFieldsView';
import BookingFlowView from './BookingFlowView';
import CalendarsView from './CalendarsView';
import RemindersView from './RemindersView';
import WidgetView from './WidgetView';

import './PageDetailView.scss';
import { defineMessage, FormattedMessage, injectIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { showCustomConfirm } from './CustomAlertProvider';

export interface TabComponentProps {
  page: Scheduling.Page;
  config: EmbedConfig;
  onChange: (page: Scheduling.Page) => void;
}

interface Tab {
  icon: React.ComponentType<any>;
  label: MessageDescriptor;
  path: string;
  component: React.ComponentType<TabComponentProps>;
}

const Tabs: Tab[] = [
  {
    icon: Icons.TabEventInfo,
    label: defineMessage({
      id: 'Settings-tl-ei',
      description: 'Settings - Tab Labels - Event Info',
      defaultMessage: 'Event Info',
    }),
    path: 'event-info',
    component: EventInfoView,
  },
  {
    icon: Icons.TabCalendars,
    label: defineMessage({
      id: 'Settings-tl-c',
      description: 'Settings - Tab Labels - Calendars',
      defaultMessage: 'Calendars',
    }),
    path: 'calendars',
    component: CalendarsView,
  },
  {
    icon: Icons.TabOpeningHours,
    label: defineMessage({
      id: 'Settings-tl-oh',
      description: 'Settings - Tab Labels - Opening Hours',
      defaultMessage: 'Opening Hours',
    }),
    path: 'opening-hours',
    component: OpeningHoursView,
  },
  {
    icon: Icons.TabBookingFlow,
    label: defineMessage({
      id: 'Settings-tl-bf',
      description: 'Settings - Tab Labels - Booking Flow',
      defaultMessage: 'Booking Flow',
    }),
    path: 'booking-flow',
    component: BookingFlowView,
  },
  {
    icon: Icons.TabReminders,
    label: defineMessage({
      id: 'Settings-tl-r',
      description: 'Settings - Tab Labels - Reminders',
      defaultMessage: 'Reminders',
    }),
    path: 'reminders',
    component: RemindersView,
  },
  {
    icon: Icons.TabCustomFields,
    label: defineMessage({
      id: 'Settings-tl-cf',
      description: 'Settings - Tab Labels - Custom Fields',
      defaultMessage: 'Custom Fields',
    }),
    path: 'custom-fields',
    component: CustomFieldsView,
  },
  {
    icon: Icons.TabAppearance,
    label: defineMessage({
      id: 'Settings-tl-ps',
      description: 'Settings - Tab Labels - Page Styles',
      defaultMessage: 'Page Styles',
    }),
    path: 'page-styles',
    component: WidgetView,
  },
];

interface PageDetailViewProps {
  allowBack: boolean;
  config: EmbedConfig;
  initialPage: Scheduling.Page;
  onDismiss: () => void;
  onBack: () => void;
  onDelete?: () => void;
  onSave: (page: Scheduling.Page) => Promise<any>;
}

interface PageDetailViewState {
  page: Scheduling.Page;
  saving: boolean;
}

class PageDetailViewBase extends Component<PageDetailViewProps & { intl: IntlShape }, PageDetailViewState> {
  _mounted = false;

  constructor(props: any) {
    super(props);

    this.saveListener = this.saveListener.bind(this);

    // Deep copy the page so we can have an initial and working copy.
    this.state = { page: clone(props.initialPage), saving: false };
  }

  saveListener = (e: MessageEvent) => {
    if (e.data && e.data.type === 'nylas:save-request') {
      this.props.onSave(this.state.page);
    }
  };

  componentDidMount() {
    this._mounted = true;
    window.addEventListener('message', this.saveListener);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.saveListener);
    this._mounted = false;
  }

  handlePageUpdate = (page: Scheduling.Page) => {
    this.setState({ page });
  };

  handleSaveButtonClicked = async () => {
    this.setState({ saving: true });
    const start = Date.now();
    await this.props.onSave(this.state.page);
    await until({ timeSince: start, greaterThan: 400 });
    if (!this._mounted) return;
    this.setState({ saving: false });
  };

  render() {
    const { initialPage, allowBack, onDismiss, onDelete, onBack, config, intl } = this.props;
    const { page } = this.state;
    const pristine = JSON.stringify(page) === JSON.stringify(initialPage);

    function areYouSure(onConfirmed: () => void) {
      if (pristine) {
        onConfirmed();
        return;
      }
      showCustomConfirm({
        body: intl.formatMessage({
          id: 'Settings-pcws?',
          description: 'Settings - Prompt - Close without saving?',
          defaultMessage: 'Are you sure you want to exit without saving changes to this page?',
        }),
        callback: (confirmed) => confirmed && onConfirmed(),
      });
    }

    const AllowedTabIDs = config.behavior && (config.behavior.displayOnly as string[]);
    let AllowedTabs = AllowedTabIDs ? Tabs.filter((t) => AllowedTabIDs.includes(t.path)) : Tabs;

    // If you choose "external" confirmation and management of bookings, these features are
    // irrelevant so we hide them to avoid confusion.
    if (page.config.booking.confirmation_method === 'external') {
      AllowedTabs = AllowedTabs.filter((t) => !['booking-flow', 'reminders', 'custom-fields'].includes(t.path));
    }
    return (
      <EmbedPageContainer
        onDismiss={() => areYouSure(onDismiss)}
        className="PageDetailView"
        headerHiddenOnMobile={AllowedTabs.length === 1 && !allowBack && !config.style.modalTitle}
        headerName={config.style.modalTitle || this.state.page.config.event.title}
        breadcrumb={
          allowBack &&
          !config.style.modalTitle && (
            <div className="breadcrumbs">
              <Link to="#/" onClick={(e) => areYouSure(onBack)}>
                <FormattedMessage
                  id="Settings-btpl"
                  defaultMessage="Scheduling Pages"
                  description="Settings - Back to Pages Label"
                />
              </Link>
              <Icons.ArrowRight width="12" height="12" style={{ padding: `0 10px` }} />
            </div>
          )
        }
      >
        <EmbedPageContent>
          {AllowedTabs.length > 1 && (
            <div className="sidebar">
              {AllowedTabs.map((tab) => (
                <NavLink to={`/embed/${page.id}/${tab.path}`} key={tab.path}>
                  <tab.icon /> <FormattedMessage {...tab.label} />
                </NavLink>
              ))}
            </div>
          )}
          <div className="main">
            <Switch>
              {AllowedTabs.map((tab) => (
                <Route
                  key={tab.path}
                  path={`/embed/${page.id}/${tab.path}`}
                  render={() => <tab.component page={page} config={config} onChange={this.handlePageUpdate} />}
                />
              ))}
              <Redirect to={`/embed/${page.id}/${AllowedTabs[0].path}`} />
            </Switch>
          </div>
        </EmbedPageContent>
        {config.behavior.displayDoneButton !== false && (
          <EmbedPageFooter>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!config.behavior?.disableViewingPages && (
                <a className="view-page-link" href={`${config.pageDomain}/${initialPage.slug}`} target="__blank">
                  <Icons.View style={{ marginRight: 5 }} />
                  <FormattedMessage
                    defaultMessage="View Page"
                    description="Settings-pvpl"
                    id="Settings - Pages - View Page Label"
                  />
                </a>
              )}
              {onDelete && (
                <a
                  href="#/"
                  tabIndex={0}
                  className="delete-page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    showCustomConfirm({
                      body: intl.formatMessage({
                        id: 'Settings-pd',
                        defaultMessage:
                          'Are you sure you want to delete this scheduling page? Existing bookings will not be cancelled but will no longer be rescheduled or cancelled through the page.',
                        description: 'Settings - Prompt - Delete?',
                      }),
                      callback: (confirmed) => {
                        if (confirmed) {
                          onDelete();
                        }
                      },
                    });
                  }}
                >
                  <Icons.X width="14" height="14" style={{ marginRight: 5 }} />
                  <FormattedMessage
                    defaultMessage="Delete Page"
                    id="Settings-pdbl"
                    description="Settings - Pages - Delete Button Label"
                  />
                </a>
              )}
            </div>
            <div style={{ flex: 1 }} />
            {this.state.saving ? (
              <Button className="save" intent="default" disabled>
                <Icons.Spinner width={15} height={15} style={{ marginRight: 7 }} />{' '}
                <FormattedMessage
                  defaultMessage="Saving..."
                  description="Settings-psl"
                  id="Settings - Pages - Saving Label"
                />
              </Button>
            ) : (
              <Button
                intent="default"
                className={`save ${pristine && 'no-changes'}`}
                onClick={this.handleSaveButtonClicked}
                disabled={page.config.hasFormErrors}
              >
                <FormattedMessage defaultMessage="Save & Exit" id="bsae" description="Button - Save and Exit" />
              </Button>
            )}
          </EmbedPageFooter>
        )}
      </EmbedPageContainer>
    );
  }
}

export const PageDetailView = injectIntl(PageDetailViewBase);
