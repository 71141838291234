import React from 'react';
import * as Icons from '../components/SVGIcons';

import './EmbedPageContainer.scss';
import SVGButton from '../components/SVGButton';
import { useIntl } from 'react-intl';
import { isMobile,  isTablet } from 'react-device-detect';

export const EmbedPageContainer: React.FunctionComponent<{
  breadcrumb?: React.ReactNode;
  headerHiddenOnMobile?: boolean
  headerName: React.ReactNode | string;
  className: string;
  children: React.ReactNode;
  onDismiss: () => void;
}> = (props) => {
  const intl = useIntl();
  const useMobileHeader = isMobile && !isTablet;

  return (
    <div className={`EmbedPageContainer ${props.className}`}>
      {useMobileHeader && !props.headerHiddenOnMobile && (
        <div className="header">
          <SVGButton
            title={intl.formatMessage({ id: 'Close', defaultMessage: 'Close', description: 'Close' })}
            className="close-x"
            onClick={props.onDismiss}
            style={{ paddingRight: 15 }}
          >
            <Icons.ArrowLeft />
          </SVGButton>
          <div style={{ flex: 1, textAlign: 'center' }}>{props.headerName}</div>
        </div>
      )}
      {!useMobileHeader && (
        <div className="header">
          {props.breadcrumb}
          {props.headerName}
          <div style={{ flex: 1 }} />
          <SVGButton
            title={intl.formatMessage({ id: 'Close', defaultMessage: 'Close', description: 'Close' })}
            className="close-x"
            onClick={props.onDismiss}
          >
            <Icons.X />
          </SVGButton>
        </div>
      )}

      {props.children}
    </div>
  );
};

export const EmbedPageContent: React.FunctionComponent<{}> = (props) => <div className="content">{props.children}</div>;

export const EmbedPageFooter: React.FunctionComponent<{}> = (props) => <div className="footer">{props.children}</div>;
