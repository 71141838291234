import React, { Component } from 'react';
import moment, { Moment } from 'moment';

import { Scheduling } from '../types/Scheduling';
import * as Icons from '../components/SVGIcons';
import SVGButton from '../components/SVGButton';
import * as DateUtils from '../DateUtils';
import { getNylasWindowContext, track } from '../NylasWindowContext';

import './CalendarMonthView.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { LONG_DAY_FORMATS } from '../LocaleSupport';

type TimeSlot = Scheduling.TimeSlot;

interface CalendarMonthViewProps {
  loading?: boolean;
  showAutoschedule: boolean;
  slots: TimeSlot[];
  ownEvents: 'loading' | { start: number; end: number }[] | Error | 'empty';
  onSlotClicked: (slot: TimeSlot) => Promise<void>;
}

interface CalendarMonthViewState {
  monthStart: Moment | null;
  selected: Moment | null;
}

const { apiBaseURL } = getNylasWindowContext();

function getSlotsByDayInMonth(monthStart: Moment, slots: TimeSlot[]) {
  const slotsByDay: { slots: TimeSlot[]; date: Moment }[] = [];

  let date = monthStart;
  for (let ii = 1; ii <= monthStart.daysInMonth(); ii++) {
    const next = monthStart.clone().add(ii, 'days');
    const dateUnix = date.unix();
    const slotsInDay = slots.filter((s) => s.start >= dateUnix && s.start < next.unix());
    slotsByDay.push({ date, slots: slotsInDay });
    date = next;
  }
  return slotsByDay;
}

class CalendarMonthView extends Component<CalendarMonthViewProps, CalendarMonthViewState> {
  state: CalendarMonthViewState = {
    monthStart: null,
    selected: null,
  };

  componentDidMount() {
    this.autoselectSharedAvailability();
  }

  componentDidUpdate(prev: CalendarMonthViewProps) {
    // Just finished loading autobusy state
    if (!(prev.ownEvents instanceof Array) && this.props.ownEvents instanceof Array) {
      this.autoselectSharedAvailability();
    }

    // Just finished loading slots and autobusy state is available
    if (prev.slots.length === 0 && this.props.slots.length > 0 && this.props.ownEvents instanceof Array) {
      this.autoselectSharedAvailability();
    }
  }

  autoselectSharedAvailability = () => {
    const { ownEvents } = this.props;
    if (!(ownEvents instanceof Array)) return;

    const isFree = (s: TimeSlot) => !ownEvents.some((b) => b.start < s.end && s.start < b.end);
    const first = this.props.slots.find(isFree);

    track('Showing Shared Availability', {
      hostSlots: this.props.slots.length,
      sharedSlots: this.props.slots.filter(isFree).length,
    });

    if (!first) return;
    this.setState({
      selected: moment(first.start * 1000)
        .utc()
        .startOf('day'),
    });
  };

  onToggleSelected = (date: Moment | null) => {
    if (this.state.selected && date && this.state.selected.unix() === date.unix()) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected: date });
    }
  };

  onShiftByMonth = (delta: number) => {
    this.setState({
      monthStart: this.getCurrentMonthStart().clone().add(delta, 'months').startOf('month'),
    });
  };

  getCurrentMonthStart() {
    return (
      this.state.monthStart ||
      (this.props.slots.length ? moment(this.props.slots[0].start * 1000).utc() : moment()).startOf('month')
    );
  }

  render() {
    const { loading, slots, onSlotClicked, ownEvents, showAutoschedule } = this.props;
    const { selected } = this.state;

    const monthStart = this.getCurrentMonthStart();
    const slotsByDay = getSlotsByDayInMonth(monthStart, slots);
    const selectedDay = slotsByDay.find((s) => selected && s.date.unix() === selected.unix());

    const isFree =
      ownEvents instanceof Array
        ? (s: TimeSlot) => !ownEvents.some((b) => b.start < s.end && s.start < b.end)
        : () => true;

    return (
      <div className={`CalendarMonthView`}>
        <div className="col-month">
          {slots && slots.length === 0 && !loading && (
            <div className="no-slots-available">
              <div>Sorry, we're booked!</div>
            </div>
          )}
          <MonthOverview
            slots={slots}
            slotsByDay={slotsByDay}
            slotIsFree={isFree}
            selected={selected}
            monthStart={monthStart}
            onShiftByMonth={this.onShiftByMonth}
            onToggleSelected={this.onToggleSelected}
          />
        </div>
        <div className="col-slot-list">
          {selectedDay ? (
            <SlotList
              selected={selectedDay.date}
              slots={selectedDay.slots}
              slotIsFree={isFree}
              hasOwnEvents={ownEvents instanceof Array}
              onSlotClicked={onSlotClicked}
            />
          ) : (
            <Instructions ownEvents={ownEvents} showAutoschedule={showAutoschedule} />
          )}
        </div>
      </div>
    );
  }
}

interface MonthOverviewProps {
  monthStart: Moment;
  slots: TimeSlot[];
  slotsByDay: { slots: TimeSlot[]; date: Moment }[];
  slotIsFree: (slot: TimeSlot) => boolean;
  selected: Moment | null;
  onToggleSelected: (date: Moment | null) => void;
  onShiftByMonth: (delta: number) => void;
}

const MonthOverview = ({
  monthStart,
  selected,
  slots,
  slotsByDay,
  slotIsFree,
  onShiftByMonth,
  onToggleSelected,
}: MonthOverviewProps) => {
  const daysOfWeek: string[] = [];
  for (let ii = 0; ii < 7; ii++) {
    daysOfWeek.push(monthStart.clone().startOf('week').add(ii, 'days').format('ddd'));
  }

  const rows: React.ReactNode[][] = [];
  let row: React.ReactNode[] = [];

  // ensure the first row is entirely filled
  for (let ii = 0; ii < slotsByDay[0].date.weekday(); ii++) {
    row.push(<div key={`before-month-${ii}`} className="month-grid-day-container" />);
  }

  slotsByDay.forEach(({ slots, date }, idx) => {
    row.push(
      slots.length > 0 ? (
        <div key={idx} className="month-grid-day-container">
          <button
            className={`month-grid-day populated ${slots.filter(slotIsFree).length === 0 && 'not-free'} ${
              selected && date.unix() === selected.unix() && 'selected'
            }`}
            onClick={() => onToggleSelected(date)}
            aria-label={`${date.toDate().toLocaleDateString(undefined, {
              weekday: 'long',
              month: 'long',
              day: '2-digit',
              timeZone: DateUtils.UTC,
            })}`}
          >
            <div>{idx + 1}</div>
          </button>
        </div>
      ) : (
        <div key={idx} className="month-grid-day-container">
          <div className={'month-grid-day'} onClick={() => onToggleSelected(null)}>
            <div>{idx + 1}</div>
          </div>
        </div>
      ),
    );
    if (row.length === 7) {
      rows.push(row);
      row = [];
    }
  });

  // ensure the last row is entirely filled
  const lastDayOfWeek = slotsByDay[slotsByDay.length - 1].date.weekday();
  for (let ii = lastDayOfWeek; ii < 6; ii++) {
    row.push(<div key={`after-month-${ii}`} className="month-grid-day-container" />);
  }
  rows.push(row);

  return (
    <>
      <div className="month-switcher">
        <h2 style={{ textTransform: 'capitalize' }}>{`${monthStart.format('MMMM YYYY')}`}</h2>
        <SVGButton
          className="tinted"
          title="previous month"
          onClick={() => onShiftByMonth(-1)}
          disabled={!slots[0] || slots[0].start > monthStart.unix()}
        >
          <Icons.ArrowLeft />
        </SVGButton>
        <SVGButton
          className="tinted"
          title="next month"
          onClick={() => onShiftByMonth(1)}
          disabled={!slots[0] || slots[slots.length - 1].start < monthStart.clone().add(1, 'month').unix()}
        >
          <Icons.ArrowRight />
        </SVGButton>
      </div>
      <div className="month-grid-header">
        {daysOfWeek.map((day, idx) => (
          <div key={idx} className="day-heading">
            {day}
          </div>
        ))}
      </div>
      {rows.map((row, idx) => (
        <div className="month-grid-row" key={idx}>
          {row}
        </div>
      ))}
    </>
  );
};

interface SlotListProps {
  slots: TimeSlot[];
  selected: Moment;
  hasOwnEvents: boolean;
  slotIsFree: (slot: TimeSlot) => boolean;
  onSlotClicked: (slot: TimeSlot) => Promise<void>;
}

const SlotList = ({ slots, slotIsFree, selected, onSlotClicked, hasOwnEvents }: SlotListProps) => {
  const [expanded, setExpanded] = React.useState<TimeSlot | null>(null);
  const bottomFadeRef = React.createRef<HTMLDivElement>();
  const intl = useIntl();

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!bottomFadeRef.current) return;
    const atBottom = e.currentTarget.scrollHeight === e.currentTarget.clientHeight + e.currentTarget.scrollTop;
    bottomFadeRef.current.style.opacity = atBottom ? '0' : '1';
  };

  let header = selected.format(LONG_DAY_FORMATS[intl.locale] || LONG_DAY_FORMATS.en);
  header = header.slice(0, 1).toUpperCase() + header.slice(1);

  const renderSlot = (slot: TimeSlot) => (
    <SlotListItem
      slot={slot}
      key={slot.start}
      isFree={slotIsFree(slot)}
      isExpanded={expanded === slot}
      onClick={() => setExpanded(expanded === slot ? null : slot)}
      onConfirmed={onSlotClicked}
    />
  );

  if (!hasOwnEvents) {
    return (
      <>
        <h2>{header}</h2>
        <div className="slots-wrap">
          <div className="slots" onScroll={onScroll}>
            {slots.map(renderSlot)}
          </div>
          <div className="bottom-fade" ref={bottomFadeRef} />
        </div>
      </>
    );
  }

  const free = slots.filter(slotIsFree);
  const busy = slots.filter((s) => !slotIsFree(s));

  return (
    <>
      <h2>{header}</h2>
      <div className="slots-wrap">
        <div className="slots" onScroll={onScroll}>
          {free.length > 0 && (
            <h4>
              <FormattedMessage
                defaultMessage="Times with Shared Availability"
                description="Section - Autoschedule - Shared Times"
                id="sast"
              />
              {Sparkle}
            </h4>
          )}
          {free.map(renderSlot)}
          {busy.length > 0 && (
            <h4>
              <FormattedMessage
                defaultMessage="Times with Conflicts"
                description="Section - Autoschedule - Conflict Times"
                id="sact"
              />
            </h4>
          )}
          {busy.map(renderSlot)}
        </div>
        <div className="bottom-fade" ref={bottomFadeRef} />
      </div>
    </>
  );
};

interface InstructionsProps {
  showAutoschedule: boolean;
  ownEvents: 'loading' | { start: number; end: number }[] | Error | 'empty';
}

const Instructions = ({ ownEvents, showAutoschedule }: InstructionsProps) => (
  <>
    <div style={{ flex: 1 }} />
    <p className="empty-state">
      <FormattedMessage
        defaultMessage="Select a date to view available times."
        description="Booking instructions"
        id="boin"
      />
    </p>
    <div style={{ flex: 1 }} />
    {showAutoschedule && (
      <>
        {ownEvents === 'empty' ? (
          <>
            <div className="autoschedule-or">
              <div>
                <FormattedMessage defaultMessage="or" description="Booking instructions separator" id="boins" />
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="autoschedule">
              <h2 style={{ marginTop: 15 }}>
                {Sparkle}{' '}
                <FormattedMessage defaultMessage="Autoschedule" description="Autoschedule title" id="autost" />
                {Sparkle}
              </h2>
              <p>
                <FormattedMessage
                  defaultMessage="Sign in to your email to automatically find times you're both available."
                  description="Autoschedule description"
                  id="autosd"
                />
              </p>
              <div style={{ marginTop: 20, marginBottom: 40 }}>
                <a
                  target="_top"
                  onClick={() => track('Auto-schedule Clicked', { service: 'google' })}
                  href={`${apiBaseURL}/oauth/google?next=${encodeURIComponent(window.location.href)}`}
                >
                  <img alt="Google" src="/btn_google_signin_light_normal_web@2x.png" height={45} />
                </a>
                <a
                  target="_top"
                  onClick={() => track('Auto-schedule Clicked', { service: 'o365' })}
                  href={`${apiBaseURL}/oauth/o365?next=${encodeURIComponent(window.location.href)}`}
                >
                  <img alt="Office365" src="/btn_msft_signin_light_normal_web@2x.png" height={45} />
                </a>
              </div>
              <div className="autoschedule-note">
                <FormattedMessage
                  defaultMessage="We value your privacy. Your free/busy schedule is requested once and credentials are not stored on our servers."
                  description="Autoschedule privacy note"
                  id="autospn"
                />
              </div>
            </div>
          </>
        ) : ownEvents instanceof Error ? (
          <div>{ownEvents.toString()}</div>
        ) : ownEvents === 'loading' ? (
          <div>
            <Icons.Spinner color="#666" width={40} height={40} />
          </div>
        ) : undefined}
      </>
    )}
  </>
);

const SlotListItem: React.FC<{
  slot: TimeSlot;
  isFree: boolean;
  isExpanded: boolean;
  onClick: () => void;
  onConfirmed: (slot: TimeSlot) => Promise<void>;
}> = ({ slot, isFree, isExpanded, onClick, onConfirmed }) => {
  const [confirming, setConfirming] = React.useState(false);

  return (
    <div key={slot.start} className={`slot ${isFree && 'free'}`}>
      <button className="bordered" onClick={onClick}>
        {moment(slot.start * 1000)
          .utc()
          .format('LT')}
      </button>
      {isExpanded && (
        <button
          className="confirm"
          autoFocus
          onClick={async () => {
            setConfirming(true);
            await onConfirmed(slot);
            setConfirming(false);
          }}
        >
          {confirming ? (
            <Icons.Spinner width={25} height={25} color="white" style={{ marginTop: 5 }} />
          ) : (
            <FormattedMessage defaultMessage="Confirm" description="Button - Confirm Slot" id="Button-Confirm-Slot" />
          )}
        </button>
      )}
    </div>
  );
};
const Sparkle = <img alt="sparkle" src="/sparkle.png" width={22} height={22} style={{ verticalAlign: 'middle' }} />;

export default CalendarMonthView;
