import React from 'react';
import * as Icons from '../components/SVGIcons';

import './Sheet.scss';
import { FormattedMessage } from 'react-intl';

interface SheetProps {
  title: string;
  children: React.ReactNode;
  onDismiss: () => void;
}

export const SheetFooter: React.FunctionComponent = (props) => <div className="sheet-footer">{props.children}</div>;

export const SheetBody: React.FunctionComponent = (props) => <div className="sheet-body">{props.children}</div>;

export const Sheet: React.FunctionComponent<SheetProps> = (props) => {
  const [dismissing, setDismissing] = React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout>();

  const onDismiss = () => {
    setDismissing(true);
    timerRef.current = setTimeout(() => {
      props.onDismiss();
    }, 220);
  };

  React.useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  return (
    <div className={`Sheet ${dismissing && 'dismissing'}`}>
      <button className="back-arrow" onClick={onDismiss} tabIndex={0}>
        <Icons.ArrowLeft width={15} height={15} /> <FormattedMessage defaultMessage="Back" description="Back" id="Back" />
      </button>
      {props.children}
    </div>
  );
};
