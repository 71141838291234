/* eslint-disable import/first */
// Import polyfills first so that application code can immediately
// use modern APIs like Array.includes at import-time.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';

// Remove the focus outline around buttons, links etc but ONLY after
// a mouse event occurs, keeping the a11y experience nice.
import 'remove-focus-outline';

import { App } from './App';
import { isTablet, isMobile } from 'react-device-detect';

if (process.env.NODE_ENV === 'production') {
  (window as any).initAnalytics();
}

setTimeout(() => {
  if (isTablet) {
    document.body.className = `${document.body.className} is-touch`;
  } else if (isMobile) {
    document.body.className = `${document.body.className} is-touch is-mobile`;
  }
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
