import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { range as utilRange, assertUnreachable } from '../Utils';
import { Tooltip } from '../components/Tooltip';
import { useIntl } from 'react-intl';

export type TimeUnitName = 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months';

export type FieldKey =
  | 'duration'
  | 'minBookingNotice'
  | 'minCancellationNotice'
  | 'futureLimit'
  | 'bufferTime'
  | 'timeBeforeEvent';

export interface TimeUnit {
  name: TimeUnitName;
  range: readonly number[];
  multiplier: number; // Constant to multiply by numbers in `values` to convert to base value
}

interface TimeFieldProps {
  label?: string;
  tooltip?: string;
  fieldKey: FieldKey;
}

export const TimeField: React.FunctionComponent<TimeFieldProps> = ({ label, fieldKey, tooltip }) => (
  <Field
    name={fieldKey}
    parse={(value) => Number(value)}
    render={({ input }) => <TimeFieldContent input={input} tooltip={tooltip} label={label} fieldKey={fieldKey} />}
  />
);

const TimeFieldContent: React.FunctionComponent<
  TimeFieldProps & {
    input: FieldRenderProps<any>['input'];
  }
> = ({ label, input, fieldKey, tooltip }) => {
  const intl = useIntl();
  const units = getTimeUnits(fieldKey);
  const [unitName, setUnitName] = React.useState(bestUnit(units, input.value));
  const unit = units.find((u) => u.name === unitName)!;
  const isSingular = input.value / unit.multiplier === 1;

  const DisplayLabels = {
    Minutes: [
      intl.formatMessage({ id: 'Minute', defaultMessage: 'Minute' }),
      intl.formatMessage({ id: 'Minutes', defaultMessage: 'Minutes' }),
    ],
    Hours: [
      intl.formatMessage({ id: 'Hour', defaultMessage: 'Hour' }),
      intl.formatMessage({ id: 'Hours', defaultMessage: 'Hours' }),
    ],
    Days: [
      intl.formatMessage({ id: 'Day', defaultMessage: 'Day' }),
      intl.formatMessage({ id: 'Days', defaultMessage: 'Days' }),
    ],
    Weeks: [
      intl.formatMessage({ id: 'Week', defaultMessage: 'Week' }),
      intl.formatMessage({ id: 'Weeks', defaultMessage: 'Weeks' }),
    ],
    Months: [
      intl.formatMessage({ id: 'Month', defaultMessage: 'Month' }),
      intl.formatMessage({ id: 'Months', defaultMessage: 'Months' }),
    ],
  };

  return (
    <div className="timeField">
      {label && (
        <label htmlFor="time-field">
          {label} <Tooltip text={tooltip} />
        </label>
      )}

      <div className="timeFieldSelectContainer">
        <select id="time-field" value={input.value} onChange={(e) => input.onChange(e)}>
          {!unit.range.map((i) => unit.multiplier * i).includes(input.value) && (
            <option key={'api-provided'} value={input.value}>
              {input.value / unit.multiplier}
            </option>
          )}
          {unit.range.map((i) => (
            <option key={i} value={unit.multiplier * i}>
              {i}
            </option>
          ))}
        </select>
        <select
          value={unitName}
          style={{ marginLeft: 5 }}
          onChange={(e) => {
            const nextUnitName = e.currentTarget.value as any;
            setUnitName(nextUnitName);
            const nextUnit = units.find((u) => u.name === nextUnitName)!;
            const valueInNextUnit = input.value / nextUnit.multiplier;
            const nextValue = nextUnit.range.includes(valueInNextUnit) ? valueInNextUnit : nextUnit.range[0];
            input.onChange({ target: { value: nextValue * nextUnit.multiplier } } as any);
          }}
        >
          {units.map((i) => (
            <option key={i.name} value={i.name}>
              {isSingular && unitName === i.name ? DisplayLabels[i.name][0] : DisplayLabels[i.name][1]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export function bestUnit(available: TimeUnit[], value: number): TimeUnitName {
  const hours = available.find((a) => a.name === 'Hours');

  // Base Unit is Minutes
  if (hours && hours.multiplier === 60) {
    if (value <= 90 && available.find((a) => a.name === 'Minutes')) {
      return 'Minutes';
    } else if (value <= 23 * 60) {
      return 'Hours';
    } else {
      return 'Days';
    }
  }

  // Base Unit is Days
  if (value % 7 === 0) {
    return 'Weeks';
  } else if (value <= 30) {
    return 'Days';
  } else {
    return 'Months';
  }
}

export function getTimeUnits(key: FieldKey): TimeUnit[] {
  switch (key) {
    case 'duration':
      return [
        { name: 'Minutes', range: [15, 20, 30, 45, 60, 75, 90], multiplier: 1 },
        { name: 'Hours', range: utilRange(1, 23), multiplier: 60 },
      ];
    case 'minBookingNotice':
      return [
        { name: 'Hours', range: utilRange(0, 23), multiplier: 60 },
        { name: 'Days', range: utilRange(0, 30), multiplier: 60 * 24 },
      ];
    case 'minCancellationNotice':
      return [
        { name: 'Minutes', range: utilRange(0, 90, 15), multiplier: 1 },
        { name: 'Hours', range: utilRange(0, 23), multiplier: 60 },
        { name: 'Days', range: utilRange(0, 30), multiplier: 60 * 24 },
      ];
    case 'futureLimit':
      return [
        { name: 'Days', range: utilRange(1, 30), multiplier: 1 },
        { name: 'Weeks', range: utilRange(1, 4), multiplier: 7 },
        { name: 'Months', range: utilRange(1, 2), multiplier: 30 },
      ];
    case 'bufferTime':
      return [
        { name: 'Minutes', range: utilRange(0, 90, 15), multiplier: 1 },
        { name: 'Hours', range: utilRange(0, 23), multiplier: 60 },
      ];
    case 'timeBeforeEvent':
      return [
        { name: 'Minutes', range: utilRange(15, 90, 15), multiplier: 1 },
        { name: 'Hours', range: utilRange(1, 23), multiplier: 60 },
        { name: 'Days', range: utilRange(0, 30), multiplier: 24 * 60 },
      ];
    default:
      assertUnreachable(key);
      throw new Error('switch not exhaustive');
  }
}

export function formatDuration(min: number) {
  const units = getTimeUnits('duration');
  const unitName = bestUnit(units, min);
  const unit = units.find((u) => u.name === unitName)!;
  return `${min / unit.multiplier} ${unitName.toLocaleLowerCase()}`;
}
