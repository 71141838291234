import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../components/Button';
import * as Icons from '../components/SVGIcons';
import { Tooltip } from '../components/Tooltip';
import { Scheduling } from '../types/Scheduling';
import { isValidEmail } from '../Utils';
import './ParticipantsField.scss';


interface ParticipantsFieldProps {
    label?: string;
    tooltip?: string;
    organizer?: { account_name: string; account_email: string };
    participants?: Scheduling.Participant[];
    updateParticipants: (participants: Scheduling.Participant[]) => void;
}

export const ParticipantsField: React.FunctionComponent<ParticipantsFieldProps> = ({ label, organizer, participants, updateParticipants, tooltip }) => {
    const [participantEmail, setParticipantEmail] = React.useState("");
    const intl = useIntl();
    const participantEmailInputField = React.useRef<HTMLInputElement>(null);

    const removeParticipant = (participant: Scheduling.Participant) => {
        const newParticipants = participants?.filter(p => p !== participant);
        if (newParticipants) {
            updateParticipants(newParticipants);
        }
    }
    
    return (
        <div className="participantsField">
            {label && (
                <label htmlFor="time-field">
                    {label} <Tooltip text={tooltip} />
                </label>
            )}

            <div className="participantsFieldInputContainer">
                <input
                    ref={participantEmailInputField}
                    value={participantEmail}
                    id="participants"
                    type="email"
                    className="participantsFieldInput"
                    pattern="/\S+@\S+/"
                    placeholder={intl.formatMessage({
                        id: 'ParticipantsField-eppp',
                        description: 'ParticipantsField - Input - Participants placeholder',
                        defaultMessage: 'Add participants',
                    })}
                    onChange={(event) => {
                        participantEmailInputField.current?.setCustomValidity("");
                        setParticipantEmail(event.target.value);
                    }}
                />
                <Button className="add" intent="outlined" onClick={() => {
                    if (!isValidEmail(participantEmail)) {
                        participantEmailInputField.current?.setCustomValidity(intl.formatMessage({
                            id: "ParticipantsField-iep",
                            description: "ParticipantsField - Validation - Invalid email",
                            defaultMessage: "Invalid email address provided."
                        }));
                    } else if (typeof organizer !== "undefined" && participantEmail.toLocaleLowerCase() === organizer?.account_email.toLocaleLowerCase()) {
                        participantEmailInputField.current?.setCustomValidity(intl.formatMessage({
                            id: "ParticipantsField-caysap",
                            description: "ParticipantsField - Validation - Cannot add yourself as participant",
                            defaultMessage: "You cannot add yourself as a participant."
                        }));
                    } else if (participants?.find(p => p.email.toLocaleLowerCase() === participantEmail.toLocaleLowerCase())) {
                        participantEmailInputField.current?.setCustomValidity(intl.formatMessage({
                            id: "ParticipantsField-paa",
                            description: "ParticipantsField - Validation - Participant already added",
                            defaultMessage: "Participant already added."
                        }));
                    } else {
                        if (!participants) {
                            participants = [];
                        }
                        updateParticipants([...participants, { email: participantEmail }]);
                        setParticipantEmail("");
                    }
                }}>
                    <Icons.Plus width={15} height={15} style={{ marginRight: 7 }} />
                    <FormattedMessage
                        defaultMessage="Add"
                        id="ParticipantsField-add"
                        description="ParticipantsField-Add-Button-Label"
                    />
                </Button>
            </div>
            {/* TODO: add participant name here */}
            <div className="participantsFieldParticipants">
                {participants?.map((participant, index) => (
                    <div className="participantsFieldParticipant" key={index}>
                        <button
                            onClick={() => removeParticipant(participant)}
                            aria-label={intl.formatMessage({
                                id: "ParticipantsFields-rp",
                                defaultMessage: "Remove participant",
                                description: "ParticipantsField - Button - Remove Participant"
                            })}
                            disabled={participant.role === "organizer"}
                        >
                            <Icons.X width={12} height={12} />
                        </button>
                        <div className="participantsFieldParticipant">
                            <div className="email">
                                {participant.email}&nbsp;
                                {participant.role === "organizer" &&
                                <span className="tag">{intl.formatMessage({
                                    id: "ParticipantsField-organizer",
                                    defaultMessage: "Organizer",
                                    description: "ParticipantsField - Tag - Organizer"
                                })}</span>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};