import React from 'react';
import { Scheduling } from '../types/Scheduling';
import { TabComponentProps } from './PageDetailView';
import './BookingFlowView.scss';
import RadioGroup from '../components/RadioGroup';
import { FormWithSpy, Field } from '../components/FormHelpers';
import { clone } from '../Utils';
import { FormattedMessage, useIntl } from 'react-intl';

export const BookingFlowView: React.FunctionComponent<TabComponentProps> = (props) => {
  const intl = useIntl();

  const handleFormChange = (formData: { thank_you_redirect?: string; confirmation_method: 'automatic' | 'manual' }) => {
    const page = clone(props.page);
    page.config.booking.confirmation_method = formData.confirmation_method;
    page.config.appearance.thank_you_redirect = formData.thank_you_redirect;
    props.onChange(page);
  };

  const config = props.page.config;

  return (
    <div className="BookingFlowView">
      <FormWithSpy
        onChange={handleFormChange}
        onSubmit={() => {}}
        initialValues={{
          confirmation_method: config.booking.confirmation_method,
          thank_you_redirect: config.appearance.thank_you_redirect,
        }}
      >
        {() => (
          <>
            <h2>
              <FormattedMessage
                defaultMessage="How should new bookings be handled?"
                description="Settings - Confirmation Method - Prompt"
                id="Settings-cmp"
              />
            </h2>
            <RadioGroup<Scheduling.PageConfigBooking['confirmation_method']>
              inputName="confirmation_method"
              options={[
                {
                  label: intl.formatMessage({
                    id: 'Settings-cma',
                    defaultMessage: 'Automatic',
                    description: 'Settings - Confirmation Method - Automatic',
                  }),
                  value: 'automatic',
                  description: intl.formatMessage({
                    id: 'Settings-cma-d',
                    defaultMessage: 'Bookings are auto-confirmed when they are submitted (instant booking)',
                    description: 'Settings - Confirmation Method - Automatic Description',
                  }),
                },
                {
                  label: intl.formatMessage({
                    id: 'Settings-cmm',
                    defaultMessage: 'Manual',
                    description: 'Settings - Confirmation Method - Manual',
                  }),
                  value: 'manual',
                  description: intl.formatMessage({
                    id: 'Settings-cmm-d',
                    defaultMessage: "You'll be notified and can confirm or decline bookings manually",
                    description: 'Settings - Confirmation Method - Manual Description',
                  }),
                },
              ]}
            />
            <div style={{ height: 4 }} />
            <div>
              <h2>
                <FormattedMessage
                  defaultMessage="What should happen when a booking is completed?"
                  description="Settings - Thank You - Prompt"
                  id="Settings-typ"
                />
              </h2>
              <Field name="thank_you_redirect">
                {({ input, meta }) => (
                  <div className="RadioGroup">
                    <div className="RadioButton">
                      <input
                        type="radio"
                        id="radio-a"
                        checked={!config.appearance.thank_you_redirect}
                        onChange={() => input.onChange({ target: { value: '' } } as any)}
                      />{' '}
                      <label htmlFor="radio-a">
                        <div className="title">
                          <FormattedMessage
                            defaultMessage="Hosted Page"
                            description="Settings - Thank You - Prompt Default"
                            id="Settings-typd"
                          />
                        </div>
                        <div className="description">
                          <FormattedMessage
                            defaultMessage="Show the guest the standard thank-you page."
                            description="Settings - Thank You - Prompt Default Description"
                            id="Settings-typdd"
                          />
                        </div>
                      </label>
                    </div>
                    <div className="RadioButton">
                      <input
                        type="radio"
                        id="radio-b"
                        checked={!!config.appearance.thank_you_redirect}
                        onChange={() => input.onChange({ target: { value: 'https://example.com/' } } as any)}
                      />{' '}
                      <label htmlFor="radio-b">
                        <div className="title">
                          <FormattedMessage
                            defaultMessage="Custom Page"
                            description="Settings - Thank You - Prompt Custom"
                            id="stypc"
                          />
                        </div>
                        <div className="description">
                          <FormattedMessage
                            defaultMessage="Pass booking info to a page on another website."
                            description="Settings - Thank You - Prompt Custom Description"
                            id="stypcd"
                          />{' '}
                          <a
                            href="https://developer.nylas.com/docs/scheduler/customization/custom-thank-you-page"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <FormattedMessage defaultMessage="Learn More" description="Learn More" id="Learn-More" />
                          </a>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </Field>
              <div style={{ margin: 20, marginTop: -15, marginLeft: 30 }}>
                <Field
                  name="thank_you_redirect"
                  label="URL"
                  type="text"
                  placeholder="URL"
                  component="input"
                  className="redirect-url-field"
                />
              </div>
            </div>
          </>
        )}
      </FormWithSpy>
    </div>
  );
};
export default BookingFlowView;
