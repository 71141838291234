import React, { useState } from 'react';
import { Scheduling } from '../types/Scheduling';
import { Link } from 'react-router-dom';
import { EmbedPageContainer, EmbedPageContent } from './EmbedPageContainer';
import * as Icons from '../components/SVGIcons';

import './PagesGridView.scss';
import { formatDuration } from './TimeField';
import { copyValue, EmbedConfig } from '../Utils';
import { FormattedMessage } from 'react-intl';

interface PagesGridViewProps {
  pageDomain: string;
  config: EmbedConfig;
  pages: Scheduling.Page[];
  onDismiss: () => void;
  onDeletePage: (page: Scheduling.Page) => Promise<any>;
}

export const PagesGridView: React.FunctionComponent<PagesGridViewProps> = (props) => {
  return (
    <EmbedPageContainer
      className="PagesGridView"
      onDismiss={props.onDismiss}
      headerName={
        props.config.style.modalTitle || (
          <FormattedMessage
            id="Settings-spt"
            description="Settings - Scheduling Pages Title"
            defaultMessage="Scheduling Pages"
          />
        )
      }
    >
      <EmbedPageContent>
        {props.pages
          .sort((lhs, rhs) => (new Date(lhs.modified_at) > new Date(rhs.modified_at) ? 1 : -1))
          .map((p) => (
            <PageItem
              key={p.slug}
              page={p}
              pageDomain={props.pageDomain}
              disableViewingPages={props.config.behavior?.disableViewingPages || false}
            />
          ))}
        <CreatePageItem />
      </EmbedPageContent>
    </EmbedPageContainer>
  );
};

const PageItem: React.FunctionComponent<{
  page: Scheduling.Page;
  pageDomain: string;
  disableViewingPages: boolean;
}> = ({ page, pageDomain, disableViewingPages }) => {
  const { title, duration, location } = page.config.event;
  const [isLinkCopied, setLinkCopied] = useState(false);
  return (
    <div className="PageItem">
      <span className="name">{title}</span>
      <span className="location">{`${formatDuration(duration)} — ${location}`}</span>
      <span className="slug">{`/${page.slug}`}</span>
      <div style={{ flex: 1 }} />
      <div className="linkContainer">
        {!disableViewingPages && (
          <a
            href="#/"
            className="link"
            onClick={(e) => {
              e.preventDefault();
              setLinkCopied(!isLinkCopied);
              copyValue(e, `${pageDomain}/${page.slug}`);
            }}
          >
            {!isLinkCopied ? (
              <>
                <Icons.Share width={20} height={20} style={{ marginRight: 3 }} />
                <span className="link-text">
                  <FormattedMessage
                    defaultMessage="Copy link"
                    id="Settings-pclb"
                    description="Settings - Pages - Copy Link Button"
                  />
                </span>
              </>
            ) : (
              <>
                <Icons.BookingConfirmationCheckmark
                  width={20}
                  height={20}
                  style={{ marginRight: 3 }}
                  className="checkmark"
                  checkmarkClassName="checkmark-check"
                />
                <span className="link-text">
                  <FormattedMessage
                    defaultMessage="Copied!"
                    id="Settings-pcb"
                    description="Settings - Pages - Copied Link Button"
                  />
                </span>
              </>
            )}
          </a>
        )}
        {!disableViewingPages && (
          <a className="link" href={`${pageDomain}/${page.slug}`} target="_blank" rel="noreferrer">
            <Icons.View width={20} height={20} style={{ marginRight: 3 }} />
            <FormattedMessage defaultMessage="View" id="Settings-pvb" description="Settings - Pages - View Button" />
          </a>
        )}
        <div style={{ flex: 1 }} />
        <Link className="link" to={`/embed/${page.id}/event-info`}>
          <Icons.Edit width={20} height={20} style={{ marginRight: 3 }} />
          <FormattedMessage defaultMessage="Edit" id="Settings-peb" description="Settings - Pages - Edit Button" />
        </Link>
      </div>
    </div>
  );
};

interface CreatePageItemProps {}

const CreatePageItem: React.FunctionComponent<CreatePageItemProps> = () => (
  <Link to="/embed/add-page" className="PageItem CreatePageItem">
    <div style={{ marginBottom: 10, color: '#A8A8A8' }}>
      <Icons.Plus width={28} height={28} />
    </div>
    <div>
      <FormattedMessage
        defaultMessage="Add Scheduling Page"
        description="Settings - Pages - Add Button"
        id="Settings-pab"
      />
    </div>
  </Link>
);
